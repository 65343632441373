import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";

import { Box } from "@app/models/box.model";
import { Subscription } from "@app/models/subscription.model";
import { filter, takeUntil } from "rxjs/operators";
import { BoxesService } from "@app/core/services/boxes.service";

@Component({
  selector: 'app-change-subscription-box',
  templateUrl: './change-subscription-box.component.html',
  styleUrls: ['./change-subscription-box.component.scss']
})
export class ChangeSubscriptionBoxComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter<void>();

  componentDestroyed$ = new Subject<void>();
  selectedSubscription$ = new ReplaySubject<Subscription>();
  showModal$ =  new BehaviorSubject<boolean>(false);
  boxesRequested$ = new BehaviorSubject<boolean>(false);
  boxes$ = new BehaviorSubject<Box[]>([]);
  boxSelectedForm: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly boxesService: BoxesService,
  ) { }

  @Input()
  set show(status: boolean) {
    this.showModal$.next(status);
  }

  @Input()
  set selectedSubscription(subscription) {
    this.selectedSubscription$.next(subscription)
  }

  ngOnInit() {
    this.boxesService.getDefaultBoxes()
      .subscribe(boxes => {
        this.boxes$.next(boxes);
        this.boxesRequested$.next(true)
      });

    this.selectedSubscription$.asObservable()
      .pipe(filter(subscription => !!subscription))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(subscription => {
        console.log(subscription)
        this.initForm(subscription.id, subscription.selectedBox)
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  initForm(subscriptionId, selectedBox) {
    this.boxSelectedForm = this.fb.group({
      subscriptionId: [subscriptionId, Validators.required],
      selectedBox: [selectedBox, Validators.required],
    });
  }

  onBoxSelected() {
    this.boxesService.setDefaultBox(
      this.boxSelectedForm.get('subscriptionId').value,
      this.boxSelectedForm.get('selectedBox').value
    )
      .subscribe(response => {
        this.closed.emit();
      })
  }

}
