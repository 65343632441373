import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';

import { catchError, map, switchMap } from 'rxjs/operators';
// actions
import * as fromAdminActions from './../actions/admin.actions';
// models
// services
import { AuthService } from './../../services/auth.service';

@Injectable()
export class AuthEffects {

  @Effect({ dispatch: false }) load$ = this.actions$
    .pipe(ofType(fromAdminActions.LOAD))
    .pipe(
      switchMap(() => {
        return this.authService.profile()
          .pipe(
            map((data: any) => {
              this.store.dispatch(new fromAdminActions.LoadCompleteAction(data.user));
            }),
            catchError(reason => {
              this.store.dispatch(new fromAdminActions.LoadFailAction(reason.errors));
              this.authService.removeJWTToken();
              return of(new fromAdminActions.LoadFailAction(reason.errors));
            })
          );
      })
    );

  @Effect({ dispatch: false }) login$ = this.actions$
    .pipe(ofType(fromAdminActions.LOGIN))
    .pipe(
      map((action: fromAdminActions.LoginAction) => action.payload),
      switchMap(
        auth => this.authService.loginUser(auth)
          .pipe(
            map((event: HttpResponse<any>) => {
              console.log('En el effect voy a llamar a LoginCompleteAction con: ', event.body.user);
              this.store.dispatch(new fromAdminActions.LoginCompleteAction(event.body.user));
              const { redirectUrl } = this.route.snapshot.queryParams;
              if (redirectUrl) {
                this.router.navigate([redirectUrl], {
                  queryParams: {
                    ...this.route.snapshot.queryParams,
                    redirectUrl: undefined
                  }
                });
              }
            }),
            catchError(reason => {
              this.store.dispatch(new fromAdminActions.LoginFailAction(reason.error));
              return of(new fromAdminActions.LoginFailAction(reason.error));
            })
          )
      )
    );

  @Effect({ dispatch: false }) logout$ = this.actions$
    .pipe(ofType(fromAdminActions.LOGOUT))
    .pipe(
      map(() => {
        this.authService.logout();
      })
    );

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private actions$: Actions,
    private store: Store<any>
  ) {
  }

}

