import * as tslib_1 from "tslib";
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
// actions
import * as fromAdminActions from './../actions/admin.actions';
// models
// services
import { AuthService } from './../../services/auth.service';
var AuthEffects = /** @class */ (function () {
    function AuthEffects(router, route, authService, actions$, store) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.authService = authService;
        this.actions$ = actions$;
        this.store = store;
        this.load$ = this.actions$
            .pipe(ofType(fromAdminActions.LOAD))
            .pipe(switchMap(function () {
            return _this.authService.profile()
                .pipe(map(function (data) {
                _this.store.dispatch(new fromAdminActions.LoadCompleteAction(data.user));
            }), catchError(function (reason) {
                _this.store.dispatch(new fromAdminActions.LoadFailAction(reason.errors));
                _this.authService.removeJWTToken();
                return of(new fromAdminActions.LoadFailAction(reason.errors));
            }));
        }));
        this.login$ = this.actions$
            .pipe(ofType(fromAdminActions.LOGIN))
            .pipe(map(function (action) { return action.payload; }), switchMap(function (auth) { return _this.authService.loginUser(auth)
            .pipe(map(function (event) {
            console.log('En el effect voy a llamar a LoginCompleteAction con: ', event.body.user);
            _this.store.dispatch(new fromAdminActions.LoginCompleteAction(event.body.user));
            var redirectUrl = _this.route.snapshot.queryParams.redirectUrl;
            if (redirectUrl) {
                _this.router.navigate([redirectUrl], {
                    queryParams: tslib_1.__assign({}, _this.route.snapshot.queryParams, { redirectUrl: undefined })
                });
            }
        }), catchError(function (reason) {
            _this.store.dispatch(new fromAdminActions.LoginFailAction(reason.error));
            return of(new fromAdminActions.LoginFailAction(reason.error));
        })); }));
        this.logout$ = this.actions$
            .pipe(ofType(fromAdminActions.LOGOUT))
            .pipe(map(function () {
            _this.authService.logout();
        }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "login$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "logout$", void 0);
    return AuthEffects;
}());
export { AuthEffects };
