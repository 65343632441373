import * as tslib_1 from "tslib";
import { createEntityAdapter } from '@ngrx/entity';
import * as notesActions from './../../store/actions/notes.actions';
var ɵ0 = function (note) { return note.id; };
export var adapter = createEntityAdapter({
    selectId: ɵ0,
    sortComparer: false
});
export var initialState = adapter.getInitialState({
    loading: false,
    loaded: false,
    error: null
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case notesActions.LOAD_SUBSCRIPTION_NOTES:
        case notesActions.CREATE_NOTE: {
            return tslib_1.__assign({}, state, { loading: true, loaded: false });
        }
        case notesActions.LOAD_SUBSCRIPTION_NOTES_COMPLETE: {
            return adapter.addAll(action.payload, tslib_1.__assign({}, state, { loaded: true, loading: false, error: null }));
        }
        case notesActions.CREATE_NOTE_COMPLETE: {
            return adapter.addOne(action.payload, tslib_1.__assign({}, state, { loaded: true, loading: false, error: null }));
        }
        case notesActions.LOAD_SUBSCRIPTION_NOTES_FAIL:
        case notesActions.CREATE_NOTE_FAIL: {
            return tslib_1.__assign({}, state, { error: action.payload });
        }
        default: {
            return state;
        }
    }
}
export var getNotes = function (state) { return state.entities; };
export var getNotesLoaded = function (state) { return state.loaded; };
export var getNotesLoading = function (state) { return state.loading; };
export var getNotesError = function (state) { return state.error; };
export { ɵ0 };
