<app-bar-alert alerts-code="dashboard-alerts"></app-bar-alert>

<div class="row">
  <section class="container mb-5">

    <app-search (search)="searchBarSelectedItem($event)"></app-search>

  </section>

  <section *ngIf="!isSearching && !noResultsFound" class="container">
    <div *ngFor="let listItem of (listItems$ | async)" class="container">
      <div class="row">
        <div class="col-sm-2">
          <button (click)="onCustomerEditAction(listItem.customer)" class="full-width dsh-btn" type="button">
            edit
          </button>
          <button (click)="onResetUserPasswordAction(listItem.customer)" class="full-width dsh-btn" type="button">
            reset user password
          </button>
          <button (click)="onOpenPaymentMethodsModal(listItem.customer)" class="full-width dsh-btn" type="button">
            Update Payment Methods
          </button>
        </div>

        <div class="col">
          <div class="row">
            <div class="col-sm-12">
              <div class="row">
                <div class="col">
                  <p class="dsh-usr__text dsh-usr__text--bold">
                    Customer ID: {{ listItem.customer.id }}
                  </p>
                  <p class="dsh-usr__text dsh-usr__text--bold">
                    Offer type selected: {{ listItem.customer.offerType }}
                  </p>
                  <p class="dsh-usr__text dsh-usr__text--bold">
                    {{ listItem.customer.createdAt | date: 'shortDate' }}
                  </p>
                  <p class="dsh-usr__text">
                    {{ listItem.customer.firstName }} {{ listItem.customer.lastName }}
                  </p>
                  <p class="dsh-usr__text">
                    {{ listItem.customer.email }}
                  </p>
                  <p class="dsh-usr__text">
                    {{ listItem.customer.phone }}
                  </p>
                  <div class="row">
                    <div class="referrals-card">
                      <p class="referrals-card__title">Referrals:</p>
                      <ul class="referrals-list">
                        <li *ngFor="let referral of listItem.customer.referrals" class="referral-item">
                          {{ referral.name }} - {{ referral.createdAt | date:'short' }}
                        </li>
                      </ul>
                      <p class="free-boxes-text">
                        Free Boxes: {{ getNumberOfFreeBoxes(listItem.customer.id) }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="dsh-subs__payment_methods">
                    <div class="row" *ngFor="let paymentMethod of listItem.customer.paymentMethods">
                      <div class="col">
                        <p class="dsh-usr__text">
                          {{ paymentMethod.cardType }} ending in {{ paymentMethod.last4 }}
                        </p>
                      </div>
                    </div>

                  </div>
                  <div class="dsh-subs__payments">
                    <div class="row" *ngFor="let payment of listItem.customer.payments">
                      <div class="col">
                        <p class="dsh-usr__text transaction-item">
                          {{ payment.createdAt | date : 'shortDate' }}
                          {{ payment.amount | currency }}
                          @
                          {{ payment.processor }}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="dsh-usr__text dsh-usr__text--bold">
            SPECIAL SHIPMENTS
          </div>


          <div class="row">
            <div class="col offset-2">

              <app-customer-shipments
                [customerId]="listItem.customer.id"
                [shipments]="listItem.customer.shipments">
              </app-customer-shipments>
            </div>
          </div>

        </div>
      </div>

      <ul *ngFor="let subscription of listItem.subscriptions"
          [ngClass]="{ 'dsh-section--loading': subscription.loading }"
          class="row dsh-subs">
        <li class="col-sm-12">
          <div class="row">
            <div class="col-sm-2">
              <button
                (click)="onTurnOffAction(subscription.id, listItem.customer.id)"
                *ngIf="subscription.autoRenew"
                class="full-width dsh-btn"
                type="button">
                Turn Auto Renew OFF
              </button>
              <button
                (click)="onShowAutoRenewOnModal(subscription.id)"
                *ngIf="!subscription.autoRenew"
                class="full-width dsh-btn"
                type="button">
                Turn Auto Renew ON
              </button>
              <button
                (click)="onCancelAction(subscription.id)"
                *ngIf="subscription.status !== 'CANCELLED'"
                class="full-width dsh-btn"
                type="button">
                Cancel
              </button>
              <button (click)="onSkip(subscription.id)" class="full-width dsh-btn" type="button">
                skip
              </button>
              <button (click)="onEditSubscriptionAction(subscription)" class="full-width dsh-btn" type="button">
                edit
              </button>
              <button (click)="showProcessSubscriptionModal(subscription)" class="full-width dsh-btn" type="button">
                process
              </button>
              <button (click)="clearDeclineStatus(subscription)" class="full-width dsh-btn"
                      id="clear-decline-status-button"
                      type="button">
                clear decline status
              </button>
              <button
                (click)="onFreeShipmentAction(subscription.customerId, subscription.id, address[subscription.address])"
                class="full-width dsh-btn" type="button">
                Create a free shipment
              </button>
              <button (click)="onOpenPreferredShipperAction(subscription)" class="full-width dsh-btn" type="button">
                Change Preferred Shipper
              </button>

              <button class="full-width dsh-btn" type="button"
                      (click)="subscriptionToSelectBox = subscription; showSubscriptionToSelectBox = true">
                Change Default Box
              </button>
            </div>

            <div class="col">
              <div class="row dsh-subs__item">
                <section class="col-sm-12">
                  <div class="row">
                    <div class="col">
                      <div class="row">
                        <p class="col-sm-12 dsh-usr__text dsh-usr__text--bold">Subscription: {{ subscription.id }} -
                          AUTO RENEW: {{ subscription.autoRenew ? 'ON' : 'OFF' }}
                          <span *ngIf="subscription.source === 'cratejoy'" style="color: red">(CRATEJOY)</span>
                        </p>
                        <p class="col-sm-12 dsh-usr__text dsh-usr__text--bold">
                        <span>
                          {{ subscription.status === 'CANCELLED' ? (subscription.cancelledAt | date: 'shortDate') : (subscription.createdAt | date:
                          'shortDate') }}
                        </span>
                          <span>{{ subscription.status }}</span>
                          <span>{{ getPlan(subscription.plan).name }}</span>
                          <span *ngIf="subscription.status === 'CANCELLED' && subscription.cancelledReasons">
                          {{ parseCancelledReasons(subscription.cancelledReasons) }}
                        </span>
                          <span>({{ subscription.billingHz }}x)</span>
                        </p>
                        <p class="col-sm-12 dsh-usr__text">
                          {{ subscription.nextBillingDate | date: 'shortDate' }} <span
                          *ngIf="subscription.declineStatus && subscription.nextRetryDate">{{ subscription.declineStatus }} {{ subscription.nextRetryDate | date: 'shortDate' }}</span>
                        </p>
                        <div *ngIf="subscription.preferredShipper"
                             class="col-sm-12 dsh-usr__text subscription-preferred-shipper">
                          <p class="subscription-preferred-shipper__title">Preferred Shipper</p>
                          <p class="subscription-preferred-shipper__item">
                            <span>Carrier:</span>
                            {{ subscription.preferredShipper.carrier }}
                          </p>
                          <p class="subscription-preferred-shipper__item">
                            <span>Service:</span>
                            {{ subscription.preferredShipper.service }}
                          </p>
                        </div>
                        <p class="col-sm-12 dsh-usr__text subscription-address">
                        <span
                          class="subscription-address__item">{{ subscription.recipient.firstName }} {{ subscription.recipient.lastName }}</span>
                          <span
                            class="subscription-address__item">{{ address[subscription.address]?.shippingAddress }}</span>
                          <span *ngIf="address[subscription.address]?.shippingAddressExtra"
                                class="subscription-address__item">{{ address[subscription.address]?.shippingAddressExtra }}</span>
                          <span class="subscription-address__item">{{ address[subscription.address]?.city }}
                            , {{ address[subscription.address]?.stateCode }} {{ address[subscription.address]?.zip }}</span>
                        </p>
                        <p class="col-sm-12 dsh-usr__text">
                          {{ subscription.giftMessage }}
                        </p>
                      </div>
                    </div>

                    <!-- Subscription actions -->
                    <div class="col-auto dsh-subs__actions">
                      <p *ngFor="let action of subscription.actions" class="dsh-usr__text">
                        {{ action.createdAt | date: 'shortDate' }} - {{ action.value }}
                      </p>
                    </div>
                  </div>
                </section>

                <div class="notes-container">
                  <div class="notes-form">
                  <textarea
                    [formControl]="newNoteText"
                    class="create-note-text"
                    cols="30" rows="5">
                  </textarea>
                    <button
                      (click)="saveNewNote(subscription)"
                      class="create-note-btn">
                      Save Note
                    </button>
                  </div>
                  <ul class="notes-list">
                    <li *ngFor="let note of filterNotesBySubscription(subscription)" class="note-item">
                      {{ note.createdAt | date: 'shortDate'}} - {{ note.message }}
                    </li>
                  </ul>
                </div>

                <section class="col-sm-12">
                  <app-customer-shipments
                    [customerId]="listItem.customer.id"
                    [giftedShipments]="subscription.giftedShipments"
                    [shipments]="subscription.shipments"
                    [subscriptionAddress]="address[subscription.address]"
                    [subscriptionId]="subscription.id">
                  </app-customer-shipments>
                </section>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>

  <section *ngIf="noResultsFound" class="container">
    <div class="row">
      <div class="col">
        <p class="text-center">
          No customers found.
        </p>
      </div>
    </div>
  </section>

  <div *ngIf="isSearching" class="container">
    <div class="row">
      <div class="col">
        <p class="text-center">
          <span class="loadMoreButton button-animation button-animation--active">
          Searching for results...
        </span>
        </p>
      </div>
    </div>

  </div>
</div>

<!-- Cancel options modal -->
<div
  *ngIf="cancelForm !== undefined"
  class="modal fade"
  role="dialog"
  [ngClass]="{'show': showCancelModal }"
  [ngStyle]="{
    'display': showCancelModal ? 'block' : 'none',
    'backgroundColor': showCancelModal ? 'rgba(0,0,0,.8)' : 'transparent'
  }">

  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Cancelling reasons
        </h5>
        <button (click)="showCancelModal = false;" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form novalidate [formGroup]="cancelForm" (ngSubmit)="onCancelSubmit()">
          <div class="form-group">
            <label>Subscription id</label>
            <input class="form-control" formControlName="subscriptionId" type="text"
                   placeholder="Subscription ID">
          </div>

          <div formArrayName="reasons">
            <div *ngFor="let reason of cancelForm.controls.reasons.controls; let i = index" class="input-group mb-3"
                 formGroupName="{{i}}">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input type="checkbox" formControlName="enabled">
                </div>
              </div>
              <input [ngClass]="{'col-3': reason.get('name').value === 'Other' && reason.get('enabled').value}"
                     type="text" class="form-control"
                     formControlName="name" style="pointer-events: none;">
              <textarea *ngIf="reason.get('name').value === 'Other' && reason.get('enabled').value"
                        placeholder="Write a reason" formControlName="value"
                        class="form-control"></textarea>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCancelSubmit()">
          {{ !requestCancelSubscription ? 'Cancel subscription' : 'Loading...' }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Edit subscription modal -->
<div *ngIf="subscriptionEditForm !== undefined" [ngClass]="{'show': showSubscriptionEditModal }" [ngStyle]="{'display': showSubscriptionEditModal ? 'block' : 'none',
'backgroundColor': showSubscriptionEditModal ? 'rgba(0,0,0,.8)' : 'transparent' }" class="modal fade" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Editing subcription
        </h5>
        <button (click)="showSubscriptionEditModal = false;" type="button" class="close" data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form novalidate [formGroup]="subscriptionEditForm">
          <div class="form-group">
            <label>Subscription id</label>
            <input class="form-control" style="pointer-events: none" type="text"
                   formControlName="subscriptionId" placeholder="Subscription ID">
          </div>
          <div class="form-group" *ngIf="!subscriptionEditForm.get('isPrepay').value &&
          giftPlansToIds.includes(subscriptionEditForm.get('plan').value)">
            <label>Plan</label>
            <div class="input-group">
              <select class="form-control" formControlName="plan">
                <option *ngFor="let plan of giftPlans;" [value]="plan.id">{{ plan.name }}</option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <label>Recipient</label>
            <div class="input-group" formGroupName="recipient">
              <input type="text" class="form-control" formControlName="firstName" placeholder="First name"/>
              <input type="text" class="form-control" formControlName="lastName" placeholder="Last name"/>
            </div>
          </div>

          <div class="form-group">
            <label>Next Billing Date</label>
            <input
              type="text"
              class="form-control"
              formControlName="nextBillingDate"
              placeholder="YYYY-MM-DD"/>
            <div
              *ngIf="subscriptionEditForm.get('nextBillingDate').invalid && subscriptionEditForm.get('nextBillingDate').touched"
              style="color: red; font-size: 12px">
              Date is not completed or is formatted incorrectly.
            </div>
          </div>

          <div class="form-group" *ngIf="giftPlansToIds.includes(subscriptionEditForm.get('plan').value)">
            <label>Gift Message</label>
            <!--<input type="text" class="form-control" formControlName="giftMessage" placeholder="Hello there!"/>-->

            <textarea class="form-control" formControlName="giftMessage" placeholder="Hello there!"></textarea>
          </div>

          <div class="modal-footer">
            <button [disabled]="subscriptionEditForm.invalid" type="button" class="btn btn-secondary"
                    (click)="editSubscription()">
              {{ !requestCancelSubscription ? 'Update subscription' : 'Loading...' }}
            </button>
          </div>
        </form>

        <form novalidate [formGroup]="subscriptionEditAddressForm">
          <div class="form-group">
            <label>Address</label>
            <div class="input-group mb-3">
              <input type="text" class="form-control" formControlName="shippingAddress" placeholder="Address"/>
              <input type="text" class="form-control" formControlName="shippingAddressExtra"
                     placeholder="Address extra"/>
            </div>
            <div class="input-group mb-3">
              <input type="text" class="form-control" formControlName="city" placeholder="City"/>
              <input type="text" class="form-control" formControlName="state" placeholder="State"/>
              <input type="text" class="form-control" formControlName="stateCode" placeholder="State Code"/>
            </div>
            <div class="input-group mb-3">
              <input type="text" class="form-control" formControlName="zip" placeholder="Zip code"/>
              <input type="text" class="form-control" formControlName="country" placeholder="Country"/>
              <input type="text" class="form-control" formControlName="countryCode" placeholder="Country Code"/>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="editSubscriptionAddress()">
                Update Address
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Customer edit modal -->
<div *ngIf="customerEditForm !== undefined" [ngClass]="{'show': showCustomerEditModal }" [ngStyle]="{'display': showCustomerEditModal ? 'block' : 'none',
'backgroundColor': showCustomerEditModal ? 'rgba(0,0,0,.8)' : 'transparent' }" class="modal fade" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Editing Customer
        </h5>
        <button (click)="showCustomerEditModal = false;" type="button" class="close" data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form novalidate [formGroup]="customerEditForm">
          <div class="form-group">
            <label>Customer id</label>
            <input class="form-control" style="pointer-events: none;" type="text"
                   formControlName="id" placeholder="Subscription ID">
          </div>
          <input type="text" class="form-control" formControlName="firstName" placeholder="First name"/>
          <input type="text" class="form-control" formControlName="lastName" placeholder="Last name"/>
          <input type="text" class="form-control" formControlName="email" placeholder="Email"/>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="editCustomer()">
          {{ !requestCancelSubscription ? 'Edit Customer' : 'Loading...' }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Subscription Auto Renew ON Modal -->
<div
  *ngIf="autoRenewOnForm !== undefined"
  [ngClass]="{ 'show': showAutoRenewOnModal }"
  [ngStyle]="{
    'display': showAutoRenewOnModal ? 'block' : 'none',
    'backgroundColor': showAutoRenewOnModal ? 'rgba(0, 0, 0, .8)' : 'transparent'
  }"
  class="modal fade"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Select a date to resume the subscription
        </h5>
        <button (click)="showAutoRenewOnModal = false;" type="button" class="close" data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form novalidate [formGroup]="autoRenewOnForm">
          <div class="form-group">
            <label for="customerId">Customer ID</label>
            <input class="form-control" style="pointer-events: none;" type="text"
                   formControlName="userId" placeholder="Customer ID">
          </div>
          <div class="form-group">
            <label>Subscription ID</label>
            <input class="form-control" style="pointer-events: none;" type="text"
                   formControlName="subscriptionId" placeholder="Subscription ID">
          </div>
          <input
            type="text"
            class="form-control"
            formControlName="nextBillingDate"
            placeholder="MM-DD-YYYY"/>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onTurnAutoRenewOn()">
          Turn ON
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Turn off reasons -->
<app-modal title="What are the reasons to turn off this subscription?"
           [show]="showTurnOffModal"
           (atCloseBtn)="showTurnOffModal = false"
           secondaryBtn="Cancel"
           (atSecondaryBtn)="showTurnOffModal = false"
           primaryBtn="Turn off"
           (atPrimaryBtn)="onTurnAutoRenewOff()">

  <form novalidate [formGroup]="turnOffForm" (ngSubmit)="onTurnAutoRenewOff()">
    <div class="form-group">
      <label for="turnOffForm__subscriptionId">Subscription id</label>
      <input id="turnOffForm__subscriptionId" class="form-control" type="text" formControlName="subscriptionId"
             placeholder="Subscription ID">
    </div>

    <div formArrayName="reasons">
      <div *ngFor="let reason of turnOffForm['controls'].reasons['controls']; let i = index" class="input-group mb-3"
           formGroupName="{{i}}">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <input type="checkbox" formControlName="enabled">
          </div>
        </div>
        <input [ngClass]="{'col-3': reason.get('name').value === 'Other' && reason.get('enabled').value}" type="text"
               class="form-control"
               formControlName="name" style="pointer-events: none;">
        <textarea *ngIf="reason.get('name').value === 'Other' && reason.get('enabled').value"
                  placeholder="Write a reason" formControlName="value"
                  class="form-control"></textarea>
      </div>
    </div>
  </form>

</app-modal>

<!-- Create shipment modal -->
<app-modal title="Create free Shipment"
           [show]="showFreeShipmentModal"
           (atCloseBtn)="showFreeShipmentModal = false"
           secondaryBtn="Cancel"
           (atSecondaryBtn)="showFreeShipmentModal = false"
           primaryBtn="Buy Label"
           [disablePrimaryBtn]="!freeShipmentForm.valid"
           (atPrimaryBtn)="createFreeShipment()">

  <form novalidate [formGroup]="freeShipmentForm">
    <div class="form-group">
      <label for="subcription">Subscription id</label>
      <input style="pointer-events: none;" id="subcription" class="form-control" type="text"
             formControlName="subscription" placeholder="Subscription ID">
    </div>

    <div class="form-group">
      <label for="customer">Customer id</label>
      <input style="pointer-events: none;" id="customer" class="form-control" type="text" formControlName="customer"
             placeholder="Customer ID">
    </div>

    <div class="form-group" formGroupName="address">
      <label>Address</label>
      <div class="input-group mb-3">
        <input type="text" class="form-control" formControlName="shippingAddress" placeholder="Address"/>
        <input type="text" class="form-control" formControlName="shippingAddressExtra" placeholder="Address extra"/>
      </div>
      <div class="input-group mb-3">
        <input type="text" class="form-control" formControlName="city" placeholder="City"/>
        <input type="text" class="form-control" formControlName="state" placeholder="State"/>
        <input type="text" class="form-control" formControlName="stateCode" placeholder="State Code"/>
      </div>
      <div class="input-group mb-3">
        <input type="text" class="form-control" formControlName="zip" placeholder="Zip code"/>
        <input type="text" class="form-control" formControlName="country" placeholder="Country"/>
        <input type="text" class="form-control" formControlName="countryCode" placeholder="Country Code"/>
      </div>
    </div>
  </form>

</app-modal>

<!-- Change preferred shipper modal -->
<app-modal title="Change Preferred Shipper"
           [show]="showPreferredShipperModal"
           (atCloseBtn)="showPreferredShipperModal = false"
           secondaryBtn="Cancel"
           (atSecondaryBtn)="showPreferredShipperModal = false"
           primaryBtn="Change Preferred Shipper"
           [disablePrimaryBtn]="!preferredShipperForm.valid || shipmentRatesRequested"
           (atPrimaryBtn)="onChangePreferredShipperAction()">

  <form novalidate [formGroup]="preferredShipperForm">
    <div class="form-group">
      <label for="subcription">Subscription id</label>
      <input style="pointer-events: none;" id="subcriptionId" class="form-control" type="text"
             formControlName="subscriptionId" placeholder="Subscription ID">
    </div>

    <div *ngIf="!shipmentRatesRequested && shipmentRates; else ratesLoading" class="form-group">
      <div class="form-group">
        <label>Select Carrier:</label>
        <select formControlName="selectedCarrier" class="form-control" (change)="onChangeSelectedCarrier($event)">
          <option *ngFor="let rate of byKeys(shipmentRates)" [value]="rate"
                  [selected]="this.preferredShipperForm.get('selectedCarrier').value === rate">
            {{ rate }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>Select a Class:</label>
        <select formControlName="selectedService" class="form-control">
          <option
            *ngFor="let service of byKeys(shipmentRates[this.preferredShipperForm.get('selectedCarrier').value])"
            [value]="service"
            [selected]="this.preferredShipperForm.get('selectedService').value === service">
            {{ service }}
          </option>
        </select>
      </div>
    </div>

    <ng-template #ratesLoading>
      <p>Loading...</p>
    </ng-template>
    <!--
        <div class="form-group" formGroupName="address">
          <label>Address</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" formControlName="shippingAddress" placeholder="Address" />
            <input type="text" class="form-control" formControlName="shippingAddressExtra" placeholder="Address extra" />
          </div>
          <div class="input-group mb-3">
            <input type="text" class="form-control" formControlName="city" placeholder="City" />
            <input type="text" class="form-control" formControlName="state" placeholder="State" />
            <input type="text" class="form-control" formControlName="stateCode" placeholder="State Code" />
          </div>
          <div class="input-group mb-3">
            <input type="text" class="form-control" formControlName="zip" placeholder="Zip code" />
            <input type="text" class="form-control" formControlName="country" placeholder="Country" />
            <input type="text" class="form-control" formControlName="countryCode" placeholder="Country Code" />
          </div>
        </div> -->
  </form>

</app-modal>


<!-- Process subscription modal -->
<div
  *ngIf="processSubscriptionsOptsForm !== undefined"
  [ngClass]="{ 'show': showProcessSubscriptionOptionsModal }"
  [ngStyle]="{
    'display': showProcessSubscriptionOptionsModal ? 'block' : 'none',
    'backgroundColor': showProcessSubscriptionOptionsModal ? 'rgba(0, 0, 0, .8)' : 'transparent'
  }"
  class="modal fade"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Customize how the subscription should be processed.
        </h5>
        <button (click)="showProcessSubscriptionOptionsModal = false;" class="close" data-dismiss="modal" type="button"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form novalidate [formGroup]="processSubscriptionsOptsForm">
          <div class="form-group">
            <label for="customerId">Customer ID</label>
            <input style="pointer-events: none;" id="customerId" class="form-control" type="text"
                   formControlName="customerId" placeholder="Customer ID">
          </div>
          <div class="form-group">
            <label>Subscription ID</label>
            <input class="form-control" style="pointer-events: none;" type="text"
                   formControlName="subscriptionId" placeholder="Subscription ID">
          </div>

          <h5>Options</h5>

          <div class="form-group form-check" *ngIf="currentSubscriptionGiftIt">
            <input type="checkbox" class="form-check-input" id="processGiftItIfExist"
                   formControlName="processGiftItIfExist">
            <label class="form-check-label" for="processGiftItIfExist">Process gift it to {{
              currentSubscriptionGiftIt.name }} ({{ currentSubscriptionGiftIt.shipmentDate }}) now.</label>
          </div>
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="cleanDeclineStatus"
                   formControlName="cleanDeclineStatus">
            <label class="form-check-label" for="cleanDeclineStatus">Clean decline status after run.</label>
          </div>
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="ignoreFreeMonths" formControlName="ignoreFreeMonths">
            <label class="form-check-label" for="ignoreFreeMonths">Ignore subscription free months.</label>
          </div>
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="keepNBD" formControlName="keepNBD">
            <label class="form-check-label" for="keepNBD">Keep next billing date.</label>
          </div>
          <div class="form-group">
            <label for="discount">Discount</label>
            <input min="0" max="16.5" id="discount" class="form-control" type="number" formControlName="discount"
                   placeholder="Discount">
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button (click)="processSubscription()" [disabled]="isProcessing"
                class="btn btn-secondary"
                type="button">
          Process subscription
        </button>
      </div>
    </div>
  </div>
</div>


<div
  *ngIf="resetPasswordForm !== undefined"
  [ngClass]="{ 'show': showResetPasswordModal }"
  [ngStyle]="{
    'display': showResetPasswordModal ? 'block' : 'none',
    'backgroundColor': showResetPasswordModal ? 'rgba(0, 0, 0, .8)' : 'transparent'
  }"
  class="modal fade"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Reset User Password
        </h5>
        <button (click)="showResetPasswordModal = false;" type="button" class="close" data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form novalidate [formGroup]="resetPasswordForm">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="resetUserPassword">Reset Password</label>
                <input id="resetUserPassword" class="form-control" type="text"
                       autocomplete="off"
                       formControlName="password" placeholder="User Password">
              </div>

            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="processResetPassword()">
          Send
        </button>
      </div>
    </div>
  </div>
</div>

<app-payment-methods-modal
  [customer]="selectedCustomer"
  [paymentMethods]="selectedCustomer.paymentMethods"
  (updated)="refreshList()"
  (close)="onClosePaymentMethodsModal()"
  *ngIf="showPaymentMethosdModal">
</app-payment-methods-modal>

<app-change-subscription-box
  [show]="showSubscriptionToSelectBox"
  (closed)="showSubscriptionToSelectBox = false; subscriptionToSelectBox = undefined"
  [selectedSubscription]="subscriptionToSelectBox">

</app-change-subscription-box>
