import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, AfterViewInit, ChangeDetectorRef, ElementRef, ViewRef } from '@angular/core';
import * as client from 'braintree-web/client';
import * as hostedFields from 'braintree-web/hosted-fields';
import { Customer } from '@app/models/customer.model';
import { BraintreeService } from '@app/core/services';
import { CustomerService } from '@app/services';
import { FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AlertService } from '@app/shared/components/alerts/alert.service';
var PaymentMethodsModalComponent = /** @class */ (function () {
    function PaymentMethodsModalComponent(alertService, customerService, braintreeService, changeDetector) {
        this.alertService = alertService;
        this.customerService = customerService;
        this.braintreeService = braintreeService;
        this.changeDetector = changeDetector;
        this.dropInInstance = null;
        this.canSubmitPayment = false;
        this.isFocus = false;
        this.isFormValid = false;
        this.isClientInitialized = false;
        this.showErrorMessages = false;
        this.showTokenizeError = false;
        this.isNumberTouched = false;
        this.isCvvTouched = false;
        this.isExpDateTouched = false;
        this.cardholderNameControl = new FormControl('', Validators.required);
        this.fields = {
            number: {
                selector: '#card-number',
                placeholder: 'Card Number'
            },
            cvv: {
                selector: '#cvv',
                placeholder: '123'
            },
            expirationDate: {
                selector: '#expiration-date',
                placeholder: '12/2019'
            }
        };
        this.styles = {
            'input': {
                'font-size': '14px',
                'font-family': '"Omnes Regular", Helvetica, sans-serif',
                'font-weight': '300',
                'margin-top': '5px'
            },
            '.input-error::placeholder': {
                'color': 'red'
            }
        };
        this.close = new EventEmitter();
        this.updated = new EventEmitter();
    }
    PaymentMethodsModalComponent.prototype.ngOnInit = function () {
    };
    PaymentMethodsModalComponent.prototype.ngAfterViewInit = function () {
        this.submitBtn = document.getElementById('update-btn');
    };
    PaymentMethodsModalComponent.prototype.onAddPaymentMethod = function () {
        this.showNewPaymentMethod = true;
        this.selectedPaymentMethod = undefined;
        this.initializeCreditCardForm();
    };
    PaymentMethodsModalComponent.prototype.onEditPaymentMethod = function (paymentMethod) {
        this.showNewPaymentMethod = false;
        this.selectedPaymentMethod = paymentMethod;
        this.initializeCreditCardForm();
    };
    PaymentMethodsModalComponent.prototype.markAsDefaultPaymentMethod = function (paymentMethod) {
        var _this = this;
        this.customerService
            .defaultPaymentMethod({ customerId: this.customer.id, paymentMethodId: paymentMethod.id })
            .subscribe(function (response) {
            _this.paymentMethods = response.paymentMethods;
            _this.detectChanges();
        });
    };
    PaymentMethodsModalComponent.prototype.initializeCreditCardForm = function () {
        this.initializeBraintreeClientSDK();
    };
    PaymentMethodsModalComponent.prototype.onDeletePaymentMethod = function (paymentMethod) {
        var _this = this;
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(function (result) {
            if (result.isConfirmed) {
                _this.customerService
                    .deletePaymentMethod({ customerId: _this.customer.id, paymentMethodId: paymentMethod.id })
                    .subscribe(function (response) {
                    _this.paymentMethods = response.paymentMethods;
                    _this.detectChanges();
                }, function (error) {
                    _this.alertService.danger({
                        alertsCode: 'dashboard-alerts',
                        title: "Can't delete payment method",
                        message: error.error && error.error.message || error.message,
                        timeout: 5000
                    });
                });
            }
        });
    };
    PaymentMethodsModalComponent.prototype.addNewPaymentMethod = function () {
        var _this = this;
        if (!this.isFormValid || this.cardholderNameControl.invalid) {
            this.showErrorMessages = true;
            this.checkAndAddInputErrorClass();
            return;
        }
        this.isClientInitialized = false;
        this.hostedFields.tokenize()
            .then(function (payload) {
            _this.showErrorMessages = false;
            _this.showTokenizeError = false;
            return _this.customerService
                .addPaymentMethod({
                customerId: _this.customer.id,
                nonce: payload.nonce,
                cardholderName: _this.cardholderNameControl.value
            })
                .toPromise()
                .then(function (response) {
                _this.paymentMethods = response.paymentMethods;
                _this.cancelEditing();
                _this.isClientInitialized = false;
            }, function (error) {
                _this.alertService.danger({
                    alertsCode: 'dashboard-alerts',
                    title: "Can't add the payment method",
                    message: error.error && error.error.message || error.message,
                    timeout: 5000
                });
            });
        })
            .catch(function (err) {
            _this.showErrorMessages = true;
            _this.showTokenizeError = true;
            _this.checkAndAddInputErrorClass();
        });
    };
    PaymentMethodsModalComponent.prototype.updatePaymentMethod = function () {
        var _this = this;
        if (!this.isFormValid || this.cardholderNameControl.invalid) {
            this.showErrorMessages = true;
            this.checkAndAddInputErrorClass();
            return;
        }
        this.isClientInitialized = false;
        this.hostedFields.tokenize()
            .then(function (payload) {
            _this.showErrorMessages = false;
            _this.showTokenizeError = false;
            return _this.customerService
                .updatePaymentMethod({
                customerId: _this.customer.id,
                nonce: payload.nonce,
                paymentMethodId: _this.selectedPaymentMethod.id,
                cardholderName: _this.cardholderNameControl.value,
            })
                .toPromise()
                .then(function () {
                _this.cancelEditing();
                _this.closeModal();
                _this.isClientInitialized = true;
            });
        })
            .catch(function (err) {
            _this.showErrorMessages = true;
            _this.showTokenizeError = true;
            _this.checkAndAddInputErrorClass();
        });
    };
    /**
     * Initialize the Braintree Client sdk.
     * This methods needs to be called every time the
     * payment form is in edit mode (is not saved) in order
     * to re-initialize the client sdk with the new data.
     *
     * @memberof SecurePaymentFormComponent
     */
    PaymentMethodsModalComponent.prototype.initializeBraintreeClientSDK = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var clientToken;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isClientInitialized = false;
                        return [4 /*yield*/, this.braintreeService.getClientAuthorizationToken()];
                    case 1:
                        clientToken = _a.sent();
                        client
                            .create({ authorization: clientToken })
                            .then(function (clientInstance) { return hostedFields.create({
                            client: clientInstance,
                            fields: _this.fields,
                            styles: _this.styles
                        }); })
                            .then(function (hostedFieldsInstance) {
                            _this.isClientInitialized = true;
                            _this.hostedFields = hostedFieldsInstance;
                            _this.hostedFields.on('validityChange', function (e) {
                                var state = _this.hostedFields.getState();
                                var formValid = Object
                                    .keys(state.fields)
                                    .every(function (key) {
                                    _this.isCvvValid = state.fields.cvv.isValid;
                                    _this.isNumberValid = state.fields.number.isValid;
                                    _this.isExpDateValid = state.fields.expirationDate.isValid;
                                    return state.fields[key].isValid;
                                });
                                _this.isFormValid = formValid;
                            });
                            _this.hostedFields.on('focus', function (e) {
                                switch (e.emittedBy) {
                                    case 'number': {
                                        _this.isNumberTouched = e.fields.number.isFocused ? true : _this.isNumberTouched;
                                        break;
                                    }
                                    case 'cvv': {
                                        _this.isCvvTouched = e.fields.cvv.isFocused ? true : _this.isCvvTouched;
                                        break;
                                    }
                                    case 'expirationDate': {
                                        _this.isExpDateTouched = e.fields.expirationDate.isFocused ? true : _this.isExpDateTouched;
                                        break;
                                    }
                                }
                            });
                        })
                            .catch(function (e) { return console.log(e); });
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentMethodsModalComponent.prototype.checkAndAddInputErrorClass = function () {
        if (!this.isNumberValid && this.isNumberTouched && this.showErrorMessages) {
            this.hostedFields.clear('number');
            this.hostedFields.addClass('number', 'input-error');
        }
        if (!this.isCvvValid && this.isCvvTouched && this.showErrorMessages) {
            this.hostedFields.clear('cvv');
            this.hostedFields.addClass('cvv', 'input-error');
        }
        if (!this.isExpDateValid && this.isExpDateTouched && this.showErrorMessages) {
            this.hostedFields.clear('expirationDate');
            this.hostedFields.addClass('expirationDate', 'input-error');
        }
    };
    PaymentMethodsModalComponent.prototype.cancelEditing = function () {
        this.selectedPaymentMethod = null;
        this.isClientInitialized = null;
        this.showNewPaymentMethod = null;
        this.detectChanges();
    };
    PaymentMethodsModalComponent.prototype.closeModal = function () {
        this.close.emit();
    };
    PaymentMethodsModalComponent.prototype.detectChanges = function () {
        if (this.changeDetector && !this.changeDetector.destroyed) {
            this.changeDetector.detectChanges();
        }
    };
    return PaymentMethodsModalComponent;
}());
export { PaymentMethodsModalComponent };
