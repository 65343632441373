import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { BoxesService } from "@app/core/services/boxes.service";
var ChangeSubscriptionBoxComponent = /** @class */ (function () {
    function ChangeSubscriptionBoxComponent(fb, boxesService) {
        this.fb = fb;
        this.boxesService = boxesService;
        this.closed = new EventEmitter();
        this.componentDestroyed$ = new Subject();
        this.selectedSubscription$ = new ReplaySubject();
        this.showModal$ = new BehaviorSubject(false);
        this.boxesRequested$ = new BehaviorSubject(false);
        this.boxes$ = new BehaviorSubject([]);
    }
    Object.defineProperty(ChangeSubscriptionBoxComponent.prototype, "show", {
        set: function (status) {
            this.showModal$.next(status);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeSubscriptionBoxComponent.prototype, "selectedSubscription", {
        set: function (subscription) {
            this.selectedSubscription$.next(subscription);
        },
        enumerable: true,
        configurable: true
    });
    ChangeSubscriptionBoxComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.boxesService.getDefaultBoxes()
            .subscribe(function (boxes) {
            _this.boxes$.next(boxes);
            _this.boxesRequested$.next(true);
        });
        this.selectedSubscription$.asObservable()
            .pipe(filter(function (subscription) { return !!subscription; }))
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(function (subscription) {
            console.log(subscription);
            _this.initForm(subscription.id, subscription.selectedBox);
        });
    };
    ChangeSubscriptionBoxComponent.prototype.ngOnDestroy = function () {
        this.componentDestroyed$.next();
    };
    ChangeSubscriptionBoxComponent.prototype.initForm = function (subscriptionId, selectedBox) {
        this.boxSelectedForm = this.fb.group({
            subscriptionId: [subscriptionId, Validators.required],
            selectedBox: [selectedBox, Validators.required],
        });
    };
    ChangeSubscriptionBoxComponent.prototype.onBoxSelected = function () {
        var _this = this;
        this.boxesService.setDefaultBox(this.boxSelectedForm.get('subscriptionId').value, this.boxSelectedForm.get('selectedBox').value)
            .subscribe(function (response) {
            _this.closed.emit();
        });
    };
    return ChangeSubscriptionBoxComponent;
}());
export { ChangeSubscriptionBoxComponent };
