import { PaymentMethodsModalComponent } from './payment-method-modal/payment-methods-modal.component';
import { ShipmentItemComponent } from './shipment-item/shipment-item.component';
import { ChangeSubscriptionBoxComponent } from "@app/dashboard/components/change-subscription-box/change-subscription-box.component";

export const components = [
  PaymentMethodsModalComponent,
  ShipmentItemComponent,
  ChangeSubscriptionBoxComponent
];

export { PaymentMethodsModalComponent } from './payment-method-modal/payment-methods-modal.component';
export { ShipmentItemComponent } from './shipment-item/shipment-item.component';
export { ChangeSubscriptionBoxComponent } from "@app/dashboard/components/change-subscription-box/change-subscription-box.component";
