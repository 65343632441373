var _a;
import { createSelector } from '@ngrx/store';
import * as fromRoot from './../selectors';
import * as fromNotes from './../../store/reducers/notes.reducer';
export var getNotesIds = (_a = fromNotes.adapter.getSelectors(fromRoot.getNotesState), _a.selectIds), getNotesEntities = _a.selectEntities, getTotalANotes = _a.selectTotal, getAllNotes = _a.selectAll;
var ɵ0 = function (state) { return state.loaded; };
export var getNotesLoaded = createSelector(fromRoot.getNotesState, ɵ0);
var ɵ1 = function (state) { return state.loading; };
export var getNotesLoading = createSelector(fromRoot.getNotesState, ɵ1);
export { ɵ0, ɵ1 };
