// Services
import { DashboardService } from './services/dashboard.service';
import { StatsService } from './services/stats.service';
// Components
import * as fromComponents from './components';
import { DashboardComponent } from './dashboard.component';
import { RetentionComponent } from './pages/retention/retention.component';
import { CustomerShipmentsComponent } from './containers/customer-shipments/customer-shipments.component';
import { ShipmentItemComponent } from '@app/dashboard/components';
import { SearchComponent } from '@app/dashboard/components/search/search.component';
var SERVICES = [
    DashboardService,
    StatsService
];
var COMPONENTS = [
    DashboardComponent,
    RetentionComponent,
    CustomerShipmentsComponent,
    ShipmentItemComponent,
    SearchComponent
].concat(fromComponents.components);
var DashboardModule = /** @class */ (function () {
    function DashboardModule() {
    }
    return DashboardModule;
}());
export { DashboardModule };
