var _a;
import { createSelector } from '@ngrx/store';
import * as fromRoot from './../selectors';
import * as fromAddress from './../../store/reducers/address.reducer';
export var getAddressesIds = (_a = fromAddress.adapter.getSelectors(fromRoot.getAddressState), _a.selectIds), getAddressEntities = _a.selectEntities, getTotalAddresses = _a.selectTotal, getAllAddresses = _a.selectAll;
var ɵ0 = function (state) { return state.loaded; };
export var getAddressesLoaded = createSelector(fromRoot.getAddressState, ɵ0);
var ɵ1 = function (state) { return state.loading; };
export var getSAddressesLoading = createSelector(fromRoot.getAddressState, ɵ1);
export { ɵ0, ɵ1 };
