import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as AppData from '@config/app.json';
import * as fromAdminActions from './store/actions/admin.actions';
import * as fromRoot from './store/selectors/index';
// services
import * as fromServices from './services/auth.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, store, authService) {
        this.router = router;
        this.store = store;
        this.authService = authService;
        this.appData = AppData.default;
    }
    Object.defineProperty(AppComponent.prototype, "auth", {
        get: function () {
            return localStorage.getItem('ssops:v0.0.1:li');
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.admin$ = this.store.select(fromRoot.getAdminState);
        this.admin$
            .subscribe(function (admin) {
            if (!admin.isAuthenticated && _this.authService.token) {
                _this.store.dispatch(new fromAdminActions.LoadAction());
            }
        });
    };
    AppComponent.prototype.logOut = function () {
        var _this = this;
        localStorage.removeItem('ssops:v0.0.1:li');
        this.router.navigate(['/login'])
            .then(function (n) { return _this.store.dispatch(new fromAdminActions.LogoutAction()); });
    };
    return AppComponent;
}());
export { AppComponent };
