<app-modal title="Process future subscriptions"
           [show]="active$ | async"
           (atCloseBtn)="active = false"
           secondaryBtn="Cancel"
           (atSecondaryBtn)="active = false"
           [primaryBtn]="(loading$ | async) ? 'Loading...' : 'Confirm'"
           (atPrimaryBtn)="onSubmit$.next()">

  <form [formGroup]="form" (ngSubmit)="onSubmit$.next()">
    <div class="form-group col-sm-12">
      <label for="totalSubscription">Total of subscriptions</label>
      <input class="col-12 form-control" min="1" max="100" type="number" formControlName="totalSubscriptions" id="totalSubscription" placeholder="Limit is 100 per request" />
    </div>
  </form>

</app-modal>
