import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { BehaviorSubject, of, Subject } from "rxjs";
import { BillingService } from "@app/services/billing.service";
import { filter, switchMap, take, takeUntil, withLatestFrom } from "rxjs/operators";

@Component({
  selector: 'app-process-subscriptions',
  templateUrl: './process-subscriptions.component.html',
  styleUrls: ['./process-subscriptions.component.scss']
})
export class ProcessSubscriptionsComponent implements OnInit, OnDestroy {
  active$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  onSubmit$: Subject<void> = new Subject<void>();
  componentDestroyed$: Subject<void> = new Subject<void>();

  @Input()
  set active(value: boolean) {
    this.active$.next(value);
  }

  @Output()
  success: EventEmitter<boolean> = new EventEmitter<boolean>();

  form = this.fb.group({
    totalSubscriptions: [100, Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private billingService: BillingService,
  ) { }

  ngOnInit() {
    this.onSubmit$
      .pipe(withLatestFrom(this.loading$.asObservable()))
      .pipe(filter(([_, loading]) => loading === false))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(this.onSubmit.bind(this))
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  onSubmit() {
    if (!!this.form.valid) {
      this.loading$.next(true);
      this.billingService.processFutureSubscriptions(this.form.get('totalSubscriptions').value)
        .subscribe(
          response => {
            if (response && response.total === 0) {
              alert(`We don't have more subscriptions to process today.`);
            }

            this.success.next(true);
            this.active$.next(false);
            this.loading$.next(false);
          },
          response => {
            this.loading$.next(false);
            try {
              alert(JSON.stringify(response.error))
            } catch (e) {
              alert(response.error || 'We got an unexpected error')
            }
          }
        )
    }
  }

}
