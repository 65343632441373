import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';

import {
  Observable,
  BehaviorSubject
} from 'rxjs';
import { map, delay, switchMap, take } from 'rxjs/operators';

// Models
import { Subscription } from '../models/subscription.model';
import { Address } from '../models/address.model';

// Services
import { ShipmentService } from './shipment.service';
import { Shipment } from '@app/models/shipment.model';

@Injectable()
export class SubscriptionService {
  private apiUrl = environment.apiUrl + '/api';
  private subscriptionsState: Subscription[] = [];
  private subscriptionsSubject: BehaviorSubject<Subscription[]> = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private shipmentsService: ShipmentService
  ) {}

  // TODO: change the name of the getSubscriptions to getSubscriptionState
  public getSubscriptions(): Observable<Subscription[]> {
    return this.subscriptionsSubject;
  }

  public updateSubscriptionsState(data: Subscription[]) {
    this.subscriptions = data;
  }

  private set subscriptions(data: Subscription[]) {
    this.subscriptionsState = data;
    this.subscriptionsSubject.next(data);
  }

  private get subscriptions(): Subscription[] {
    return this.subscriptionsState.slice();
  }

  public cancelSubscription(id: number, reasons: { name: string, value: string }): Observable<Subscription> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('admin', '5a680f4a4e8ac8bab59f2144');
    return this.http.request('delete', `${this.apiUrl}/admin/subscriptions/${id}`, { body: { reasons }, params: httpParams })
      .pipe(map((response: any) => {
        const updatedSubscription = new Subscription(response.subscription);
        this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
        return updatedSubscription;
      }));
  }

  public skipSubscription(subscriptionId: number): Observable<Subscription> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('admin', '5a680f4a4e8ac8bab59f2144');
    return this.http.request('post', `${this.apiUrl}/admin/subscriptions/skipMonth`, {
      body: { subscriptionId, totalMonths: 1 },
      params: httpParams
    })
      .pipe(map((response: any) => {
        const updatedSubscription = new Subscription(response.subscription);
        this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
        return updatedSubscription;
      }));
  }

  public editSubscription(data: any): Observable<Subscription> {
    return this.http.request('put', `${this.apiUrl}/admin/subscriptions/${data.subscriptionId}`, {
      body: data
    }).pipe(map((response: any) => {
      const updatedSubscription = new Subscription(response.data);
      this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
      return updatedSubscription;
    }));
  }

  public fetchSubscriptionsRates(subscriptionId: number): Observable<any[]> {
    return this.http.get(`${this.apiUrl}/admin/subscriptions/${subscriptionId}/rates`)
      .pipe(map((response: any) => response.data));
  }

  updateSubscriptionAddress({ subscription, address }): Observable<{ subscription: Subscription, address: Address }> {
    const subscriptionObj = { ...subscription, _id: subscription.id };
    return this.http
      .put(`${this.apiUrl}/admin/subscriptions/${subscription.id}/address`, { subscription: subscriptionObj, address })
      .pipe(map((data: { address, subscription }) => {
        const updatedSubscription = new Subscription(data.subscription);
        const updatedAddress = new Address(data.address);
        this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
        return {
          subscription: updatedSubscription,
          address: updatedAddress,
        };
      }));
  }

  switchAutoRenew(data: { autoRenew, nextBillingDate, subscriptionId, userId, reasons? }): Observable<Subscription> {
    return this.http
      .put(`${this.apiUrl}/users/${data.userId}/subscriptions/${data.subscriptionId}/${data.autoRenew ? 'turn-on' : 'turn-off'}`, {
        nextBillingDate: data.nextBillingDate,
        turnedOffReasons: data.reasons
      })
      .pipe(delay(1000), map((results: any) => {
        const updatedSubscription = new Subscription(results.subscription);
        this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
        return updatedSubscription;
      }));
  }

  processSubscriptionBilling(data: ProcessSubscriptionBillingParams): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/users/${data.customerId}/subscriptions/${data.subscriptionId}/process-subscription-billing`, {
        options: data,
        origin: 'ops'
      })
      .pipe(
        delay(1000),
        switchMap(
          () => this.shipmentsService.getShipmentsState().pipe(take(1)),
          (result: any, shipments: Shipment[]) => [result, shipments]
        ),
        map(([ result, shipments ]) => {
          const updatedSubscription = new Subscription(result.subscription);
          const updatedShipments = result.shipments.map(item => new Shipment(item));
          this.shipmentsService.updateShipmentsState(this.shipmentsService.mergeShipments(shipments, updatedShipments));
          this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
          return updatedSubscription;
        })
      );
  }

  updatePreferredShipper({ subscriptionId, ...data }): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/admin/subscriptions/${subscriptionId}/change-preferred-shipper`, { ...data })
      .pipe(
        delay(1000),
        map((result: any) => {
          const updatedSubscription = new Subscription(result.subscription);
          this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
          return updatedSubscription;
        })
      );
  }

  clearDeclineStatus(subscriptionId) {
    return this.http
      .put(`${this.apiUrl}/admin/subscriptions/${subscriptionId}/clear-decline-status`, {})
      .pipe(
        map((result: any) => {
          const updatedSubscription = new Subscription(result.data.subscription);
          this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
          return updatedSubscription;
        })
      );
  }

  // UTILS
  private mergeSubscriptions(oldSubscriptions: Subscription[], newSubscriptions: Subscription[]) {
    if (oldSubscriptions.length === 0) {
      return newSubscriptions;
    }
    return newSubscriptions.reduce((allSubscription, newSubscription) => {
      const temp = 'length' in allSubscription ? allSubscription : [];
      const subscriptionIndex = temp.findIndex((subscription: Subscription) => subscription.id === newSubscription.id);
      if (subscriptionIndex === -1) {
        return [...temp, newSubscription];
      } else {
        return [
          ...temp.slice(0, subscriptionIndex),
          newSubscription,
          ...temp.slice(subscriptionIndex + 1)
        ];
      }
    }, oldSubscriptions);
  }
}

export interface ProcessSubscriptionBillingParams {
  subscriptionId: string;
  customerId: string;
  processGiftItIfExist: boolean;
  cleanDeclineStatus: boolean;
  ignoreFreeMonths: boolean;
  keepNBD: boolean;
  discount: number;
}
