import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { format, isBefore } from 'date-fns';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
// services
import * as fromServices from './../services';
import { PaymentService } from './../services';
// actions
import * as fromAddressActions from './../store/actions/address.actions';
import * as fromNotesActions from './../store/actions/notes.actions';
// selectors
import * as fromAddressSelectors from './../store/selectors/address.selectors';
import * as fromNotesSelectors from './../store/selectors/notes.selectors';
// models
import { Customer } from '@app/models/customer.model';
import { Subscription as SubscriptionModel } from '@app/models/subscription.model';
import { Shipment, ShipmentRate } from '@app/models/shipment.model';
import { ActionService } from '@app/services/action.service';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { Note } from '@app/models/note.model';
import { AlertService } from '@app/shared/components/alerts/alert.service';
import { Action } from '@app/models/action.model';
import { Address } from '@app/models/address.model';
import { Payment } from '@app/models/payment.model';
import { environment } from '@env/environment';
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(customerService, addressService, subscriptionService, shipmentsService, configService, planService, alertService, actionService, paymentService, dashboardService, fb, store) {
        this.customerService = customerService;
        this.addressService = addressService;
        this.subscriptionService = subscriptionService;
        this.shipmentsService = shipmentsService;
        this.configService = configService;
        this.planService = planService;
        this.alertService = alertService;
        this.actionService = actionService;
        this.paymentService = paymentService;
        this.dashboardService = dashboardService;
        this.fb = fb;
        this.store = store;
        this.serverUrl = environment.apiUrl;
        this.searchForm = new FormGroup({
            searchValue: new FormControl('', Validators.required),
            filter: new FormControl('email', Validators.required)
        });
        this.searchBy = [{
                name: 'E-mail',
                value: 'email'
            }, {
                name: "User's name",
                value: 'user_name',
            }, {
                name: "Recipient's name",
                value: 'recipient_name'
            }, {
                name: 'Tracking code',
                value: 'tracking_code'
            }];
        this.searchFilterSelected$ = new BehaviorSubject('E-mail');
        this.showSubscriptionToSelectBox = false;
        this.customers = [];
        this.subscriptions = [];
        this.shipments = [];
        this.actions = [];
        this.plans = [];
        this.giftPlans = [];
        this.giftPlansToIds = [
            '5a10dbfaf8ceb4830ff186a9',
            '5abd46654d4f52a1ea64fa91',
            '5b33d996641451a36467b89d'
        ];
        this.address = {};
        this.notesIds = [];
        this.notes = {};
        this.payments = [];
        this.listItems$ = new BehaviorSubject([]);
        this.showCancelModal = false;
        this.showTurnOffModal = false;
        this.showSubscriptionEditModal = false;
        this.showProcessSubscriptionOptionsModal = false;
        this.showCustomerEditModal = false;
        this.showAutoRenewOnModal = false;
        this.showFreeShipmentModal = false;
        this.showPreferredShipperModal = false;
        this.shipmentRatesRequested = false;
        this.showResetPasswordModal = false;
        this.newNoteText = new FormControl('');
        this.showPaymentMethosdModal = false;
        this.referralPromotions = [];
        this.isSearching = false;
        this.isProcessing = false;
    }
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.address$ = this.store.select(fromAddressSelectors.getAddressEntities);
        this.addressIds$ = this.store.select(fromAddressSelectors.getAddressesIds);
        this.notes$ = this.store.select(fromNotesSelectors.getNotesEntities);
        this.notesIds$ = this.store.select(fromNotesSelectors.getNotesIds);
        this.address$
            .subscribe(function (address) { return _this.address = address; });
        this.notes$
            .subscribe(function (notes) { return _this.notes = notes; });
        this.notesIds$
            .subscribe(function (notes) { return _this.notesIds = notes; });
        this.actionsState$ = this.actionService.getActionsState()
            .subscribe(function (actions) { return _this.actions = actions; });
        this.planState$ = this.planService.getPlansState()
            .subscribe(function (plans) {
            _this.plans = plans;
            _this.giftPlans = plans
                .filter(function (p) { return p.type === 'GIFT' && ['2x_3gift', '2x_6gift', '2x_12gift'].includes(p.name); });
        });
        this.planService.fetchPlans()
            .subscribe(function (plans) {
            _this.plans = plans;
            _this.giftPlans = plans
                .filter(function (p) { return p.type === 'GIFT' && ['2x_3gift', '2x_6gift', '2x_12gift'].includes(p.name); });
        });
        this.customersState$ = this.customerService.getCustomersState()
            .subscribe(function (customers) { return _this.customers = customers; });
        this.subscriptionsState$ = this.subscriptionService.getSubscriptions()
            .subscribe(function (subscriptions) { return _this.subscriptions = subscriptions; });
        this.shipmentsState$ = this.shipmentsService.getShipmentsState()
            .subscribe(function (shipments) { return _this.shipments = shipments; });
        this.paymentsState$ = this.paymentService.getPaymentsState()
            .subscribe(function (payments) { return _this.payments = payments; });
        // this.setSearchBarFunctionality();
        this.configureCancelForm();
        this.configureSubscriptionEditForm();
        this.configureCustomerEditForm();
        this.configureSubscriptionAddressEditForm();
        this.configureAutoRenewOnForm();
        this.configureTurnOffForm();
        this.configureProcessSubsOptionsForm();
        this.configureCreateShipmentForm();
        this.configurePreferresShipperForm();
        this.configureResetPasswordForm();
    };
    DashboardComponent.prototype.ngOnDestroy = function () {
        this.actionsState$.unsubscribe();
        this.planState$.unsubscribe();
        this.customersState$.unsubscribe();
        this.subscriptionsState$.unsubscribe();
        this.shipmentsState$.unsubscribe();
        this.paymentsState$.unsubscribe();
    };
    DashboardComponent.prototype.byKeys = function (entities) {
        if (entities === void 0) { entities = []; }
        return Object.keys(entities);
    };
    DashboardComponent.prototype.refreshList = function () {
        if (this.plans.length > 0) {
            this.listItems$.next(this.createListItems(this.customers, this.subscriptions, this.shipments, this.actions, this.payments));
        }
        else {
            this.listItems$.next(this.createListItems());
        }
    };
    DashboardComponent.prototype.searchBarSelectedItem = function (users) {
        var _this = this;
        this.noResultsFound = false;
        this.isSearching = true;
        combineLatest(this.dashboardService.fetchDataForDashboard(users), this.configService.getReferralPromotions())
            .subscribe(function (_a) {
            var results = _a[0], referralPromotions = _a[1];
            _this.isSearching = false;
            _this.referralPromotions = referralPromotions;
            _this.actionService.updateActionsState(results.actions.map(function (a) { return new Action(a); }));
            _this.customerService.updateCustomersState(results.customers.map(function (c) { return new Customer(c); }));
            _this.subscriptionService.updateSubscriptionsState(results.subscriptions.map(function (s) { return new SubscriptionModel(s); }));
            _this.shipmentsService.updateShipmentsState(results.shipments.map(function (s) { return new Shipment(s); }));
            _this.paymentService.updatePaymentsState(results.payments.map(function (p) { return new Payment(p); }));
            var newAddress = results.address.map(function (address) { return new Address(address); });
            _this.addressService.updateAddressState(newAddress);
            var newNotes = results.notes.map(function (notes) { return new Note(notes); });
            // Load addresses.
            _this.store.dispatch(new fromAddressActions.LoadCustomersAddressesCompleteAction(newAddress));
            _this.store.dispatch(new fromNotesActions.LoadSubscriptionNotesCompleteAction(newNotes));
            _this.refreshList();
        }, function (e) {
            _this.actionService.updateActionsState([]);
            _this.customerService.updateCustomersState([]);
            _this.subscriptionService.updateSubscriptionsState([]);
            _this.shipmentsService.updateShipmentsState([]);
            _this.paymentService.updatePaymentsState([]);
            // Load addresses.
            _this.store.dispatch(new fromAddressActions.LoadCustomersAddressesCompleteAction([]));
            _this.store.dispatch(new fromNotesActions.LoadSubscriptionNotesCompleteAction([]));
            _this.refreshList();
            _this.isSearching = false;
            _this.noResultsFound = true;
            console.log(e);
        });
    };
    DashboardComponent.prototype.createCancelReasonItem = function (name) {
        return this.fb.group({
            name: name,
            value: '',
            enabled: false,
        });
    };
    DashboardComponent.prototype.configureCancelForm = function () {
        this.cancelForm = this.fb.group({
            subscriptionId: [null, Validators.required],
            reasons: this.fb.array([
                this.createCancelReasonItem('Too many plants'),
                this.createCancelReasonItem('Need to save money'),
                this.createCancelReasonItem('Not what expected'),
                this.createCancelReasonItem('Didn’t receive shipment(s)'),
                this.createCancelReasonItem('Other'),
            ]),
        });
    };
    DashboardComponent.prototype.configureTurnOffForm = function () {
        this.turnOffForm = this.fb.group({
            subscriptionId: [null, Validators.required],
            userId: [null, Validators.required],
            reasons: this.fb.array([
                this.createCancelReasonItem('Too many plants'),
                this.createCancelReasonItem('Need to save money'),
                this.createCancelReasonItem('Not what expected'),
                this.createCancelReasonItem('Didn’t receive shipment(s)'),
                this.createCancelReasonItem('Other'),
            ]),
        });
    };
    DashboardComponent.prototype.configureProcessSubsOptionsForm = function () {
        this.processSubscriptionsOptsForm = this.fb.group({
            subscriptionId: ['', Validators.required],
            customerId: ['', Validators.required],
            processGiftItIfExist: [true, Validators.required],
            cleanDeclineStatus: [true, Validators.required],
            ignoreFreeMonths: [false, Validators.required],
            keepNBD: [false, Validators.required],
            discount: [0, Validators.required],
        });
    };
    DashboardComponent.prototype.configureSubscriptionEditForm = function () {
        this.subscriptionEditForm = new FormGroup({
            subscriptionId: new FormControl('', Validators.required),
            plan: new FormControl('', Validators.required),
            isPrepay: new FormControl('', Validators.required),
            recipient: new FormGroup({
                firstName: new FormControl('', Validators.required),
                lastName: new FormControl('', Validators.required),
            }),
            nextBillingDate: new FormControl('', [
                Validators.required,
                Validators.pattern(/\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])/)
            ]),
        });
    };
    DashboardComponent.prototype.configureSubscriptionAddressEditForm = function () {
        this.subscriptionEditAddressForm = new FormGroup({
            shippingAddress: new FormControl('', Validators.required),
            shippingAddressExtra: new FormControl(''),
            city: new FormControl('', Validators.required),
            state: new FormControl('', Validators.required),
            stateCode: new FormControl('', Validators.required),
            zip: new FormControl('', Validators.required),
            country: new FormControl('United States', Validators.required),
            countryCode: new FormControl('USA', Validators.required),
            line: new FormControl('', Validators.required),
            formattedLine: new FormControl('', Validators.required),
            label: new FormControl('', Validators.required),
        });
    };
    DashboardComponent.prototype.configureCustomerEditForm = function () {
        this.customerEditForm = new FormGroup({
            id: new FormControl('', Validators.required),
            firstName: new FormControl('', Validators.required),
            lastName: new FormControl('', Validators.required),
            email: new FormControl('', Validators.required)
        });
    };
    DashboardComponent.prototype.configureAutoRenewOnForm = function () {
        this.autoRenewOnForm = this.fb.group({
            subscriptionId: ['', Validators.required],
            userId: ['', Validators.required],
            nextBillingDate: ['', Validators.required],
        });
    };
    DashboardComponent.prototype.configurePreferresShipperForm = function () {
        this.preferredShipperForm = this.fb.group({
            subscriptionId: [null, Validators.required],
            selectedCarrier: [null, Validators.required],
            selectedService: [null, Validators.required],
        });
    };
    DashboardComponent.prototype.configureResetPasswordForm = function () {
        this.resetPasswordForm = this.fb.group({
            customerId: [null, Validators.required],
            password: [null, Validators.required],
        });
    };
    DashboardComponent.prototype.createListItems = function (customers, subscriptions, shipments, actions, payments) {
        var _this = this;
        if (customers === void 0) { customers = []; }
        if (subscriptions === void 0) { subscriptions = []; }
        if (shipments === void 0) { shipments = []; }
        if (actions === void 0) { actions = []; }
        if (payments === void 0) { payments = []; }
        return customers.reduce(function (allItem, customer, i) {
            var temp = 'length' in allItem ? allItem : [];
            var subsIds = subscriptions.filter(function (sub) { return sub.customerId === customer.id; })
                .map(function (sub) { return sub.id; });
            var tempPayments = _this.payments.filter(function (payment) {
                return subsIds.includes(payment.subscription);
            }) || [];
            temp.push({
                customer: tslib_1.__assign({}, customer, { payments: tempPayments, shipments: shipments.filter(function (s) { return !s.subscriptionId; }) }),
                subscriptions: subscriptions.reduce(function (allSubs, currentSub) {
                    return allSubs.concat([
                        tslib_1.__assign({}, currentSub, { shipments: shipments.filter(function (s) { return s.subscriptionId === currentSub.id; })
                                .sort(function (a, b) { return isBefore(a.createdAt, b.createdAt) ? 1 : -1; }), actions: actions.filter(function (a) { return a.subscriptionId === currentSub.id; })
                                .sort(function (a, b) { return isBefore(a.createdAt, b.createdAt) ? 1 : -1; }) })
                    ]);
                }, [])
            });
            return temp;
        }, []);
    };
    DashboardComponent.prototype.getSubscriptionsShipments = function (subscriptionId, shipments) {
        return shipments
            .filter(function (s) { return s.subscriptionId === subscriptionId; })
            .sort(function (a, b) { return isBefore(a.createdAt, b.createdAt) ? 1 : -1; });
    };
    DashboardComponent.prototype.getSubscriptionsActions = function (subscriptionId, actions) {
        return actions
            .filter(function (s) { return s.subscriptionId === subscriptionId; })
            .sort(function (a, b) { return isBefore(a.createdAt, b.createdAt) ? 1 : -1; });
    };
    DashboardComponent.prototype.showProcessSubscriptionModal = function (subscription) {
        this.showProcessSubscriptionOptionsModal = true;
        this.processSubscriptionsOptsForm.get('subscriptionId').setValue(subscription.id);
        this.processSubscriptionsOptsForm.get('customerId').setValue(subscription.customerId);
        this.currentSubscriptionGiftIt = subscription.giftedShipments
            .find(function (item) { return item.status === 'WAITING_FOR_PAYMENT'; });
    };
    DashboardComponent.prototype.processSubscription = function () {
        var _this = this;
        this.isProcessing = true;
        this.subscriptionService.processSubscriptionBilling(this.processSubscriptionsOptsForm.value)
            .subscribe(function (data) {
            console.log('READY', data);
            _this.showProcessSubscriptionOptionsModal = false;
            _this.refreshList();
            _this.isProcessing = false;
        }, function (e) {
            _this.isProcessing = false;
            _this.showProcessSubscriptionOptionsModal = false;
            _this.alertService.danger({
                alertsCode: 'dashboard-alerts',
                title: "Can't process subscription",
                message: e.error.error ? e.error.error.message : e.error.message
            });
        });
    };
    DashboardComponent.prototype.updateLoading = function (type, id, isLoading) {
        var index;
        switch (type) {
            case 'shipments':
                index = this.shipments.findIndex(function (s) { return s.id === id; });
                this.shipments[index].loading = isLoading;
                this.shipmentsService.updateShipmentsState(this.shipments);
                break;
            case 'subscriptions':
                index = this.subscriptions.findIndex(function (s) { return s.id === id; });
                this.subscriptions[index].loading = isLoading;
                this.subscriptionService.updateSubscriptionsState(this.subscriptions);
                break;
            default:
                console.log("Can't update this kind of type:", type);
                break;
        }
        this.refreshList();
    };
    DashboardComponent.prototype.getPlan = function (planId) {
        return this.plans[this.plans.findIndex(function (plan) { return plan.id === planId; })];
    };
    DashboardComponent.prototype.onCancelAction = function (id) {
        this.cancelForm.get('subscriptionId').setValue(id);
        this.showCancelModal = true;
    };
    DashboardComponent.prototype.onCancelSubmit = function () {
        var _this = this;
        var id = this.cancelForm.get('subscriptionId').value;
        var reasons = this.cancelForm.get('reasons').value.filter(function (reason) { return reason.enabled; });
        this.updateLoading('subscriptions', id, true);
        if (confirm('Are you sure you want to cancel this subscription?')) {
            this.subscriptionService.cancelSubscription(id, reasons)
                .pipe(map(function (response) {
                _this.showCancelModal = false;
                return response;
            }))
                .subscribe(function (response) {
                _this.updateLoading('subscriptions', id, false);
            }, function (error) {
                if (typeof error === 'object' && error.error) {
                    // this.errorMsg = error.error.message;
                }
            });
        }
    };
    DashboardComponent.prototype.onSkip = function (id) {
        var _this = this;
        this.updateLoading('subscriptions', id, true);
        this.subscriptionService.skipSubscription(id)
            .subscribe(function (response) {
            _this.updateLoading('subscriptions', id, false);
        }, function (e) {
            _this.updateLoading('subscriptions', id, false);
        });
    };
    DashboardComponent.prototype.onEditSubscriptionAction = function (subscription) {
        this.showSubscriptionEditModal = true;
        this.subscriptionEditForm.get('subscriptionId').setValue(subscription.id);
        this.subscriptionEditForm.get('isPrepay').setValue(subscription.isPrepay);
        this.subscriptionEditForm.get('plan').setValue(subscription.plan);
        this.subscriptionEditForm.get('recipient').setValue({
            firstName: subscription.recipient.firstName,
            lastName: subscription.recipient.lastName
        });
        this.subscriptionEditForm.get('nextBillingDate').setValue(subscription.nextBillingDate);
        if (subscription.giftMessage) {
            this.subscriptionEditForm.addControl('giftMessage', new FormControl(''));
            this.subscriptionEditForm.get('giftMessage').setValue(subscription.giftMessage);
        }
        else {
            this.subscriptionEditForm.removeControl('giftMessage');
        }
        this.subscriptionEditAddressForm.setValue({
            shippingAddress: this.address[subscription.address].shippingAddress,
            shippingAddressExtra: this.address[subscription.address].shippingAddressExtra,
            city: this.address[subscription.address].city,
            state: this.address[subscription.address].state,
            stateCode: this.address[subscription.address].stateCode,
            zip: this.address[subscription.address].zip,
            country: this.address[subscription.address].country,
            countryCode: this.address[subscription.address].countryCode,
            line: this.address[subscription.address].line,
            formattedLine: this.address[subscription.address].formattedLine,
            label: this.address[subscription.address].label,
        });
    };
    DashboardComponent.prototype.editSubscription = function () {
        var _this = this;
        return this.subscriptionService.editSubscription(this.subscriptionEditForm.value)
            .subscribe(function (subscriptions) {
            _this.showSubscriptionEditModal = false;
            _this.refreshList();
        });
    };
    DashboardComponent.prototype.editSubscriptionAddress = function () {
        var _this = this;
        var subscriptionId = this.subscriptionEditForm.get('subscriptionId').value;
        var subscription = this.subscriptions.find(function (subs) { return subs.id === subscriptionId; });
        var _a = this.subscriptionEditAddressForm.value, shippingAddress = _a.shippingAddress, shippingAddressExtra = _a.shippingAddressExtra, city = _a.city, stateCode = _a.stateCode, zip = _a.zip;
        this.subscriptionEditAddressForm.get('line').setValue(shippingAddress);
        this.subscriptionEditAddressForm.get('formattedLine').setValue(shippingAddress);
        this.subscriptionEditAddressForm.get('label').setValue(shippingAddress + " " + shippingAddressExtra + " " + city + " " + stateCode + " " + zip);
        return this.subscriptionService.updateSubscriptionAddress({
            subscription: subscription,
            address: this.subscriptionEditAddressForm.value
        })
            .subscribe(function (data) {
            _this.store.dispatch(new fromAddressActions.AddAddressCompleteAction(data.address));
            _this.showSubscriptionEditModal = false;
            _this.refreshList();
        });
    };
    DashboardComponent.prototype.onCustomerEditAction = function (customer) {
        this.showCustomerEditModal = true;
        this.customerEditForm.setValue({
            id: customer.id,
            firstName: customer.firstName,
            lastName: customer.lastName,
            email: customer.email
        });
    };
    DashboardComponent.prototype.editCustomer = function () {
        var _this = this;
        return this.customerService.editCustomer(this.customerEditForm.get('id').value, this.customerEditForm.value)
            .subscribe(function (customer) {
            _this.showCustomerEditModal = false;
            _this.refreshList();
        });
    };
    DashboardComponent.prototype.onResetUserPasswordAction = function (customer) {
        this.showResetPasswordModal = true;
        this.resetPasswordForm.setValue({
            customerId: customer.id,
            password: '',
        });
    };
    DashboardComponent.prototype.processResetPassword = function () {
        var _this = this;
        var _a = this.resetPasswordForm.getRawValue(), customerId = _a.customerId, password = _a.password;
        this.customerService.resetCustomerPassword(customerId, password).subscribe(function () {
            _this.showResetPasswordModal = false;
            _this.resetPasswordForm.reset();
        });
    };
    DashboardComponent.prototype.getSortedSubscriptions = function (subscriptions) {
        var activeSubscriptions = subscriptions
            .filter(function (subscription) { return subscription.status === 'ACTIVE'; })
            .sort(function (a, b) { return isBefore(a.createdAt, b.createdAt) ? 1 : -1; });
        var inactiveSubscriptions = subscriptions
            .filter(function (subscription) { return subscription.status !== 'ACTIVE'; })
            .sort(function (a, b) { return isBefore(a.createdAt, b.createdAt) ? 1 : -1; });
        return activeSubscriptions.concat(inactiveSubscriptions);
    };
    /**
     * NOTES STUFF
     */
    DashboardComponent.prototype.saveNewNote = function (subscription) {
        var customerId = subscription.customerId, id = subscription.id;
        var message = this.newNoteText.value;
        this.store.dispatch(new fromNotesActions.CreateNoteAction({
            userId: customerId,
            subscriptionId: id,
            message: message
        }));
        this.newNoteText.reset('');
    };
    DashboardComponent.prototype.deleteNote = function (note) {
        console.log(note);
    };
    DashboardComponent.prototype.filterNotesBySubscription = function (subscription) {
        var _this = this;
        return this.notesIds
            .map(function (id) { return _this.notes[id]; })
            .filter(function (note) { return note.subscription === subscription.id; })
            .reverse();
    };
    /**
     * CHANGE PREFERRED SHIPMENT RATE
     */
    DashboardComponent.prototype.onChangePreferredShipperAction = function () {
        var _this = this;
        var _a = this.preferredShipperForm.value, subscriptionId = _a.subscriptionId, selectedCarrier = _a.selectedCarrier, selectedService = _a.selectedService;
        this.subscriptionService.updatePreferredShipper({
            subscriptionId: subscriptionId,
            carrier: selectedCarrier,
            service: selectedService
        })
            .subscribe(function (data) {
            _this.refreshList();
            _this.showPreferredShipperModal = false;
        });
    };
    DashboardComponent.prototype.onOpenPreferredShipperAction = function (subscription) {
        var _this = this;
        this.showPreferredShipperModal = true;
        this.shipmentRatesRequested = true;
        this.preferredShipperForm.get('subscriptionId').setValue(subscription.id);
        console.log(subscription.preferredShipper);
        if (subscription.preferredShipper) {
            this.preferredShipperForm.get('selectedCarrier').setValue(subscription.preferredShipper.carrier);
            this.preferredShipperForm.get('selectedService').setValue(subscription.preferredShipper.service);
        }
        this.subscriptionService
            .fetchSubscriptionsRates(subscription.id)
            .pipe(tap(function (value) { return _this.shipmentRatesRequested = false; }))
            .subscribe(function (rates) {
            var normalizedRates = rates.map(function (rate) { return new ShipmentRate(rate); });
            _this.shipmentRates = _this.groupRateByCarrierName(normalizedRates);
        }, function (e) {
            _this.showPreferredShipperModal = false;
            _this.alertService.danger({
                alertsCode: 'dashboard-alerts',
                title: "Can't get the rates",
                message: e.error.message
            });
        });
    };
    DashboardComponent.prototype.onChangeSelectedCarrier = function (e) {
        var carrier = this.shipmentRates[e.target.value];
        var firstClass = Object.keys(carrier)[0];
        this.preferredShipperForm.get('selectedService').setValue(firstClass);
    };
    DashboardComponent.prototype.groupRateByCarrierName = function (rates) {
        return rates.reduce(function (prev, current) {
            var _a, _b;
            var carrierName = current.carrier;
            var carrierClass = current.service;
            var prevClass = prev[carrierName];
            return tslib_1.__assign({}, prev, (_a = {}, _a[carrierName] = tslib_1.__assign({}, prevClass, (_b = {}, _b[carrierClass] = current, _b)), _a));
        }, {});
    };
    /**
     * FREE SHIPMENT
     */
    DashboardComponent.prototype.configureCreateShipmentForm = function () {
        this.freeShipmentForm = new FormGroup({
            subscription: new FormControl('', Validators.required),
            customer: new FormControl('', Validators.required),
            address: new FormGroup({
                shippingAddress: new FormControl('', Validators.required),
                shippingAddressExtra: new FormControl(''),
                city: new FormControl('', Validators.required),
                state: new FormControl('', Validators.required),
                stateCode: new FormControl('', Validators.required),
                zip: new FormControl('', Validators.required),
                country: new FormControl('United States', Validators.required),
                countryCode: new FormControl('USA', Validators.required),
                line: new FormControl(''),
                formattedLine: new FormControl(''),
                label: new FormControl(''),
            })
        });
    };
    DashboardComponent.prototype.onFreeShipmentAction = function (customerId, subscriptionId, address) {
        this.showFreeShipmentModal = true;
        this.freeShipmentForm.reset();
        this.freeShipmentForm.get('subscription').setValue(subscriptionId);
        this.freeShipmentForm.get('customer').setValue(customerId);
        if (typeof address !== 'undefined') {
            this.freeShipmentForm.get('address').get('shippingAddress').setValue(address.shippingAddress);
            this.freeShipmentForm.get('address').get('shippingAddressExtra').setValue(address.shippingAddressExtra);
            this.freeShipmentForm.get('address').get('city').setValue(address.city);
            this.freeShipmentForm.get('address').get('state').setValue(address.state);
            this.freeShipmentForm.get('address').get('stateCode').setValue(address.stateCode);
            this.freeShipmentForm.get('address').get('zip').setValue(address.zip);
        }
        this.freeShipmentForm.get('address').get('country').setValue('United States');
        this.freeShipmentForm.get('address').get('countryCode').setValue('USA');
    };
    DashboardComponent.prototype.createFreeShipment = function () {
        var _this = this;
        var _a = this.freeShipmentForm.get('address').value, shippingAddress = _a.shippingAddress, shippingAddressExtra = _a.shippingAddressExtra, city = _a.city, stateCode = _a.stateCode, zip = _a.zip;
        this.freeShipmentForm.get('address').get('line').setValue(shippingAddress);
        this.freeShipmentForm.get('address').get('formattedLine').setValue(shippingAddress);
        this.freeShipmentForm.get('address').get('label')
            .setValue(shippingAddress + " " + shippingAddressExtra + " " + city + " " + stateCode + " " + zip);
        this.shipmentsService.createFreeShipment(this.freeShipmentForm.value)
            .pipe(tap(function () { return _this.showFreeShipmentModal = false; }))
            .subscribe(function (shipment) {
            _this.shipments = [
                shipment
            ].concat(_this.shipments);
        }, function (e) { return console.log(e); });
    };
    /**
     * AUTO RENEW STUFF
     */
    DashboardComponent.prototype.onTurnOffAction = function (subscriptionId, userId) {
        this.turnOffForm.get('subscriptionId').setValue(subscriptionId);
        this.turnOffForm.get('userId').setValue(userId);
        this.showTurnOffModal = true;
    };
    DashboardComponent.prototype.onTurnAutoRenewOff = function () {
        var _this = this;
        var subscriptionId = this.turnOffForm.get('subscriptionId').value;
        var userId = this.turnOffForm.get('userId').value;
        var reasons = this.turnOffForm.get('reasons').value.filter(function (reason) { return reason.enabled; });
        this.updateLoading('subscriptions', subscriptionId, true);
        this.subscriptionService.switchAutoRenew({
            autoRenew: false,
            nextBillingDate: '',
            subscriptionId: subscriptionId,
            userId: userId,
            reasons: reasons
        })
            .subscribe(function (subscription) {
            _this.refreshList();
            _this.actionService.fetchActionsByUserId(subscriptionId)
                .subscribe(function (actions) {
                _this.actions = actions;
                _this.updateLoading('subscriptions', subscriptionId, false);
                _this.showTurnOffModal = false;
            });
        });
    };
    DashboardComponent.prototype.onTurnAutoRenewOn = function () {
        var _this = this;
        var _a = this.autoRenewOnForm.value, subscriptionId = _a.subscriptionId, userId = _a.userId, nextBillingDate = _a.nextBillingDate;
        if (this.autoRenewOnForm.valid) {
            this.updateLoading('subscriptions', subscriptionId, true);
            this.subscriptionService.switchAutoRenew({
                userId: userId,
                subscriptionId: subscriptionId,
                autoRenew: true,
                nextBillingDate: format(new Date(nextBillingDate), 'yyyy-MM-dd'),
            })
                .subscribe(function (subscription) {
                _this.actionService.fetchActionsByUserId(subscriptionId)
                    .subscribe(function (actions) {
                    _this.actions = actions;
                    _this.refreshList();
                    _this.showAutoRenewOnModal = false;
                    _this.updateLoading('subscriptions', subscriptionId, false);
                });
            });
        }
    };
    DashboardComponent.prototype.onShowAutoRenewOnModal = function (subscriptionId) {
        var subscription = this.subscriptions.find(function (item) { return item.id === subscriptionId; });
        this.showAutoRenewOnModal = true;
        this.autoRenewOnForm.setValue({
            subscriptionId: subscriptionId,
            userId: subscription.customerId,
            nextBillingDate: format(new Date(), 'MM-dd-yyyy'),
        });
    };
    DashboardComponent.prototype.clearDeclineStatus = function (subscription) {
        var _this = this;
        if (!subscription) {
            throw new Error('Should provide a subscription as parameter');
        }
        this.subscriptionService
            .clearDeclineStatus(subscription.id)
            .subscribe(function (data) {
            console.log('SUCESS: ', data);
            _this.refreshList();
        }, function (error) {
            console.log('ERROR: ', error);
        });
    };
    DashboardComponent.prototype.onOpenPaymentMethodsModal = function (customer) {
        this.selectedCustomer = customer;
        this.showPaymentMethosdModal = true;
    };
    DashboardComponent.prototype.onClosePaymentMethodsModal = function () {
        this.showPaymentMethosdModal = false;
        this.selectedCustomer = null;
        this.refreshList();
    };
    // utils
    DashboardComponent.prototype.parseCancelledReasons = function (reasons) {
        return reasons
            .map(function (r) { return "" + r.name + (r.value ? ": " + r.value : ''); });
    };
    DashboardComponent.prototype.getNumberOfFreeBoxes = function (customerId) {
        var customer = this.customers.find(function (item) { return item.id === customerId; });
        if (!customer) {
            return 0;
        }
        var freeBoxes = 0;
        var referralsToBeRedeemed = {};
        this.referralPromotions.forEach(function (promotion) {
            var unredeemedReferrals = customer.referrals.filter(function (referral) { return !referral.redeemed; });
            unredeemedReferrals = unredeemedReferrals.filter(function (r) {
                return !referralsToBeRedeemed[r.id];
            });
            if (!promotion.default) {
                unredeemedReferrals = unredeemedReferrals
                    .filter(function (r) { return r.referralPromotion === promotion._id; });
            }
            unredeemedReferrals = unredeemedReferrals
                .filter(function (r, i) { return (i < promotion.requiredReferrals) ? true : false; });
            if (unredeemedReferrals.length === promotion.requiredReferrals) {
                freeBoxes = freeBoxes + promotion.freeBoxes;
                referralsToBeRedeemed = tslib_1.__assign({}, referralsToBeRedeemed, unredeemedReferrals.reduce(function (prev, current) {
                    var _a;
                    return (tslib_1.__assign({}, prev, (_a = {}, _a[current.id] = current, _a)));
                }, {}));
                return;
            }
        });
        return freeBoxes;
    };
    return DashboardComponent;
}());
export { DashboardComponent };
