import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  FormControl,
  FormGroup
} from '@angular/forms';

import {
  Observable,
  Subscription
} from 'rxjs';
import { filter } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import * as fromAdminActions from './../store/actions/admin.actions';
import * as fromAdmin from './../store/selectors/admin.selectors';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  isAuthenticated$: Observable<boolean>;
  isAuthenticated: Subscription;

  loginForm: FormGroup;
  showPassword = false;
  notAuth = false;

  constructor(
    private store: Store<any>,
    private el: ElementRef,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.isAuthenticated$ = this.store.select(fromAdmin.getUserIsAuthenticated);
    this.el.nativeElement.classList.add('container-fluid');
    this.createForm();

    this.isAuthenticated = this.isAuthenticated$
      .pipe(filter(Boolean))
      .subscribe(isAuthenticated => {
        localStorage.setItem('ssops:v0.0.1:li', 'yes');
        const { redirectUrl } = this.route.snapshot.queryParams;
        this.router.navigateByUrl(redirectUrl ? redirectUrl : '/', { queryParamsHandling: 'merge' });
      });
  }

  ngOnDestroy() {
    this.isAuthenticated.unsubscribe();
  }

  createForm() {
    this.loginForm = new FormGroup({
      username: new FormControl(''),
      password: new FormControl('')
    });
  }

  onSubmit() {
    const user = this.loginForm.get('username').value;
    const pw = this.loginForm.get('password').value;
    this.store.dispatch(new fromAdminActions.LoginAction({
      email: user,
      password: pw
    }));
  }
}
