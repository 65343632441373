import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@env/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BillingService = /** @class */ (function () {
    function BillingService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl + '/api';
    }
    BillingService.prototype.processFutureSubscriptions = function (total) {
        var params = new HttpParams().set('amount', total.toString());
        return this.http.get(this.apiUrl.concat('/admin/billings/next-subscriptions'), { params: params });
    };
    BillingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BillingService_Factory() { return new BillingService(i0.ɵɵinject(i1.HttpClient)); }, token: BillingService, providedIn: "root" });
    return BillingService;
}());
export { BillingService };
