import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { map, delay, switchMap, take } from 'rxjs/operators';
// Models
import { Subscription } from '../models/subscription.model';
import { Address } from '../models/address.model';
// Services
import { ShipmentService } from './shipment.service';
import { Shipment } from '@app/models/shipment.model';
var SubscriptionService = /** @class */ (function () {
    function SubscriptionService(http, shipmentsService) {
        this.http = http;
        this.shipmentsService = shipmentsService;
        this.apiUrl = environment.apiUrl + '/api';
        this.subscriptionsState = [];
        this.subscriptionsSubject = new BehaviorSubject([]);
    }
    // TODO: change the name of the getSubscriptions to getSubscriptionState
    SubscriptionService.prototype.getSubscriptions = function () {
        return this.subscriptionsSubject;
    };
    SubscriptionService.prototype.updateSubscriptionsState = function (data) {
        this.subscriptions = data;
    };
    Object.defineProperty(SubscriptionService.prototype, "subscriptions", {
        get: function () {
            return this.subscriptionsState.slice();
        },
        set: function (data) {
            this.subscriptionsState = data;
            this.subscriptionsSubject.next(data);
        },
        enumerable: true,
        configurable: true
    });
    SubscriptionService.prototype.cancelSubscription = function (id, reasons) {
        var _this = this;
        var httpParams = new HttpParams();
        httpParams = httpParams.append('admin', '5a680f4a4e8ac8bab59f2144');
        return this.http.request('delete', this.apiUrl + "/admin/subscriptions/" + id, { body: { reasons: reasons }, params: httpParams })
            .pipe(map(function (response) {
            var updatedSubscription = new Subscription(response.subscription);
            _this.subscriptions = _this.mergeSubscriptions(_this.subscriptions, [updatedSubscription]);
            return updatedSubscription;
        }));
    };
    SubscriptionService.prototype.skipSubscription = function (subscriptionId) {
        var _this = this;
        var httpParams = new HttpParams();
        httpParams = httpParams.append('admin', '5a680f4a4e8ac8bab59f2144');
        return this.http.request('post', this.apiUrl + "/admin/subscriptions/skipMonth", {
            body: { subscriptionId: subscriptionId, totalMonths: 1 },
            params: httpParams
        })
            .pipe(map(function (response) {
            var updatedSubscription = new Subscription(response.subscription);
            _this.subscriptions = _this.mergeSubscriptions(_this.subscriptions, [updatedSubscription]);
            return updatedSubscription;
        }));
    };
    SubscriptionService.prototype.editSubscription = function (data) {
        var _this = this;
        return this.http.request('put', this.apiUrl + "/admin/subscriptions/" + data.subscriptionId, {
            body: data
        }).pipe(map(function (response) {
            var updatedSubscription = new Subscription(response.data);
            _this.subscriptions = _this.mergeSubscriptions(_this.subscriptions, [updatedSubscription]);
            return updatedSubscription;
        }));
    };
    SubscriptionService.prototype.fetchSubscriptionsRates = function (subscriptionId) {
        return this.http.get(this.apiUrl + "/admin/subscriptions/" + subscriptionId + "/rates")
            .pipe(map(function (response) { return response.data; }));
    };
    SubscriptionService.prototype.updateSubscriptionAddress = function (_a) {
        var _this = this;
        var subscription = _a.subscription, address = _a.address;
        var subscriptionObj = tslib_1.__assign({}, subscription, { _id: subscription.id });
        return this.http
            .put(this.apiUrl + "/admin/subscriptions/" + subscription.id + "/address", { subscription: subscriptionObj, address: address })
            .pipe(map(function (data) {
            var updatedSubscription = new Subscription(data.subscription);
            var updatedAddress = new Address(data.address);
            _this.subscriptions = _this.mergeSubscriptions(_this.subscriptions, [updatedSubscription]);
            return {
                subscription: updatedSubscription,
                address: updatedAddress,
            };
        }));
    };
    SubscriptionService.prototype.switchAutoRenew = function (data) {
        var _this = this;
        return this.http
            .put(this.apiUrl + "/users/" + data.userId + "/subscriptions/" + data.subscriptionId + "/" + (data.autoRenew ? 'turn-on' : 'turn-off'), {
            nextBillingDate: data.nextBillingDate,
            turnedOffReasons: data.reasons
        })
            .pipe(delay(1000), map(function (results) {
            var updatedSubscription = new Subscription(results.subscription);
            _this.subscriptions = _this.mergeSubscriptions(_this.subscriptions, [updatedSubscription]);
            return updatedSubscription;
        }));
    };
    SubscriptionService.prototype.processSubscriptionBilling = function (data) {
        var _this = this;
        return this.http
            .post(this.apiUrl + "/users/" + data.customerId + "/subscriptions/" + data.subscriptionId + "/process-subscription-billing", {
            options: data,
            origin: 'ops'
        })
            .pipe(delay(1000), switchMap(function () { return _this.shipmentsService.getShipmentsState().pipe(take(1)); }, function (result, shipments) { return [result, shipments]; }), map(function (_a) {
            var result = _a[0], shipments = _a[1];
            var updatedSubscription = new Subscription(result.subscription);
            var updatedShipments = result.shipments.map(function (item) { return new Shipment(item); });
            _this.shipmentsService.updateShipmentsState(_this.shipmentsService.mergeShipments(shipments, updatedShipments));
            _this.subscriptions = _this.mergeSubscriptions(_this.subscriptions, [updatedSubscription]);
            return updatedSubscription;
        }));
    };
    SubscriptionService.prototype.updatePreferredShipper = function (_a) {
        var _this = this;
        var subscriptionId = _a.subscriptionId, data = tslib_1.__rest(_a, ["subscriptionId"]);
        return this.http
            .post(this.apiUrl + "/admin/subscriptions/" + subscriptionId + "/change-preferred-shipper", tslib_1.__assign({}, data))
            .pipe(delay(1000), map(function (result) {
            var updatedSubscription = new Subscription(result.subscription);
            _this.subscriptions = _this.mergeSubscriptions(_this.subscriptions, [updatedSubscription]);
            return updatedSubscription;
        }));
    };
    SubscriptionService.prototype.clearDeclineStatus = function (subscriptionId) {
        var _this = this;
        return this.http
            .put(this.apiUrl + "/admin/subscriptions/" + subscriptionId + "/clear-decline-status", {})
            .pipe(map(function (result) {
            var updatedSubscription = new Subscription(result.data.subscription);
            _this.subscriptions = _this.mergeSubscriptions(_this.subscriptions, [updatedSubscription]);
            return updatedSubscription;
        }));
    };
    // UTILS
    SubscriptionService.prototype.mergeSubscriptions = function (oldSubscriptions, newSubscriptions) {
        if (oldSubscriptions.length === 0) {
            return newSubscriptions;
        }
        return newSubscriptions.reduce(function (allSubscription, newSubscription) {
            var temp = 'length' in allSubscription ? allSubscription : [];
            var subscriptionIndex = temp.findIndex(function (subscription) { return subscription.id === newSubscription.id; });
            if (subscriptionIndex === -1) {
                return temp.concat([newSubscription]);
            }
            else {
                return temp.slice(0, subscriptionIndex).concat([
                    newSubscription
                ], temp.slice(subscriptionIndex + 1));
            }
        }, oldSubscriptions);
    };
    return SubscriptionService;
}());
export { SubscriptionService };
