import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { Coupon, GGCoupon } from '@app/models/coupon.model';
var CouponsService = /** @class */ (function () {
    function CouponsService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl + '/api';
    }
    CouponsService.prototype.getGGCouponsList = function (filter) {
        if (filter === void 0) { filter = { code: '', $status: '', $skip: '0', $limit: '25' }; }
        var params = new HttpParams({ fromObject: tslib_1.__assign({}, filter) });
        return this.http.get(this.apiUrl.concat('/admin/ggcoupons'), { params: params })
            .pipe(map(function (data) {
            return {
                coupons: data.coupons.map(function (c) { return new GGCoupon(c); }),
                pagination: data.pagination,
            };
        }));
    };
    CouponsService.prototype.getCouponsList = function (filter) {
        var params = new HttpParams({ fromObject: { code: filter } });
        return this.http.get(this.apiUrl.concat('/admin/coupons'), { params: params })
            .pipe(map(function (data) {
            return data.coupons.map(function (c) { return new Coupon(c); });
        }));
    };
    CouponsService.prototype.createCoupon = function (coupon) {
        return this.http.post(this.apiUrl.concat('/admin/coupons'), { coupon: coupon })
            .pipe(map(function (data) { return new Coupon(data); }));
    };
    CouponsService.prototype.editCoupon = function (id, coupon) {
        return this.http.put(this.apiUrl.concat("/admin/coupons/" + id), { coupon: coupon })
            .pipe(map(function (data) { return new Coupon(data); }));
    };
    CouponsService.prototype.enableCoupon = function (id) {
        return this.http.put(this.apiUrl.concat("/admin/coupons/" + id + "/enable"), {})
            .pipe(map(function (data) { return new Coupon(data); }));
    };
    CouponsService.prototype.disableCoupon = function (id) {
        return this.http.delete(this.apiUrl.concat("/admin/coupons/" + id + "/disable"))
            .pipe(map(function (data) { return new Coupon(data); }));
    };
    return CouponsService;
}());
export { CouponsService };
