import { environment } from "@env/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Box } from "@app/models/box.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BoxesService = /** @class */ (function () {
    function BoxesService(httpClient) {
        this.httpClient = httpClient;
        this.apiUrl = environment.apiUrl + '/api';
    }
    BoxesService.prototype.getDefaultBoxes = function () {
        return this.httpClient
            .get(this.apiUrl + "/boxes/type/default")
            .pipe(map(function (result) { return result.boxes.map(function (box) { return new Box(box); }); }));
    };
    BoxesService.prototype.setDefaultBox = function (subscriptionId, boxId) {
        return this.httpClient
            .put(this.apiUrl + "/users/subscriptions/" + subscriptionId + "/set-default-box", {
            boxId: boxId
        });
    };
    BoxesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BoxesService_Factory() { return new BoxesService(i0.ɵɵinject(i1.HttpClient)); }, token: BoxesService, providedIn: "root" });
    return BoxesService;
}());
export { BoxesService };
