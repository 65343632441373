import * as tslib_1 from "tslib";
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as notesActions from './../actions/notes.actions';
// services
import { NotesService } from '@app/services';
import { ofType } from '@ngrx/effects';
var NotesEffects = /** @class */ (function () {
    function NotesEffects(notesService, actions$, store) {
        var _this = this;
        this.notesService = notesService;
        this.actions$ = actions$;
        this.store = store;
        this.loadSubscriptionNotes$ = this.actions$
            .pipe(ofType(notesActions.LOAD_SUBSCRIPTION_NOTES))
            .pipe(map(function (action) { return action.payload; }), switchMap(function (_a) {
            var userId = _a.userId, subscriptionId = _a.subscriptionId;
            return _this.notesService
                .getSubscriptionNotes({ userId: userId, subscriptionId: subscriptionId })
                .pipe(map(function (notes) { return _this.store.dispatch(new notesActions.LoadSubscriptionNotesCompleteAction(notes)); }), catchError(function (reason) {
                _this.store.dispatch(new notesActions.LoadSubscriptionNoteFailAction(reason.errors));
                return of(new notesActions.LoadSubscriptionNoteFailAction(reason.errors));
            }));
        }));
        this.createNote$ = this.actions$
            .pipe(ofType(notesActions.CREATE_NOTE))
            .pipe(map(function (action) { return action.payload; }), switchMap(function (_a) {
            var userId = _a.userId, subscriptionId = _a.subscriptionId, message = _a.message;
            return _this.notesService
                .createNote({ userId: userId, subscriptionId: subscriptionId, message: message })
                .pipe(map(function (note) { return _this.store.dispatch(new notesActions.CreateNoteCompleteAction(note)); }), catchError(function (reason) {
                _this.store.dispatch(new notesActions.CreateNoteFailAction(reason.errors));
                return of(new notesActions.CreateNoteFailAction(reason.errors));
            }));
        }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], NotesEffects.prototype, "loadSubscriptionNotes$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], NotesEffects.prototype, "createNote$", void 0);
    return NotesEffects;
}());
export { NotesEffects };
