import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
var DashboardService = /** @class */ (function () {
    function DashboardService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl + '/api';
    }
    DashboardService.prototype.fetchDataForDashboard = function (searchObject) {
        var params = new HttpParams({
            fromObject: tslib_1.__assign({}, searchObject, { searchValue: encodeURIComponent(searchObject.searchValue) })
        });
        return this.http.get(this.apiUrl + "/admin/dashboard/search/", { params: params });
    };
    DashboardService.prototype.fetchAutoComplete = function (value) {
        var params = new HttpParams()
            .append('searchValue', encodeURIComponent(value));
        return this.http.get(this.apiUrl + "/admin/dashboard/search-autocomplete/", { params: params })
            .pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }));
    };
    return DashboardService;
}());
export { DashboardService };
