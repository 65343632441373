import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams
} from '@angular/common/http';

import { environment } from '@env/environment';

import {
  Observable,
  BehaviorSubject
} from 'rxjs';
import { map } from 'rxjs/operators';

// Models
import { Customer } from '@app/models/customer.model';

@Injectable()
export class CustomerService {
  private apiUrl = environment.apiUrl + '/api';
  private customersState: Customer[] = [];
  private customersState$: BehaviorSubject<Customer[]> = new BehaviorSubject([]);

  constructor(private http: HttpClient) {
  }

  public getCustomersState(): Observable<Customer[]> {
    console.log('CustomerService::getCustomersState');
    return this.customersState$;
  }

  public updateCustomersState(data: Customer[]) {
    console.log('CustomerService::updateCustomersState');
    this.customers = data;
  }

  private set customers(data: Customer[]) {
    this.customersState = data;
    this.customersState$.next(data);
  }

  private get customers(): Customer[] {
    return this.customersState.slice();
  }

  public fetchCustomersByIdsFromApi(params: FetchCustomersParams): Observable<Customer[]> {
    console.log('CustomerService::fetchCustomersByIdsFromApi');
    let httpParams = new HttpParams();
    if (typeof params.limit === 'number') {
      httpParams = httpParams.append('limit', params.limit.toString());
    }
    if (typeof params.page === 'number') {
      httpParams = httpParams.append('page', params.page.toString());
    }
    return this.http.post(`${this.apiUrl}/admin/customers/by-ids`, { ids: params.ids }, { params: httpParams })
      .pipe(
        map((response: any) => {
          const newCustomers = response.data.map(customer => new Customer(customer));
          this.customers = this.mergeCustomers(this.customers, newCustomers);
          return newCustomers;
        })
      )
  }

  public fetchCustomerByEmail(customerEmail: string) {
    console.log('CustomerService::fetchCustomerByEmail');
    return this.http.post(`${this.apiUrl}/admin/customers/by-email`, { email: customerEmail })
      .pipe(
        map((response: any) => {
          const newCustomers = response.data.map(customer => new Customer(customer));
          this.customers = this.mergeCustomers(this.customers, newCustomers);
          return newCustomers;
        })
      );
  }

  public fetchCustomersByNameAndEmailFromApi(text, page = 0, limit = 25): Observable<Customer[]> {
    console.log('CustomerService::fetchCustomersBynameAnAddressFromApi');
    let httpParams = new HttpParams();
    if (typeof limit === 'number') {
      httpParams = httpParams.append('limit', limit.toString());
    }
    if (typeof page === 'number') {
      httpParams = httpParams.append('page', page.toString());
    }
    return this.http.post(`${this.apiUrl}/admin/customers/by-name-and-email`, { text }, { params: httpParams })
      .pipe(
        map((response: any) => {
          const newCustomers = response.data.map(customer => new Customer(customer));
          this.customers = this.mergeCustomers(this.customers, newCustomers);
          return newCustomers;
        })
      );
  }

  public editCustomer(id: string, data: any): Observable<Customer> {
    return this.http.request('put', `${this.apiUrl}/admin/customers/${data.id}`, {
      body: data
    })
      .pipe(
        map((response: any) => {
          const updatedSubscription = new Customer(response.data);
          this.customers = this.mergeCustomers(this.customers, [updatedSubscription]);
          return updatedSubscription;
        })
      );
  }

  public resetCustomerPassword(id: string, password): Observable<string> {
    return this.http.put(`${this.apiUrl}/admin/customers/${id}/reset-password`, { password })
      .pipe(
        map((response: any) => {
          return response.message;
        })
      );
  }

  addPaymentMethod({
                     customerId,
                     nonce,
                     cardholderName,
                   }): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/users/${customerId}/add-payment-method`, {
        nonce,
        cardholderName,
      })
      .pipe(
        map((response: any) => {
          const oldCustomer = this.customers.find(customer => customer.id === customerId);
          const newCustomer = new Customer({
            ...oldCustomer,
            _id: oldCustomer.id,
            paymentMethods: response.paymentMethods
          });
          this.customers = this.mergeCustomers(this.customers, [newCustomer]);
          return newCustomer;
        })
      );
  }

  updatePaymentMethod({
                        customerId,
                        paymentMethodId,
                        nonce,
                        cardholderName,
                        paymentProcessor = 'braintree'
                      }): Observable<any> {
    return this.http
      .put(`${this.apiUrl}/users/${customerId}/update-payment-method`, {
        paymentMethodId,
        nonce,
        cardholderName,
        paymentProcessor
      })
      .pipe(
        map((response: any) => {
          const oldCustomer = this.customers.find(customer => customer.id === customerId);
          const newCustomer = new Customer({
            ...oldCustomer,
            _id: oldCustomer.id,
            paymentMethods: [response.paymentMethod]
          });
          this.customers = this.mergeCustomers(this.customers, [newCustomer]);
          return newCustomer;
        })
      );
  }

  deletePaymentMethod({
                        customerId,
                        paymentMethodId,
                      }): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/users/${customerId}/payment-method/${paymentMethodId}`)
      .pipe(
        map((response: any) => {
          const oldCustomer = this.customers.find(customer => customer.id === customerId);
          const newCustomer = new Customer({
            ...oldCustomer,
            _id: oldCustomer.id,
            paymentMethods: response.paymentMethods
          });
          this.customers = this.mergeCustomers(this.customers, [newCustomer]);
          return newCustomer;
        })
      );
  }


  defaultPaymentMethod({
                         customerId,
                         paymentMethodId,
                       }): Observable<any> {
    return this.http
      .put(`${this.apiUrl}/users/${customerId}/payment-method/${paymentMethodId}/default`, {})
      .pipe(
        map((response: any) => {
          const oldCustomer = this.customers.find(customer => customer.id === customerId);
          const newCustomer = new Customer({
            ...oldCustomer,
            _id: oldCustomer.id,
            paymentMethods: response.paymentMethods
          });
          this.customers = this.mergeCustomers(this.customers, [newCustomer]);
          return newCustomer;
        })
      );
  }

  // UTILS
  private mergeCustomers(oldCustomers: Customer[], newCustomers: Customer[]) {
    if (oldCustomers.length === 0) {
      return newCustomers;
    }
    return newCustomers.reduce((allCustomers, newCustomer) => {
      const temp = 'length' in allCustomers ? allCustomers : [];
      const customerIndex = temp.findIndex((customer: Customer) => customer.id === newCustomer.id);
      if (customerIndex === -1) {
        return [...temp, newCustomer];
      } else {
        return [
          ...temp.slice(0, customerIndex),
          newCustomer,
          ...temp.slice(customerIndex + 1)
        ];
      }
    }, oldCustomers);
  }
}

export interface FetchCustomersParams {
  ids: string[];
  limit: number | undefined;
  page: number | undefined;
}
