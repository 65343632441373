import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as addressActions from '@app/store/actions/address.actions';
// models
// services
import { AddressService } from '@app/services';
var AddressEffects = /** @class */ (function () {
    function AddressEffects(addressService, actions$, store) {
        var _this = this;
        this.addressService = addressService;
        this.actions$ = actions$;
        this.store = store;
        this.loadCustomerAddresses$ = this.actions$
            .pipe(ofType(addressActions.LOAD_CUSTOMER_ADDRESSES))
            .pipe(map(function (action) { return action.payload; }), switchMap(function (data) {
            return _this.addressService
                .getCustomerAddresses({ user: data.id })
                .pipe(map(function (addresses) { return _this.store.dispatch(new addressActions.LoadCustomerAddressesCompleteAction(addresses)); }), catchError(function (reason) {
                _this.store.dispatch(new addressActions.LoadCustomerAddressesFailAction(reason.errors));
                return of(new addressActions.LoadCustomerAddressesFailAction(reason.errors));
            }));
        }));
        this.loadByCustomerIds$ = this.actions$
            .pipe(ofType(addressActions.LOAD_CUSTOMERS_ADDRESSES))
            .pipe(map(function (action) { return action.payload; }), switchMap(function (_a) {
            var ids = _a.ids;
            return _this.addressService
                .getAddressesByUserIds({ ids: ids })
                .pipe(map(function (addresses) { return _this.store.dispatch(new addressActions.LoadCustomersAddressesCompleteAction(addresses)); }), catchError(function (reason) {
                _this.store.dispatch(new addressActions.LoadCustomersAddressesFailAction(reason.errors));
                return of(new addressActions.LoadCustomersAddressesFailAction(reason.errors));
            }));
        }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AddressEffects.prototype, "loadCustomerAddresses$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AddressEffects.prototype, "loadByCustomerIds$", void 0);
    return AddressEffects;
}());
export { AddressEffects };
