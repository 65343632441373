import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard.component';
import { RetentionComponent } from './pages/retention/retention.component';
import { AuthGuard } from '@app/guards/auth/auth.guard';

const routes: Route[] = [
  {
    component: DashboardComponent, path: '', pathMatch: 'full', canActivate: [AuthGuard],
  },
  {
    path: 'stats',
    children: [
      {
        path: 'retention', component: RetentionComponent, canActivate: [AuthGuard]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {}
