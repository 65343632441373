import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { BehaviorSubject, Subject } from "rxjs";
import { BillingService } from "@app/services/billing.service";
import { filter, takeUntil, withLatestFrom } from "rxjs/operators";
var ProcessSubscriptionsComponent = /** @class */ (function () {
    function ProcessSubscriptionsComponent(fb, billingService) {
        this.fb = fb;
        this.billingService = billingService;
        this.active$ = new BehaviorSubject(false);
        this.loading$ = new BehaviorSubject(false);
        this.onSubmit$ = new Subject();
        this.componentDestroyed$ = new Subject();
        this.success = new EventEmitter();
        this.form = this.fb.group({
            totalSubscriptions: [100, Validators.required]
        });
    }
    Object.defineProperty(ProcessSubscriptionsComponent.prototype, "active", {
        set: function (value) {
            this.active$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    ProcessSubscriptionsComponent.prototype.ngOnInit = function () {
        this.onSubmit$
            .pipe(withLatestFrom(this.loading$.asObservable()))
            .pipe(filter(function (_a) {
            var _ = _a[0], loading = _a[1];
            return loading === false;
        }))
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(this.onSubmit.bind(this));
    };
    ProcessSubscriptionsComponent.prototype.ngOnDestroy = function () {
        this.componentDestroyed$.next();
    };
    ProcessSubscriptionsComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!!this.form.valid) {
            this.loading$.next(true);
            this.billingService.processFutureSubscriptions(this.form.get('totalSubscriptions').value)
                .subscribe(function (response) {
                if (response && response.total === 0) {
                    alert("We don't have more subscriptions to process today.");
                }
                _this.success.next(true);
                _this.active$.next(false);
                _this.loading$.next(false);
            }, function (response) {
                _this.loading$.next(false);
                try {
                    alert(JSON.stringify(response.error));
                }
                catch (e) {
                    alert(response.error || 'We got an unexpected error');
                }
            });
        }
    };
    return ProcessSubscriptionsComponent;
}());
export { ProcessSubscriptionsComponent };
