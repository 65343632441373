import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@app/services';
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(router, fb, authService, route) {
        this.router = router;
        this.fb = fb;
        this.authService = authService;
        this.route = route;
        this.showPassword = false;
        this.showConfirmPassword = false;
        this.displayErrors = false;
        this.passwordChangedMessage = false;
        this.form = this.fb.group({
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
        });
    }
    Object.defineProperty(ResetPasswordComponent.prototype, "arePasswordsEquals", {
        get: function () {
            var password = this.form.get('password').value;
            var confirmPassword = this.form.get('confirmPassword').value;
            return password === confirmPassword;
        },
        enumerable: true,
        configurable: true
    });
    ResetPasswordComponent.prototype.ngOnInit = function () {
        this.token = this.route.snapshot.queryParams.recoveryToken;
    };
    ResetPasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.form.invalid || !this.arePasswordsEquals) {
            this.displayErrors = true;
            return;
        }
        var _a = this.form.getRawValue(), password = _a.password, confirmPassword = _a.confirmPassword;
        this.authService.recoveryPassword(this.token, password, confirmPassword)
            .subscribe(function (response) {
            _this.passwordChangedMessage = response;
            // this.router.navigate(['/login']);
        }, function (err) {
            console.log(err);
            _this.error = err.error.message;
        });
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
