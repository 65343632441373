<ng-container *ngIf="boxSelectedForm">
  <!-- Change preferred shipper modal -->
  <app-modal title="Change selected box"
             [show]="showModal$ | async"
             (atCloseBtn)="closed.emit()"
             secondaryBtn="Cancel"
             (atSecondaryBtn)="closed.emit()"
             primaryBtn="Change selected box"
             [disablePrimaryBtn]="!boxSelectedForm.valid || !(boxesRequested$ | async)"
             (atPrimaryBtn)="onBoxSelected()">

    <form novalidate [formGroup]="boxSelectedForm">

      <div class="form-group">
        <label for="subscriptionId">Subscription id</label>
        <input style="pointer-events: none;" id="subscriptionId" class="form-control" type="text"
               formControlName="subscriptionId" placeholder="Subscription ID" [disabled]="true">
      </div>

      <div *ngIf="boxesRequested$ | async; else boxesLoading" class="form-group">
        <div class="form-group">
          <label>Select Box:</label>
          <select formControlName="selectedBox" class="form-control">
            <option *ngFor="let box of (boxes$ | async)" [value]="box.id"
                    [selected]="boxSelectedForm.get('selectedBox').value === box.id">
              {{ box.name }}
            </option>
          </select>
        </div>
      </div>

      <ng-template #boxesLoading>
        <p>Loading...</p>
      </ng-template>
    </form>

  </app-modal>
</ng-container>


