import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
// Models
import { Action } from '@app/models/action.model';
var ActionService = /** @class */ (function () {
    function ActionService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl + '/api';
        this.actionsState = [];
        this.actionsState$ = new BehaviorSubject([]);
    }
    ActionService.prototype.getActionsState = function () {
        return this.actionsState$;
    };
    ActionService.prototype.updateActionsState = function (data) {
        this.actions = data;
    };
    Object.defineProperty(ActionService.prototype, "actions", {
        get: function () {
            return this.actionsState.slice();
        },
        set: function (data) {
            this.actionsState = data;
            this.actionsState$.next(data);
        },
        enumerable: true,
        configurable: true
    });
    ActionService.prototype.fetchActionsByUserId = function (subscriptionId) {
        var _this = this;
        return this.http.get(this.apiUrl + "/admin/subscriptions/" + subscriptionId + "/actions")
            .pipe(map(function (response) {
            var newState = _this.mergeState(_this.actions, response.data.map(function (action) { return new Action(action); }));
            _this.updateActionsState(newState);
            return newState;
        }));
    };
    // UTILS
    ActionService.prototype.mergeState = function (oldState, newState) {
        if (oldState.length === 0) {
            return newState;
        }
        return newState.reduce(function (all, current) {
            var temp = 'length' in all ? all : [];
            var index = temp.findIndex(function (t) { return t.id === current.id; });
            if (index === -1) {
                return temp.concat([current]);
            }
            else {
                return temp.slice(0, index).concat([
                    current
                ], temp.slice(index + 1));
            }
        }, oldState);
    };
    return ActionService;
}());
export { ActionService };
