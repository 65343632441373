import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as ReshipData from '@config/reship.json';
import { environment } from '@env/environment';
// rxjs
import { mergeMap, take, tap } from 'rxjs/operators';
// services
import { AddressService, ShipmentService, SubscriptionService } from '@app/services';
import { AlertService } from '@app/shared/components/alerts/alert.service';
// models
import { ShipmentRate } from '@app/models/shipment.model';
import { Address } from '@app/models/address.model';
import * as moment from 'moment';
import * as fromAddressActions from '@app/store/actions/address.actions';
import { Store } from '@ngrx/store';
var CustomerShipmentsComponent = /** @class */ (function () {
    function CustomerShipmentsComponent(shipmentService, subscriptionService, alertService, addressService, store) {
        this.shipmentService = shipmentService;
        this.subscriptionService = subscriptionService;
        this.alertService = alertService;
        this.addressService = addressService;
        this.store = store;
        this.reshipData = ReshipData.default;
        this.serverUrl = environment.apiUrl;
        this.giftedShipments = [];
        this.showBuyShipmentModal = false;
        this.shipmentRatesRequested = false;
        this.showReshipReasonForm = false;
        this.loadingShipments = [];
        this.showChangePrintingDateModal = false;
        this.showChangeShipmentAddress = false;
        this.showChangeShipmentReceiver = false;
    }
    CustomerShipmentsComponent.prototype.configureSubscriptionAddressEditForm = function () {
        this.shipmentEditAddressForm = new FormGroup({
            shippingAddress: new FormControl('', Validators.required),
            shippingAddressExtra: new FormControl(''),
            city: new FormControl('', Validators.required),
            state: new FormControl('', Validators.required),
            stateCode: new FormControl('', Validators.required),
            zip: new FormControl('', Validators.required),
            country: new FormControl('United States', Validators.required),
            countryCode: new FormControl('USA', Validators.required),
            line: new FormControl('', Validators.required),
            formattedLine: new FormControl('', Validators.required),
            label: new FormControl('', Validators.required),
        });
    };
    CustomerShipmentsComponent.prototype.ngOnInit = function () {
        this.configureBuyShipmentForm();
        this.configureReshipForm();
        this.configurePrintingDateForm();
        this.configureSubscriptionAddressEditForm();
        this.configureShipmentReceiverEditForm();
    };
    CustomerShipmentsComponent.prototype.getGiftItMessage = function (shipment) {
        var index = this.giftedShipments.findIndex(function (gs) { return gs.shipment === shipment.id; });
        if (index !== -1) {
            return this.giftedShipments[index].message;
        }
        else {
            return undefined;
        }
    };
    CustomerShipmentsComponent.prototype.configureBuyShipmentForm = function () {
        this.buyShipmentForm = new FormGroup({
            subscription: new FormControl('', Validators.required),
            shipment: new FormControl('', Validators.required),
            selectedCarrier: new FormControl('', Validators.required),
            selectedService: new FormControl('', Validators.required),
        });
    };
    CustomerShipmentsComponent.prototype.configureShipmentReceiverEditForm = function () {
        this.shipmentReceiverForm = new FormGroup({
            name: new FormControl('', Validators.required),
        });
    };
    CustomerShipmentsComponent.prototype.configurePrintingDateForm = function () {
        this.changePrintingDateForm = new FormGroup({
            cantPrintUntil: new FormControl('', Validators.required),
            shipmentId: new FormControl('', Validators.required),
        });
    };
    CustomerShipmentsComponent.prototype.onBuyShipmentAction = function (shipment) {
        var _this = this;
        this.showBuyShipmentModal = true;
        this.shipmentRatesRequested = true;
        this.buyShipmentForm.reset();
        this.buyShipmentForm.get('subscription').setValue(shipment.subscriptionId);
        this.buyShipmentForm.get('shipment').setValue(shipment.id);
        this.shipmentService.fetchShipmentRates(shipment.id)
            .pipe(tap(function (results) {
            _this.shipmentRatesRequested = false;
        }))
            .subscribe(function (rates) {
            var normalizedRates = rates.map(function (rate) { return new ShipmentRate(rate); });
            _this.shipmentRates = _this.groupRateByCarrierName(normalizedRates);
            if (!_this.buyShipmentForm.get('selectedCarrier').value) {
                _this.buyShipmentForm.get('selectedCarrier').setValue('USPS');
                _this.buyShipmentForm.get('selectedService').setValue('First');
            }
        }, function (e) {
            _this.showBuyShipmentModal = false;
            _this.alertService.danger({
                alertsCode: 'dashboard-alerts',
                title: "Can't get the rates",
                message: e.error.message
            });
        });
    };
    CustomerShipmentsComponent.prototype.groupRateByCarrierName = function (rates) {
        return rates.reduce(function (prev, current) {
            var _a, _b;
            var carrierName = current.carrier;
            var carrierClass = current.service;
            var prevClass = prev[carrierName];
            return tslib_1.__assign({}, prev, (_a = {}, _a[carrierName] = tslib_1.__assign({}, prevClass, (_b = {}, _b[carrierClass] = current, _b)), _a));
        }, {});
    };
    CustomerShipmentsComponent.prototype.onChangeSelectedCarrier = function (e) {
        var carrier = this.shipmentRates[e.target.value];
        var firstClass = Object.keys(carrier)[0];
        this.buyShipmentForm.get('selectedService').setValue(firstClass);
    };
    CustomerShipmentsComponent.prototype.buyShipment = function () {
        var _this = this;
        var _a = this.buyShipmentForm.value, subscription = _a.subscription, shipment = _a.shipment, selectedCarrier = _a.selectedCarrier, selectedService = _a.selectedService;
        this.loadingShipment(shipment, true);
        this.shipmentService.buyShipmentLabel(subscription, shipment, {
            carrier: selectedCarrier,
            service: selectedService,
        })
            .pipe(tap(function () {
            _this.showBuyShipmentModal = false;
            _this.loadingShipment(shipment, false);
        }))
            .subscribe(function (updatedShipment) {
            var index = _this.shipments.findIndex(function (s) { return s.id === updatedShipment.id; });
            _this.shipments = _this.shipments.slice(0, index).concat([
                updatedShipment
            ], _this.shipments.slice(index + 1));
        }, function (e) {
            _this.alertService.danger({
                alertsCode: 'dashboard-alerts',
                title: "Can't Buy the label.",
                message: e.error.error.message
            });
        });
    };
    CustomerShipmentsComponent.prototype.onGetLabel = function (shipmentId) {
        var _this = this;
        this.loadingShipment(shipmentId, true);
        this.shipmentService.fetchShipmentLabelPrinted(shipmentId)
            .subscribe(function (pdfPath) {
            _this.loadingShipment(shipmentId, false);
            window.open(_this.serverUrl + pdfPath);
        }, function (_a) {
            var error = _a.error;
            _this.loadingShipment(shipmentId, false);
            _this.alertService.danger({
                alertsCode: 'dashboard-alerts',
                title: "Can't get the label",
                message: error.message ? error.message : JSON.stringify(error),
                timeout: 10000
            });
        });
    };
    CustomerShipmentsComponent.prototype.onPrintLabel = function (shipmentId) {
        var _this = this;
        this.loadingShipment(shipmentId, true);
        this.shipmentService.fetchShipmentLabelToPrint(shipmentId)
            .pipe(mergeMap(function (pdfPath) {
            window.open(_this.serverUrl + pdfPath);
            return _this.shipmentService.setShipmentAsPrinted(shipmentId);
        }), tap(function () { return _this.loadingShipment(shipmentId, false); }))
            .subscribe(function (shipment) {
            var index = _this.shipments.findIndex(function (s) { return s.id === shipment.id; });
            _this.shipments = _this.shipments.slice(0, index).concat([
                shipment
            ], _this.shipments.slice(index + 1));
        }, function (_a) {
            var error = _a.error;
            _this.alertService.danger({
                alertsCode: 'dashboard-alerts',
                title: "Can't print the label",
                message: error.message ? error.message : JSON.stringify(error),
                timeout: 10000
            });
        });
    };
    CustomerShipmentsComponent.prototype.onVoidPressed = function (shipmentId) {
        var _this = this;
        this.loadingShipment(shipmentId, true);
        this.shipmentService.refundShipmentById(shipmentId)
            .pipe(tap(function () { return _this.loadingShipment(shipmentId, false); }))
            .subscribe(function (shipment) {
            var index = _this.shipments.findIndex(function (s) { return s.id === shipment.id; });
            _this.shipments = _this.shipments.slice(0, index).concat([
                shipment
            ], _this.shipments.slice(index + 1));
        }, function (e) { return alert(JSON.stringify(e.error)); });
    };
    CustomerShipmentsComponent.prototype.configureReshipForm = function () {
        this.reshipReasonForm = new FormGroup({
            shipmentId: new FormControl(null, Validators.required),
            reasons: new FormArray(this.reshipData.reshipReasons && this.reshipData.reshipReasons.length > 0
                ? this.reshipData.reshipReasons.map(function (reason) { return new FormGroup({
                    value: new FormControl(reason.value),
                    enabled: new FormControl(false),
                    name: new FormControl(reason.name)
                }); })
                : [new FormGroup({
                        value: new FormControl(''),
                        enabled: new FormControl(false),
                        name: new FormControl('Other')
                    })])
        });
    };
    CustomerShipmentsComponent.prototype.onReshipAction = function (shipmentId, refund) {
        this.reshipReasonForm.get('shipmentId').setValue(shipmentId);
        this.reshipReasonForm.get('reasons').controls.forEach(function (r) { return r.get('enabled').setValue(false); });
        this.showReshipReasonForm = true;
    };
    CustomerShipmentsComponent.prototype.onReshipPressed = function () {
        var _this = this;
        var _a = this.reshipReasonForm.value, shipmentId = _a.shipmentId, reasons = _a.reasons;
        this.loadingShipment(shipmentId, true);
        this.shipmentService.reshipShipmentById({ shipmentId: shipmentId, reasons: reasons, refundCurrentLabel: false })
            .pipe(tap(function () {
            _this.showReshipReasonForm = false;
            _this.loadingShipment(shipmentId, false);
        }))
            .subscribe(function (shipments) {
            shipments.forEach(function (shipment) {
                var index = _this.shipments.findIndex(function (s) { return s.id === shipment.id; });
                _this.shipments = shipment.reship.isReship ? [
                    shipment
                ].concat(_this.shipments) : _this.shipments.slice(0, index).concat([
                    shipment
                ], _this.shipments.slice(index + 1));
            });
        }, function (e) { return alert(JSON.stringify(e.error)); });
    };
    // utils
    CustomerShipmentsComponent.prototype.isLoading = function (shipmentId) {
        return this.loadingShipments.findIndex(function (id) { return id === shipmentId; }) !== -1;
    };
    CustomerShipmentsComponent.prototype.loadingShipment = function (shipmentId, bool) {
        var index = this.loadingShipments.findIndex(function (id) { return id === shipmentId; });
        if (bool && index === -1) {
            this.loadingShipments = [
                shipmentId
            ].concat(this.loadingShipments);
        }
        else if (!bool && index !== -1) {
            this.loadingShipments = this.loadingShipments.slice(0, index).concat(this.loadingShipments.slice(index + 1));
        }
    };
    CustomerShipmentsComponent.prototype.byKeys = function (entities) {
        return Object.keys(entities);
    };
    CustomerShipmentsComponent.prototype.onChangeAddress = function (shipment) {
        var _this = this;
        this.addressService.getAddressState()
            .pipe(take(1))
            .subscribe(function (address) {
            var currentAddress = address.find(function (a) { return a.id === shipment.address; });
            _this.shipmentEditAddressForm.patchValue(tslib_1.__assign({}, currentAddress));
            _this.activeShipment = shipment;
            _this.showChangeShipmentAddress = true;
        });
    };
    CustomerShipmentsComponent.prototype.onReceiverChange = function (shipment) {
        this.activeShipment = shipment;
        this.shipmentReceiverForm.get('name').setValue(shipment.receiver);
        this.showChangeShipmentReceiver = true;
    };
    CustomerShipmentsComponent.prototype.updateShipmentReceiver = function () {
        var _this = this;
        var receiver = this.shipmentReceiverForm.get('name').value;
        if (receiver === this.activeShipment.receiver) {
            this.showChangeShipmentReceiver = false;
            return;
        }
        this.shipmentService.updateShipment(this.activeShipment.id, { receiver: receiver }).subscribe(function (data) {
            _this.activeShipment.receiver = data.receiver;
            _this.showChangeShipmentReceiver = false;
        });
    };
    CustomerShipmentsComponent.prototype.updateShipmentAddress = function () {
        var _this = this;
        var _a = this.shipmentEditAddressForm.value, shippingAddress = _a.shippingAddress, shippingAddressExtra = _a.shippingAddressExtra, city = _a.city, stateCode = _a.stateCode, zip = _a.zip;
        this.shipmentEditAddressForm.get('line').setValue(shippingAddress);
        this.shipmentEditAddressForm.get('formattedLine').setValue(shippingAddress);
        this.shipmentEditAddressForm.get('label').setValue(shippingAddress + " " + shippingAddressExtra + " " + city + " " + stateCode + " " + zip);
        return this.shipmentService.updateShipmentAddress(this.activeShipment.id, this.shipmentEditAddressForm.value)
            .subscribe(function (data) {
            _this.store.dispatch(new fromAddressActions.AddAddressCompleteAction(data.address));
            _this.addressService.addAddressItem(data.address);
            _this.shipmentService.updateShipmentItem(data.shipment);
            _this.showChangeShipmentAddress = false;
            _this.activeShipment = undefined;
        });
    };
    CustomerShipmentsComponent.prototype.onChangePrintDateAction = function (shipment) {
        this.changePrintingDateForm.get('shipmentId').setValue(shipment.id);
        var cantPrintUntilDate = moment(shipment.printed.cantPrintUntil).format('YYYY-MM-DD');
        this.changePrintingDateForm.get('cantPrintUntil')
            .setValue(cantPrintUntilDate);
        this.showChangePrintingDateModal = true;
    };
    CustomerShipmentsComponent.prototype.changePrintingDate = function () {
        var _this = this;
        var _a = this.changePrintingDateForm.value, cantPrintUntil = _a.cantPrintUntil, shipmentId = _a.shipmentId;
        this.loadingShipment(shipmentId, true);
        this.shipmentService.changeShipmentPrintingDate(shipmentId, cantPrintUntil)
            .pipe(tap(function () {
            _this.showChangePrintingDateModal = false;
            _this.loadingShipment(shipmentId, false);
        }))
            .subscribe(function (updatedShipment) {
            var index = _this.shipments.findIndex(function (s) { return s.id === updatedShipment.id; });
            _this.shipments = _this.shipments.slice(0, index).concat([
                updatedShipment
            ], _this.shipments.slice(index + 1));
        }, function (e) {
            _this.showChangePrintingDateModal = false;
            _this.loadingShipment(shipmentId, false);
            _this.alertService.danger({
                alertsCode: 'dashboard-alerts',
                title: "Can't update the printing date.",
                message: e.error.error.message
            });
        });
    };
    return CustomerShipmentsComponent;
}());
export { CustomerShipmentsComponent };
