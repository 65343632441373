import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private apiUrl = environment.apiUrl + '/api';

  constructor(
    private http: HttpClient,
  ) { }

  processFutureSubscriptions(total: number) {
    const params = new HttpParams().set('amount', total.toString());
    return this.http.get<{ total: number, subscriptions: number[]}>(this.apiUrl.concat('/admin/billings/next-subscriptions'), { params })
  }
}
