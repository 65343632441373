import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
// Models
import { Plan } from '@app/models/plan.model';
var PlanService = /** @class */ (function () {
    function PlanService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl + '/api';
        this.plansState = [];
        this.plansState$ = new BehaviorSubject([]);
    }
    PlanService.prototype.getPlansState = function () {
        return this.plansState$;
    };
    PlanService.prototype.updateSubscriptionsState = function (data) {
        this.plans = data;
    };
    Object.defineProperty(PlanService.prototype, "plans", {
        get: function () {
            return this.plansState.slice();
        },
        set: function (data) {
            this.plansState = data;
            this.plansState$.next(data);
        },
        enumerable: true,
        configurable: true
    });
    PlanService.prototype.fetchPlans = function () {
        var _this = this;
        return this.http.get(this.apiUrl + "/products/plans/")
            .pipe(map(function (response) {
            var newState = _this.mergeState(_this.plans, response.plans.map(function (plan) { return new Plan(plan); }));
            _this.updateSubscriptionsState(newState);
            return newState;
        }));
    };
    // UTILS
    PlanService.prototype.mergeState = function (oldState, newState) {
        if (oldState.length === 0) {
            return newState;
        }
        return newState.reduce(function (all, current) {
            var temp = 'length' in all ? all : [];
            var index = temp.findIndex(function (t) { return t.id === current.id; });
            if (index === -1) {
                return temp.concat([current]);
            }
            else {
                return temp.slice(0, index).concat([
                    current
                ], temp.slice(index + 1));
            }
        }, oldState);
    };
    return PlanService;
}());
export { PlanService };
