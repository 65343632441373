import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, flatMap, map, mergeMap } from 'rxjs/operators';
import * as LabelPrinter from '@config/label-printer.json';
// Store
import { Store } from '@ngrx/store';
import * as fromAddress from './../store/selectors/address.selectors';
import * as fromAddressActions from './../store/actions/address.actions';
import { AlertService } from '@app/shared/components/alerts/alert.service';
import * as fromServices from './../services';
import { SocketIoService } from '@app/core/services';
var ShipmentsLabelsComponent = /** @class */ (function () {
    function ShipmentsLabelsComponent(el, route, alertService, customerService, shipmentService, store, formBuilder, socketIO) {
        var _this = this;
        this.el = el;
        this.route = route;
        this.alertService = alertService;
        this.customerService = customerService;
        this.shipmentService = shipmentService;
        this.store = store;
        this.formBuilder = formBuilder;
        this.socketIO = socketIO;
        this.labelPrinterData = LabelPrinter.default;
        this.loadingShipment = false;
        this.page = 1;
        this.searchPage = 1;
        this.shipmentsLabelsRows = [];
        this.showSearchLabelsField = false;
        this.onSearchLabel$ = new Subject();
        this.hidePrintedLabels = false;
        this.downloadingPdf = false;
        this.downloadingPdfByTrackingCodes = false;
        this.loadingShipmentsStats = false;
        this.showPrintingModal = false;
        this.isManifestConfirmationModalActive = false;
        this.printByTrackingNumbers = false;
        this.loadingManifests = false;
        this.showBuildsOptions = false;
        this.carrierList = [
            {
                carrierName: 'USPS',
                services: [
                    'First',
                    'Priority',
                    'Express',
                    'ParcelSelect',
                    'LibraryMail',
                    'MediaMail',
                    'FirstClassMailInternational',
                    'FirstClassPackageInternationalService',
                    'PriorityMailInternational',
                    'ExpressMailInternational',
                ]
            },
            {
                carrierName: 'UPSMailInnovations',
                services: [
                    'First',
                    'Priority',
                    'ExpeditedMailInnovations',
                    'PriorityMailInnovations',
                    'EconomyMailInnovations',
                ]
            },
            {
                carrierName: 'DHLGlobalMail',
                services: [
                    'MarketingParcelGroundDomestic',
                    'MarketingParcelExpeditedDomestic',
                    'ParcelsGroundDomestic',
                    'ParcelsExpeditedDomestic'
                ]
            },
            {
                carrierName: 'DHLeCommerce',
                services: [
                    'DHLSMParcelExpedited',
                    'DHLSMParcelGround',
                    'DHLSMParcelExpeditedMax'
                ]
            }
        ];
        this.carrierSelectedServices = [];
        this.currentFormattedMonth = moment().format('MMMM YYYY');
        this.monthlyStatsList = [];
        this.optionStats = {};
        this.showAll = false;
        // IO events
        this.onBuildFailedSubscription = this.socketIO.listen('SHIPMENTS__BUILD-FAILED')
            .subscribe(function (data) {
            _this.downloadingPdf = false;
            _this.alertService.danger({
                alertsCode: 'sl-alerts',
                title: "Can't create the build",
                message: data.message ? data.message : JSON.stringify(data),
                timeout: 10000
            });
        });
        this.onBuildGenerated = this.socketIO.listen('SHIPMENTS__BUILD-FINISHED')
            .subscribe(function (_a) {
            var pdfUrl = _a.pdfUrl;
            _this.downloadingPdfByTrackingCodes = false;
            _this.downloadingPdf = false;
            window.open(pdfUrl);
        });
        this.serverUrl = environment.apiUrl;
        this.shipments = [];
        this.customers = [];
    }
    ShipmentsLabelsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.addresses$ = this.store.select(fromAddress.getAddressEntities);
        this.el.nativeElement.classList.add('container');
        this.shipmentService.getShipmentsState()
            .subscribe(function (shipments) {
            _this.shipments = shipments;
            _this.shipmentsLabelsRows = _this.getFormatedShipmentsLabelsRows(_this.shipments, _this.customers);
        });
        this.customerService.getCustomersState()
            .subscribe(function (customers) {
            _this.customers = customers;
            _this.shipmentsLabelsRows = _this.getFormatedShipmentsLabelsRows(_this.shipments, _this.customers);
        });
        this.loadShipments();
        this.onSearchLabel$
            .pipe(map(function (element) {
            _this.searchingText = element.value;
            return element;
        }), debounceTime(1500))
            .subscribe(function (target) { return _this.onSearchLabel(target); });
        this.updateShipmentsStats();
        this.configureBuildOptionsForm();
    };
    ShipmentsLabelsComponent.prototype.ngOnDestroy = function () {
        this.onBuildFailedSubscription.unsubscribe();
        this.onBuildGenerated.unsubscribe();
    };
    ShipmentsLabelsComponent.prototype.loadShipments = function () {
        var _this = this;
        this.fetchShipmentsAndUsersFromApi()
            .subscribe(function (results) {
            _this.shipmentService.updateShipmentsState(results.shipments);
            _this.customerService.updateCustomersState(results.customers);
        }, function (e) { return console.log(e); });
    };
    ShipmentsLabelsComponent.prototype.fetchShipmentsAndUsersFromApi = function (page, limit) {
        var _this = this;
        if (page === void 0) { page = 0; }
        if (limit === void 0) { limit = 25; }
        this.loadingShipment = true;
        return this.shipmentService.fecthShipmentsFromApi({ limit: limit, page: page })
            .pipe(mergeMap(function (shipments) { return _this.customerService.fetchCustomersByIdsFromApi({
            ids: shipments.map(function (shipment) { return shipment.userId; }),
            limit: limit,
            page: undefined
        })
            .pipe(map(function (customers) {
            _this.store.dispatch(new fromAddressActions.LoadCustomersAddressesAction({
                ids: customers.map(function (customer) { return customer.id; })
            }));
            _this.loadingShipment = false;
            return { customers: customers, shipments: shipments };
        })); }));
    };
    ShipmentsLabelsComponent.prototype.getFormatedShipmentsLabelsRows = function (shipments, customers) {
        if ((typeof shipments === 'undefined' || shipments.length === 0)
            || (typeof shipments === 'undefined' || customers.length === 0)) {
            return [];
        }
        return shipments.reduce(function (allRows, currentShipment) {
            var temp = 'length' in allRows ? allRows : [];
            var currentCustomer = customers[customers.findIndex(function (c) { return c.id === currentShipment.userId; })];
            if (typeof currentCustomer === 'undefined') {
                return temp;
            }
            return temp.concat([{
                    shipmentId: currentShipment.id,
                    userId: currentCustomer.id,
                    address: currentShipment.address,
                    userName: currentCustomer.firstName + " " + currentCustomer.lastName,
                    labelUrl: typeof currentShipment.shippingLabel !== 'undefined' ? currentShipment.shippingLabel.labelUrl : undefined,
                    printed: currentShipment.printed.isPrinted,
                    refunded: currentShipment.tracker ? currentShipment.tracker.refunded : false,
                    cancelled: currentShipment.cancelled,
                }]);
        }, []);
    };
    ShipmentsLabelsComponent.prototype.getShipmentAddress = function (shipment) {
        return this.store.select(fromAddress.getAddressEntities)
            .pipe(map(function (addresses) { return addresses[shipment.address]; }));
    };
    ShipmentsLabelsComponent.prototype.filteredLabels = function (shipmentsLabelsRows) {
        var filteredShipmentsLabelsRows = shipmentsLabelsRows;
        var searchText = this.searchingText;
        if (typeof searchText !== 'undefined' && searchText.length >= 1) {
            filteredShipmentsLabelsRows = filteredShipmentsLabelsRows.filter(function (fslr) {
                return fslr.userName.toLowerCase().includes(searchText.toLowerCase())
                    || fslr.address.toLowerCase().includes(searchText.toLowerCase());
            });
        }
        if (this.hidePrintedLabels) {
            filteredShipmentsLabelsRows = filteredShipmentsLabelsRows.filter(function (fslr) { return fslr.printed === false; });
        }
        return filteredShipmentsLabelsRows;
    };
    ShipmentsLabelsComponent.prototype.onLoadMore = function (page) {
        var _this = this;
        this.loadingShipment = true;
        this.fetchShipmentsAndUsersFromApi(0, 25 * page)
            .subscribe(function (results) {
            _this.loadingShipment = false;
            if (results.shipments.length !== 0 && results.customers.length !== 0) {
                _this.page = page;
            }
        });
    };
    ShipmentsLabelsComponent.prototype.onPrintLabel = function (shipmentId) {
        var _this = this;
        this.loadingShipment = true;
        this.shipmentService.fetchShipmentLabelToPrint(shipmentId)
            .pipe(mergeMap(function (pdfPath) {
            window.open(_this.serverUrl + pdfPath);
            return _this.shipmentService.setShipmentAsPrinted(shipmentId);
        }))
            .subscribe(function (shipment) {
            _this.loadingShipment = false;
            _this.updateShipmentsStats();
        }, function (_a) {
            var error = _a.error;
            _this.downloadingPdf = false;
            _this.alertService.danger({
                alertsCode: 'sl-alerts',
                title: "Can't print the label",
                message: error.message ? error.message : JSON.stringify(error),
                timeout: 10000
            });
        });
    };
    ShipmentsLabelsComponent.prototype.onSearchLabel = function (element) {
        var _this = this;
        this.searchPage = 1;
        if (element.value.length === 0) {
            return;
        }
        this.customerService.fetchCustomersByNameAndEmailFromApi(element.value, 0, 100 * this.searchPage + 1)
            .pipe(flatMap(function (customers) {
            var customersIds = customers.map(function (c) { return c.id; });
            return _this.shipmentService.fetchShipmentsByUsers(customersIds, 0, 100 * _this.searchPage + 1);
        }))
            .subscribe(function (shipments) {
            _this.searchPage = _this.searchPage + 1;
        });
    };
    ShipmentsLabelsComponent.prototype.onShowHidePrintedLabels = function () {
        this.hidePrintedLabels = !this.hidePrintedLabels;
    };
    ShipmentsLabelsComponent.prototype.updateShipmentsStats = function () {
        var _this = this;
        this.loadingShipmentsStats = true;
        this.shipmentService.fetchShipmentsStatsFromApi()
            .subscribe(function (stats) {
            _this.shipmentsStats = stats;
            if (_this.shipmentsStats && _this.shipmentsStats.monthlyStats) {
                _this.monthlyStatsList = Object
                    .keys(_this.shipmentsStats.monthlyStats)
                    .sort(function (a, b) {
                    var dateA = moment(a, 'MMMM YYYY');
                    var dateB = moment(b, 'MMMM YYYY');
                    return dateA.isBefore(dateB) ? 1 : -1;
                });
                _this.buildOptionsForm.get('date').patchValue(_this.currentFormattedMonth);
            }
            _this.loadingShipmentsStats = false;
        });
    };
    ShipmentsLabelsComponent.prototype.getTodayShipmentsManifest = function () {
        var _this = this;
        this.loadingManifests = true;
        this.shipmentService.getTodayShipmentManifest()
            .subscribe(function (manifestsUrl) {
            _this.loadingManifests = false;
            _this.closeManifestConfirmationModal();
            manifestsUrl.forEach(function (url) { return window.open(url); });
        }, function (error) {
            console.log(error);
            var serverError = error.error || error;
            _this.loadingManifests = false;
            _this.closeManifestConfirmationModal();
            _this.alertService.danger({
                alertsCode: 'sl-alerts',
                title: "Can't create manifest.",
                message: serverError.message ? serverError.message : JSON.stringify(serverError),
                timeout: 10000
            });
        });
    };
    ShipmentsLabelsComponent.prototype.openManifestConfirmationModal = function () {
        this.isManifestConfirmationModalActive = true;
    };
    ShipmentsLabelsComponent.prototype.closeManifestConfirmationModal = function () {
        this.isManifestConfirmationModalActive = false;
    };
    ShipmentsLabelsComponent.prototype.updateCarrierServicesList = function (carrierName) {
        var serviceControl = this.buildOptionsForm.get('selectedService');
        var carrier = this.carrierList.find(function (e) { return e.carrierName === carrierName; });
        this.carrierSelectedServices = carrier ? carrier.services : [];
        if (!this.carrierSelectedServices.some(function (e) { return e === serviceControl.value; })) {
            serviceControl.setValue(null);
        }
    };
    ShipmentsLabelsComponent.prototype.onPrintBuild = function (value) {
        var _this = this;
        this.showBuildsOptions = false;
        this.downloadingPdf = true;
        if (this.buildOptionsForm.valid) {
            this.shipmentService.fetchUnprintedShipmentsPdf(value)
                .subscribe(function (_) {
                _this.alertService.success({
                    alertsCode: 'sl-alerts',
                    title: 'Build is being generated',
                    message: '',
                    timeout: 5000
                });
            }, function (_a) {
                var error = _a.error;
                _this.downloadingPdf = false;
                _this.alertService.danger({
                    alertsCode: 'sl-alerts',
                    title: "Can't create the build",
                    message: error.message ? error.message : JSON.stringify(error),
                    timeout: 10000
                });
            });
            this.configureBuildOptionsForm();
        }
    };
    ShipmentsLabelsComponent.prototype.parseMonth = function (num) {
        return [undefined, 'January', 'February', 'March', 'April', 'May', 'June', 'July',
            'August', 'September', 'October', 'November', 'December'][num];
    };
    // ---- COMPONENT HELPERS
    ShipmentsLabelsComponent.prototype.downloadPdf = function (pdfBytes, pdfName) {
        var newBlob = new Blob([pdfBytes], { type: 'application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }
        var data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.target = '_blank';
        link.download = pdfName + '.pdf';
        link.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(data);
        }, 100);
    };
    ShipmentsLabelsComponent.prototype.submitTrackingNumbers = function () {
        var _this = this;
        this.printByTrackingNumbers = false;
        var csvFile = this.csvInputFile.nativeElement.files;
        var reader = new FileReader();
        reader.onload = function (e) {
            var result = e.target.result;
            var trackingCodes = result ? result.split(/\r?\n|\r/g) : [];
            _this.downloadingPdfByTrackingCodes = true;
            _this.shipmentService.fetchShipmentLabelByTrackingNumbers(trackingCodes)
                .subscribe(function (_) { }, function (error) {
                _this.downloadingPdf = false;
                _this.alertService.danger({
                    alertsCode: 'sl-alerts',
                    title: "Can't create the build",
                    message: error.message ? error.message : JSON.stringify(error),
                    timeout: 10000
                });
            });
        };
        reader.readAsText(csvFile[0]);
    };
    ShipmentsLabelsComponent.prototype.getTypeStats = function (selectedDate) {
        var _this = this;
        console.log('test', selectedDate);
        this.loadingShipmentsStats = true;
        this.shipmentService.fetchShipmentsStatsByTypeFromApi(selectedDate)
            .subscribe(function (stats) {
            console.log(stats);
            _this.optionStats = stats;
            _this.loadingShipmentsStats = false;
        });
    };
    // ---- Printing options
    ShipmentsLabelsComponent.prototype.configureBuildOptionsForm = function () {
        var _this = this;
        this.buildOptionsForm = this.formBuilder.group({
            month: this.formBuilder.control('ALL', [Validators.required]),
            option: this.formBuilder.control('ALL', [Validators.required]),
            date: this.formBuilder.control('ALL', [Validators.required]),
            quantity: this.formBuilder.control(100, [Validators.required, Validators.min(0), Validators.max(100)]),
            selectedCarrier: [undefined],
            selectedService: [undefined],
            selectedBox: ['all', Validators.required],
        });
        this.buildOptionsForm.get('selectedCarrier').valueChanges.subscribe(function (value) {
            for (var i = 0; i < _this.labelPrinterData.defaultBuildRates.length; i++) {
                var rateObject = _this.labelPrinterData.defaultBuildRates[i];
                if (rateObject.carrier === value) {
                    _this.buildOptionsForm.get('selectedService').setValue(rateObject.rate);
                    break;
                }
            }
        });
        this.buildOptionsForm.get('date').valueChanges.subscribe(function (value) {
            console.log(value);
            _this.getTypeStats(value);
        });
        // this.buildOptionsForm.get('customCarrier').valueChanges.subscribe((value) => {
        //   const validations = value ? [Validators.required] : [];
        //   ['selectedCarrier', 'selectedService'].forEach((item) => {
        //     this.buildOptionsForm.get(item).setValidators(validations);
        //     this.buildOptionsForm.get(item).updateValueAndValidity();
        //   });
        //   if (!value) {
        //     this.buildOptionsForm.get('selectedCarrier').reset();
        //     this.buildOptionsForm.get('selectedService').reset();
        //   } else {
        //     this.buildOptionsForm.get('selectedCarrier').setValue('UPSMailInnovations');
        //     this.buildOptionsForm.get('selectedService').setValue('ExpeditedMailInnovations');
        //   }
        // });
        // this.buildOptionsForm.get('selectedCarrier').valueChanges.subscribe((value) => {
        //   this.updateCarrierServicesList(value);
        // });
    };
    return ShipmentsLabelsComponent;
}());
export { ShipmentsLabelsComponent };
