import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxWebstorageModule } from 'ngx-webstorage';
// @ngrx modules
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, } from '@ngrx/router-store';
// effects
import * as fromEffects from './store/effects';
// reducer
import { reducers } from '@app/store/reducers';
// interceptors
import { AuthInterceptor } from './interceptors/auth.interceptor';

import { AppRoutingModule } from './app-routing.module';
// Components
import { AppComponent } from './app.component';
import { ShipmentsLabelsComponent } from './shipments-labels/shipments-labels.component';
import { ShipmentsCreatorComponent } from './shipments-creator/shipments-creator.component';
import { LoginComponent } from './login/login.component';
import { ChurnComponent } from './churn/churn.component';
import { SalesComponent } from './sales/sales.component';

import * as fromServices from './services';
import { SocketIoService } from './core/services/socket-io.service';

import { AuthGuard } from './guards/auth/auth.guard';
// Modules
import { CoreModule } from './core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { DashboardModule } from '@app/dashboard/dashboard.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RecoveryPasswordGuard } from '@app/guards/auth/recovery-password.guard';
import { BuildsListComponent } from './shipments-labels/builds-list/builds-list.component';
import { ProcessSubscriptionsComponent } from './shipments-labels/components/process-subscriptions/process-subscriptions.component';
import { ManifestsListComponent } from './shipments-labels/manifests-list/manifests-list.component';

@NgModule({
  declarations: [
    AppComponent,
    ShipmentsLabelsComponent,
    ShipmentsCreatorComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    BuildsListComponent,
    ChurnComponent,
    SalesComponent,
    ManifestsListComponent,
    ProcessSubscriptionsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxWebstorageModule.forRoot({
      prefix: 'ssops:v0.0.1', separator: '::', caseSensitive: true
    }),
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    }),
    EffectsModule.forRoot([...fromEffects.effects]),
    CoreModule,
    SharedModule,
    DashboardModule,
    AppRoutingModule,
  ],
  providers: [
    ...fromServices.services,
    SocketIoService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthGuard,
    RecoveryPasswordGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
