<form (submit)="onSubmit()">
  <div class="input-group">
    <input [formControl]="searchControl"
           class="form-control"
           placeholder="Please write full email or full name."
           type="text"/>
  </div>
</form>
<div *ngIf="displayResults" class="results">
  <ul class="list-group list-group-flush">

    <li *ngIf="autoCompleteResult?.subscriptions.length" class="list-group-item">
      Subscriptions
      <ul class="list-group list-group-flush results-items">
        <li (click)="selectSubscription(subscription)" *ngFor="let subscription of autoCompleteResult.subscriptions"
            class="list-group-item">
          <div>
            <!--{{ subscription.id }}-->
            {{ subscription._id?.firstName + ' ' + subscription._id?.lastName }}
          </div>
        </li>
      </ul>
    </li>
    <li *ngIf="autoCompleteResult?.customers.length" class="list-group-item">
      Customers
      <ul class="list-group list-group-flush results-items">
        <li (click)="selectCustomer(customer)" *ngFor="let customer of autoCompleteResult.customers"
            class="list-group-item">
          <div>
            Name: {{ customer.firstName + ' ' + customer.lastName }}
          </div>
          <div>Email: {{ customer.email }}</div>
        </li>
      </ul>
    </li>
    <li *ngIf="autoCompleteResult.shipments.length" class="list-group-item">
      Shipments
      <ul class="list-group list-group-flush results-items">
        <li (click)="selectShipment(shipment)" *ngFor="let shipment of autoCompleteResult.shipments;"
            class="list-group-item">
          <div>
            <!--<span>Subscription: {{ shipment.subscriptionId }}</span>-->
            <!--<span>Receiver: {{ shipment.receiver }}</span>-->
            <span>Receiver: {{ shipment._id }}</span>
          </div>
          <!--<div>TrackingCode: {{ shipment.tracker?.trackingCode }}</div>-->
        </li>
      </ul>
    </li>
    <li *ngIf="autoCompleteResult?.addresses.length" class="list-group-item">
      Address
      <ul class="list-group list-group-flush results-items">
        <li (click)="selectAddress(address)" *ngFor="let address of autoCompleteResult.addresses"
            class="list-group-item">
          <span> {{ address._id }}</span>
        </li>
      </ul>
    </li>
  </ul>
</div>
