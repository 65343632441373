import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity/src/models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { format, isBefore } from 'date-fns';

import { BehaviorSubject, combineLatest, Observable, SubscriptionLike as ISubscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
// services
import * as fromServices from './../services';
import { PaymentService } from './../services';
// actions
import * as fromAddressActions from './../store/actions/address.actions';
import * as fromNotesActions from './../store/actions/notes.actions';
// selectors
import * as fromAddressSelectors from './../store/selectors/address.selectors';
import * as fromNotesSelectors from './../store/selectors/notes.selectors';
// models
import { Customer, Referral } from '@app/models/customer.model';
import { Subscription as SubscriptionModel } from '@app/models/subscription.model';
import { Shipment, ShipmentRate } from '@app/models/shipment.model';
import { ActionService } from '@app/services/action.service';
import { DashboardService, DataFromDashboard } from '@app/dashboard/services/dashboard.service';
import { Note } from '@app/models/note.model';

import { Plan } from '@app/models/plan.model';
import { AlertService } from '@app/shared/components/alerts/alert.service';
import { Action } from '@app/models/action.model';
import { Address } from '@app/models/address.model';
import { Payment } from '@app/models/payment.model';

import { environment } from '@env/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DashboardService]
})
export class DashboardComponent implements OnInit, OnDestroy {

  noResultsFound;
  serverUrl: string = environment.apiUrl;

  searchForm = new FormGroup({
    searchValue: new FormControl('', Validators.required),
    filter: new FormControl('email', Validators.required)
  });
  searchBy = [{
    name: 'E-mail',
    value: 'email'
  }, {
    name: `User's name`,
    value: 'user_name',
  }, {
    name: `Recipient's name`,
    value: 'recipient_name'
  }, {
    name: 'Tracking code',
    value: 'tracking_code'
  }];
  searchFilterSelected$ = new BehaviorSubject('E-mail');

  subscriptionToSelectBox: SubscriptionModel;
  showSubscriptionToSelectBox = false;

  customersState$: ISubscription;
  customers: Customer[] = [];

  subscriptionsState$: ISubscription;
  subscriptions: SubscriptionModel[] = [];

  shipmentsState$: ISubscription;
  shipments: Shipment[] = [];

  actionsState$: ISubscription;
  actions: Action[] = [];

  planState$: ISubscription;
  plans: Plan[] = [];
  giftPlans: Plan[] = [];

  giftPlansToIds = [
    '5a10dbfaf8ceb4830ff186a9',
    '5abd46654d4f52a1ea64fa91',
    '5b33d996641451a36467b89d'
  ];

  addressIds$: Observable<string[] | number[]>;
  address$: Observable<Dictionary<Address>>;
  address: Dictionary<Address> = {};

  notesIds$: Observable<any[]>;
  notes$: Observable<Dictionary<Note>>;
  notesIds: any[] = [];
  notes: Dictionary<Note> = {};


  payments: Payment[] = [];
  paymentsState$: ISubscription;

  listItems$: BehaviorSubject<DashboardListItem[]> = new BehaviorSubject([]);

  showCancelModal = false;
  cancelForm: FormGroup;

  showTurnOffModal = false;
  turnOffForm: FormGroup;

  showSubscriptionEditModal = false;
  subscriptionEditForm: FormGroup;
  subscriptionEditAddressForm: FormGroup;

  processSubscriptionsOptsForm: FormGroup;
  showProcessSubscriptionOptionsModal = false;

  currentSubscriptionGiftIt: any;

  customerEditForm: FormGroup;
  showCustomerEditModal = false;

  autoRenewOnForm: FormGroup;
  showAutoRenewOnModal = false;

  freeShipmentForm: FormGroup;
  showFreeShipmentModal = false;

  showPreferredShipperModal = false;
  preferredShipperForm: FormGroup;
  shipmentRates: any;
  shipmentRatesRequested = false;

  showResetPasswordModal = false;
  newNoteText = new FormControl('');

  showPaymentMethosdModal = false;

  referralPromotions: any[] = [];

  resetPasswordForm: FormGroup;
  isSearching = false;
  isProcessing = false;


  selectedCustomer: Customer;
  selectedSubscription: SubscriptionModel;

  constructor(
    private customerService: fromServices.CustomerService,
    private addressService: fromServices.AddressService,
    private subscriptionService: fromServices.SubscriptionService,
    private shipmentsService: fromServices.ShipmentService,
    private configService: fromServices.ConfigService,
    private planService: fromServices.PlanService,
    private alertService: AlertService,
    private actionService: ActionService,
    private paymentService: PaymentService,
    private dashboardService: DashboardService,
    private fb: FormBuilder,
    private store: Store<any>
  ) {
  }

  ngOnInit() {
    this.address$ = this.store.select(fromAddressSelectors.getAddressEntities);
    this.addressIds$ = this.store.select(fromAddressSelectors.getAddressesIds);

    this.notes$ = this.store.select(fromNotesSelectors.getNotesEntities);
    this.notesIds$ = this.store.select(fromNotesSelectors.getNotesIds);

    this.address$
      .subscribe(address => this.address = address);

    this.notes$
      .subscribe(notes => this.notes = notes);

    this.notesIds$
      .subscribe(notes => this.notesIds = notes);

    this.actionsState$ = this.actionService.getActionsState()
      .subscribe(actions => this.actions = actions);

    this.planState$ = this.planService.getPlansState()
      .subscribe(plans => {
        this.plans = plans;
        this.giftPlans = plans
          .filter(p => p.type === 'GIFT' && ['2x_3gift', '2x_6gift', '2x_12gift'].includes(p.name));
      });

    this.planService.fetchPlans()
      .subscribe(plans => {
        this.plans = plans;
        this.giftPlans = plans
          .filter(p => p.type === 'GIFT' && ['2x_3gift', '2x_6gift', '2x_12gift'].includes(p.name));
      });

    this.customersState$ = this.customerService.getCustomersState()
      .subscribe(customers => this.customers = customers);

    this.subscriptionsState$ = this.subscriptionService.getSubscriptions()
      .subscribe(subscriptions => this.subscriptions = subscriptions);

    this.shipmentsState$ = this.shipmentsService.getShipmentsState()
      .subscribe(shipments => this.shipments = shipments);

    this.paymentsState$ = this.paymentService.getPaymentsState()
      .subscribe(payments => this.payments = payments);

    // this.setSearchBarFunctionality();
    this.configureCancelForm();
    this.configureSubscriptionEditForm();
    this.configureCustomerEditForm();
    this.configureSubscriptionAddressEditForm();
    this.configureAutoRenewOnForm();
    this.configureTurnOffForm();
    this.configureProcessSubsOptionsForm();
    this.configureCreateShipmentForm();
    this.configurePreferresShipperForm();
    this.configureResetPasswordForm();
  }

  ngOnDestroy() {
    this.actionsState$.unsubscribe();
    this.planState$.unsubscribe();
    this.customersState$.unsubscribe();
    this.subscriptionsState$.unsubscribe();
    this.shipmentsState$.unsubscribe();
    this.paymentsState$.unsubscribe();
  }

  byKeys(entities = []): string[] {
    return Object.keys(entities);
  }

  refreshList() {
    if (this.plans.length > 0) {
      this.listItems$.next(this.createListItems(this.customers, this.subscriptions, this.shipments, this.actions, this.payments));
    } else {
      this.listItems$.next(this.createListItems());
    }
  }

  searchBarSelectedItem(users) {
    this.noResultsFound = false;
    this.isSearching = true;
    combineLatest(
      this.dashboardService.fetchDataForDashboard(users),
      this.configService.getReferralPromotions(),
    )
      .subscribe(([results, referralPromotions]: [DataFromDashboard, any[]]) => {
        this.isSearching = false;
        this.referralPromotions = referralPromotions;

        this.actionService.updateActionsState(results.actions.map(a => new Action(a)));
        this.customerService.updateCustomersState(results.customers.map(c => new Customer(c)));
        this.subscriptionService.updateSubscriptionsState(results.subscriptions.map(s => new SubscriptionModel(s)));
        this.shipmentsService.updateShipmentsState(results.shipments.map(s => new Shipment(s)));
        this.paymentService.updatePaymentsState(results.payments.map(p => new Payment(p)));

        const newAddress = results.address.map(address => new Address(address));
        this.addressService.updateAddressState(newAddress);
        const newNotes = results.notes.map(notes => new Note(notes));

        // Load addresses.
        this.store.dispatch(new fromAddressActions.LoadCustomersAddressesCompleteAction(newAddress));
        this.store.dispatch(new fromNotesActions.LoadSubscriptionNotesCompleteAction(newNotes));

        this.refreshList();
      }, e => {
        this.actionService.updateActionsState([]);
        this.customerService.updateCustomersState([]);
        this.subscriptionService.updateSubscriptionsState([]);
        this.shipmentsService.updateShipmentsState([]);
        this.paymentService.updatePaymentsState([]);
        // Load addresses.
        this.store.dispatch(new fromAddressActions.LoadCustomersAddressesCompleteAction([]));
        this.store.dispatch(new fromNotesActions.LoadSubscriptionNotesCompleteAction([]));

        this.refreshList();
        this.isSearching = false;
        this.noResultsFound = true;
        console.log(e);
      });
  }


  createCancelReasonItem(name) {
    return this.fb.group({
      name,
      value: '',
      enabled: false,
    });
  }

  configureCancelForm() {
    this.cancelForm = this.fb.group({
      subscriptionId: [null, Validators.required],
      reasons: this.fb.array([
        this.createCancelReasonItem('Too many plants'),
        this.createCancelReasonItem('Need to save money'),
        this.createCancelReasonItem('Not what expected'),
        this.createCancelReasonItem('Didn’t receive shipment(s)'),
        this.createCancelReasonItem('Other'),
      ]),
    });
  }

  configureTurnOffForm() {
    this.turnOffForm = this.fb.group({
      subscriptionId: [null, Validators.required],
      userId: [null, Validators.required],
      reasons: this.fb.array([
        this.createCancelReasonItem('Too many plants'),
        this.createCancelReasonItem('Need to save money'),
        this.createCancelReasonItem('Not what expected'),
        this.createCancelReasonItem('Didn’t receive shipment(s)'),
        this.createCancelReasonItem('Other'),
      ]),
    });
  }

  configureProcessSubsOptionsForm() {
    this.processSubscriptionsOptsForm = this.fb.group({
      subscriptionId: ['', Validators.required],
      customerId: ['', Validators.required],
      processGiftItIfExist: [true, Validators.required],
      cleanDeclineStatus: [true, Validators.required],
      ignoreFreeMonths: [false, Validators.required],
      keepNBD: [false, Validators.required],
      discount: [0, Validators.required],
    });
  }

  configureSubscriptionEditForm() {
    this.subscriptionEditForm = new FormGroup({
      subscriptionId: new FormControl('', Validators.required),
      plan: new FormControl('', Validators.required),
      isPrepay: new FormControl('', Validators.required),
      recipient: new FormGroup({
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
      }),
      nextBillingDate: new FormControl('', [
        Validators.required,
        Validators.pattern(/\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])/)
      ]),
    });
  }

  configureSubscriptionAddressEditForm() {
    this.subscriptionEditAddressForm = new FormGroup({
      shippingAddress: new FormControl('', Validators.required),
      shippingAddressExtra: new FormControl(''),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      stateCode: new FormControl('', Validators.required),
      zip: new FormControl('', Validators.required),
      country: new FormControl('United States', Validators.required),
      countryCode: new FormControl('USA', Validators.required),
      line: new FormControl('', Validators.required),
      formattedLine: new FormControl('', Validators.required),
      label: new FormControl('', Validators.required),
    });
  }

  configureCustomerEditForm() {
    this.customerEditForm = new FormGroup({
      id: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required)
    });
  }

  configureAutoRenewOnForm() {
    this.autoRenewOnForm = this.fb.group({
      subscriptionId: ['', Validators.required],
      userId: ['', Validators.required],
      nextBillingDate: ['', Validators.required],
    });
  }

  configurePreferresShipperForm() {
    this.preferredShipperForm = this.fb.group({
      subscriptionId: [null, Validators.required],
      selectedCarrier: [null, Validators.required],
      selectedService: [null, Validators.required],
    });
  }

  configureResetPasswordForm() {
    this.resetPasswordForm = this.fb.group({
      customerId: [null, Validators.required],
      password: [null, Validators.required],
      // confirmPassword: [null, Validators.required],
    });
  }

  createListItems(
    customers: Customer[] = [],
    subscriptions: SubscriptionModel[] = [],
    shipments: Shipment[] = [],
    actions: Action[] = [],
    payments: Payment[] = []
  ): any[] {

    return customers.reduce((allItem: DashboardListItem[], customer: Customer, i: number) => {
      const temp: any = 'length' in allItem ? allItem : [];

      const subsIds = subscriptions.filter(sub => sub.customerId === customer.id)
        .map(sub => sub.id);

      const tempPayments: Payment[] = this.payments.filter(payment =>
        subsIds.includes(payment.subscription)) || [];

      temp.push({
        customer: {
          ...customer,
          payments: tempPayments,
          shipments: shipments.filter(s => !s.subscriptionId)
        },
        subscriptions: subscriptions.reduce((allSubs, currentSub): any => {
          return [
            ...allSubs,
            {
              ...currentSub,
              shipments: shipments.filter(s => s.subscriptionId === currentSub.id)
                .sort((a, b) => isBefore(a.createdAt, b.createdAt) ? 1 : -1),
              actions: actions.filter(a => a.subscriptionId === currentSub.id)
                .sort((a, b) => isBefore(a.createdAt, b.createdAt) ? 1 : -1),
            }
          ];
        }, [])
      });
      return temp;
    }, []);
  }

  getSubscriptionsShipments(subscriptionId: number, shipments: Shipment[]) {
    return shipments
      .filter(s => s.subscriptionId === subscriptionId)
      .sort((a, b) => isBefore(a.createdAt, b.createdAt) ? 1 : -1);
  }

  getSubscriptionsActions(subscriptionId: number, actions: Action[]) {
    return actions
      .filter(s => s.subscriptionId === subscriptionId)
      .sort((a, b) => isBefore(a.createdAt, b.createdAt) ? 1 : -1);
  }

  showProcessSubscriptionModal(subscription: SubscriptionModel) {
    this.showProcessSubscriptionOptionsModal = true;
    this.processSubscriptionsOptsForm.get('subscriptionId').setValue(subscription.id);
    this.processSubscriptionsOptsForm.get('customerId').setValue(subscription.customerId);
    this.currentSubscriptionGiftIt = subscription.giftedShipments
      .find(item => item.status === 'WAITING_FOR_PAYMENT');
  }

  processSubscription() {
    this.isProcessing = true;
    this.subscriptionService.processSubscriptionBilling(this.processSubscriptionsOptsForm.value)
      .subscribe(data => {
        console.log('READY', data);
        this.showProcessSubscriptionOptionsModal = false;
        this.refreshList();
        this.isProcessing = false;
      }, e => {
        this.isProcessing = false;
        this.showProcessSubscriptionOptionsModal = false;
        this.alertService.danger({
          alertsCode: 'dashboard-alerts',
          title: `Can't process subscription`,
          message: e.error.error ? e.error.error.message : e.error.message
        });
      });
  }

  updateLoading(type: string, id: string | number, isLoading: boolean) {
    let index;
    switch (type) {
      case 'shipments':
        index = this.shipments.findIndex(s => s.id === id);
        this.shipments[index].loading = isLoading;
        this.shipmentsService.updateShipmentsState(this.shipments);
        break;

      case 'subscriptions':
        index = this.subscriptions.findIndex(s => s.id === id);
        this.subscriptions[index].loading = isLoading;
        this.subscriptionService.updateSubscriptionsState(this.subscriptions);
        break;

      default:
        console.log(`Can't update this kind of type:`, type);
        break;
    }
    this.refreshList();
  }

  getPlan(planId: string): Plan {
    return this.plans[this.plans.findIndex(plan => plan.id === planId)];
  }

  onCancelAction(id: number) {
    this.cancelForm.get('subscriptionId').setValue(id);
    this.showCancelModal = true;
  }

  onCancelSubmit() {
    const id = this.cancelForm.get('subscriptionId').value;
    const reasons = this.cancelForm.get('reasons').value.filter(reason => reason.enabled);
    this.updateLoading('subscriptions', id, true);
    if (confirm('Are you sure you want to cancel this subscription?')) {
      this.subscriptionService.cancelSubscription(id, reasons)
        .pipe(map(response => {
          this.showCancelModal = false;
          return response;
        }))
        .subscribe(response => {
            this.updateLoading('subscriptions', id, false);
          }, error => {
            if (typeof error === 'object' && error.error) {
              // this.errorMsg = error.error.message;
            }
          }
        );
    }
  }

  onSkip(id: number) {
    this.updateLoading('subscriptions', id, true);
    this.subscriptionService.skipSubscription(id)
      .subscribe(response => {
        this.updateLoading('subscriptions', id, false);
      }, e => {
        this.updateLoading('subscriptions', id, false);
      });
  }

  onEditSubscriptionAction(subscription: SubscriptionModel) {
    this.showSubscriptionEditModal = true;
    this.subscriptionEditForm.get('subscriptionId').setValue(subscription.id);
    this.subscriptionEditForm.get('isPrepay').setValue(subscription.isPrepay);
    this.subscriptionEditForm.get('plan').setValue(subscription.plan);
    this.subscriptionEditForm.get('recipient').setValue({
      firstName: subscription.recipient.firstName,
      lastName: subscription.recipient.lastName
    });
    this.subscriptionEditForm.get('nextBillingDate').setValue(subscription.nextBillingDate);

    if (subscription.giftMessage) {
      this.subscriptionEditForm.addControl('giftMessage', new FormControl(''));
      this.subscriptionEditForm.get('giftMessage').setValue(subscription.giftMessage);
    } else {
      this.subscriptionEditForm.removeControl('giftMessage');
    }

    this.subscriptionEditAddressForm.setValue({
      shippingAddress: this.address[subscription.address].shippingAddress,
      shippingAddressExtra: this.address[subscription.address].shippingAddressExtra,
      city: this.address[subscription.address].city,
      state: this.address[subscription.address].state,
      stateCode: this.address[subscription.address].stateCode,
      zip: this.address[subscription.address].zip,
      country: this.address[subscription.address].country,
      countryCode: this.address[subscription.address].countryCode,
      line: this.address[subscription.address].line,
      formattedLine: this.address[subscription.address].formattedLine,
      label: this.address[subscription.address].label,
    });
  }

  editSubscription() {
    return this.subscriptionService.editSubscription(this.subscriptionEditForm.value)
      .subscribe((subscriptions: SubscriptionModel) => {
        this.showSubscriptionEditModal = false;
        this.refreshList();
      });
  }

  editSubscriptionAddress() {
    const subscriptionId = this.subscriptionEditForm.get('subscriptionId').value;
    const subscription = this.subscriptions.find(subs => subs.id === subscriptionId);
    const { shippingAddress, shippingAddressExtra, city, stateCode, zip } = this.subscriptionEditAddressForm.value;
    this.subscriptionEditAddressForm.get('line').setValue(shippingAddress);
    this.subscriptionEditAddressForm.get('formattedLine').setValue(shippingAddress);
    this.subscriptionEditAddressForm.get('label').setValue(`${shippingAddress} ${shippingAddressExtra} ${city} ${stateCode} ${zip}`);

    return this.subscriptionService.updateSubscriptionAddress({
      subscription,
      address: this.subscriptionEditAddressForm.value
    })
      .subscribe(data => {
        this.store.dispatch(new fromAddressActions.AddAddressCompleteAction(data.address));
        this.showSubscriptionEditModal = false;
        this.refreshList();
      });
  }

  onCustomerEditAction(customer: Customer) {
    this.showCustomerEditModal = true;
    this.customerEditForm.setValue({
      id: customer.id,
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email
    });
  }

  editCustomer() {
    return this.customerService.editCustomer(this.customerEditForm.get('id').value, this.customerEditForm.value)
      .subscribe((customer: Customer) => {
        this.showCustomerEditModal = false;
        this.refreshList();
      });
  }


  onResetUserPasswordAction(customer: Customer) {
    this.showResetPasswordModal = true;

    this.resetPasswordForm.setValue({
      customerId: customer.id,
      password: '',
    });
  }

  processResetPassword() {
    const { customerId, password } = this.resetPasswordForm.getRawValue();
    this.customerService.resetCustomerPassword(customerId, password).subscribe(() => {
      this.showResetPasswordModal = false;
      this.resetPasswordForm.reset();
    });
  }

  getSortedSubscriptions(subscriptions: SubscriptionModel[]): SubscriptionModel[] {
    const activeSubscriptions = subscriptions
      .filter(subscription => subscription.status === 'ACTIVE')
      .sort((a, b) => isBefore(a.createdAt, b.createdAt) ? 1 : -1);

    const inactiveSubscriptions = subscriptions
      .filter(subscription => subscription.status !== 'ACTIVE')
      .sort((a, b) => isBefore(a.createdAt, b.createdAt) ? 1 : -1);

    return [...activeSubscriptions, ...inactiveSubscriptions];
  }

  /**
   * NOTES STUFF
   */

  saveNewNote(subscription) {
    const { customerId, id } = subscription;
    const message = this.newNoteText.value;

    this.store.dispatch(new fromNotesActions.CreateNoteAction({
      userId: customerId,
      subscriptionId: id,
      message
    }));

    this.newNoteText.reset('');
  }

  deleteNote(note) {
    console.log(note);
  }

  filterNotesBySubscription(subscription) {
    return this.notesIds
      .map(id => this.notes[id])
      .filter(note => note.subscription === subscription.id)
      .reverse();
  }

  /**
   * CHANGE PREFERRED SHIPMENT RATE
   */

  onChangePreferredShipperAction() {
    const { subscriptionId, selectedCarrier, selectedService } = this.preferredShipperForm.value;
    this.subscriptionService.updatePreferredShipper({
      subscriptionId,
      carrier: selectedCarrier,
      service: selectedService
    })
      .subscribe(data => {
        this.refreshList();
        this.showPreferredShipperModal = false;
      });
  }

  onOpenPreferredShipperAction(subscription: SubscriptionModel) {
    this.showPreferredShipperModal = true;
    this.shipmentRatesRequested = true;
    this.preferredShipperForm.get('subscriptionId').setValue(subscription.id);
    console.log(subscription.preferredShipper);
    if (subscription.preferredShipper) {
      this.preferredShipperForm.get('selectedCarrier').setValue(subscription.preferredShipper.carrier);
      this.preferredShipperForm.get('selectedService').setValue(subscription.preferredShipper.service);
    }

    this.subscriptionService
      .fetchSubscriptionsRates(subscription.id)
      .pipe(tap(value => this.shipmentRatesRequested = false))
      .subscribe(
        rates => {
          const normalizedRates = rates.map(rate => new ShipmentRate(rate));
          this.shipmentRates = this.groupRateByCarrierName(normalizedRates);
        },
        e => {
          this.showPreferredShipperModal = false;
          this.alertService.danger({
            alertsCode: 'dashboard-alerts',
            title: `Can't get the rates`,
            message: e.error.message
          });
        }
      );
  }

  onChangeSelectedCarrier(e) {
    const carrier = this.shipmentRates[e.target.value];
    const firstClass = Object.keys(carrier)[0];
    this.preferredShipperForm.get('selectedService').setValue(firstClass);
  }

  groupRateByCarrierName(rates: ShipmentRate[]) {
    return rates.reduce((prev, current) => {
      const carrierName = current.carrier;
      const carrierClass = current.service;
      const prevClass = prev[carrierName];
      return { ...prev, [carrierName]: { ...prevClass, [carrierClass]: current } };
    }, {});
  }

  /**
   * FREE SHIPMENT
   */
  configureCreateShipmentForm() {
    this.freeShipmentForm = new FormGroup({
      subscription: new FormControl('', Validators.required),
      customer: new FormControl('', Validators.required),
      address: new FormGroup({
        shippingAddress: new FormControl('', Validators.required),
        shippingAddressExtra: new FormControl(''),
        city: new FormControl('', Validators.required),
        state: new FormControl('', Validators.required),
        stateCode: new FormControl('', Validators.required),
        zip: new FormControl('', Validators.required),
        country: new FormControl('United States', Validators.required),
        countryCode: new FormControl('USA', Validators.required),
        line: new FormControl(''),
        formattedLine: new FormControl(''),
        label: new FormControl(''),
      })
    });
  }

  onFreeShipmentAction(customerId: string, subscriptionId: number, address: any) {
    this.showFreeShipmentModal = true;
    this.freeShipmentForm.reset();
    this.freeShipmentForm.get('subscription').setValue(subscriptionId);
    this.freeShipmentForm.get('customer').setValue(customerId);
    if (typeof address !== 'undefined') {
      this.freeShipmentForm.get('address').get('shippingAddress').setValue(address.shippingAddress);
      this.freeShipmentForm.get('address').get('shippingAddressExtra').setValue(address.shippingAddressExtra);
      this.freeShipmentForm.get('address').get('city').setValue(address.city);
      this.freeShipmentForm.get('address').get('state').setValue(address.state);
      this.freeShipmentForm.get('address').get('stateCode').setValue(address.stateCode);
      this.freeShipmentForm.get('address').get('zip').setValue(address.zip);
    }
    this.freeShipmentForm.get('address').get('country').setValue('United States');
    this.freeShipmentForm.get('address').get('countryCode').setValue('USA');
  }

  createFreeShipment() {
    const { shippingAddress, shippingAddressExtra, city, stateCode, zip } = this.freeShipmentForm.get('address').value;
    this.freeShipmentForm.get('address').get('line').setValue(shippingAddress);
    this.freeShipmentForm.get('address').get('formattedLine').setValue(shippingAddress);
    this.freeShipmentForm.get('address').get('label')
      .setValue(`${shippingAddress} ${shippingAddressExtra} ${city} ${stateCode} ${zip}`);
    this.shipmentsService.createFreeShipment(this.freeShipmentForm.value)
      .pipe(tap(() => this.showFreeShipmentModal = false))
      .subscribe((shipment) => {
        this.shipments = [
          shipment,
          ...this.shipments
        ];
      }, e => console.log(e));
  }

  /**
   * AUTO RENEW STUFF
   */

  onTurnOffAction(subscriptionId: number, userId: string) {
    this.turnOffForm.get('subscriptionId').setValue(subscriptionId);
    this.turnOffForm.get('userId').setValue(userId);
    this.showTurnOffModal = true;
  }

  onTurnAutoRenewOff() {
    const subscriptionId = this.turnOffForm.get('subscriptionId').value;
    const userId = this.turnOffForm.get('userId').value;
    const reasons = this.turnOffForm.get('reasons').value.filter(reason => reason.enabled);
    this.updateLoading('subscriptions', subscriptionId, true);
    this.subscriptionService.switchAutoRenew({
      autoRenew: false,
      nextBillingDate: '',
      subscriptionId,
      userId,
      reasons
    })
      .subscribe(subscription => {
        this.refreshList();
        this.actionService.fetchActionsByUserId(subscriptionId)
          .subscribe(actions => {
            this.actions = actions;
            this.updateLoading('subscriptions', subscriptionId, false);
            this.showTurnOffModal = false;
          });
      });
  }

  onTurnAutoRenewOn() {
    const { subscriptionId, userId, nextBillingDate } = this.autoRenewOnForm.value;
    if (this.autoRenewOnForm.valid) {
      this.updateLoading('subscriptions', subscriptionId, true);
      this.subscriptionService.switchAutoRenew({
        userId,
        subscriptionId,
        autoRenew: true,
        nextBillingDate: format(new Date(nextBillingDate), 'yyyy-MM-dd'),
      })
        .subscribe(subscription => {
          this.actionService.fetchActionsByUserId(subscriptionId)
            .subscribe(actions => {
              this.actions = actions;
              this.refreshList();
              this.showAutoRenewOnModal = false;
              this.updateLoading('subscriptions', subscriptionId, false);
            });
        });
    }
  }

  onShowAutoRenewOnModal(subscriptionId) {
    const subscription = this.subscriptions.find(item => item.id === subscriptionId);
    this.showAutoRenewOnModal = true;
    this.autoRenewOnForm.setValue({
      subscriptionId,
      userId: subscription.customerId,
      nextBillingDate: format(new Date(), 'MM-dd-yyyy'),
    });
  }

  clearDeclineStatus(subscription: SubscriptionModel) {

    if (!subscription) {
      throw new Error('Should provide a subscription as parameter');
    }

    this.subscriptionService
      .clearDeclineStatus(subscription.id)
      .subscribe(
        (data) => {
          console.log('SUCESS: ', data);
          this.refreshList();
        },
        (error) => {
          console.log('ERROR: ', error);
        },
      );
  }

  onOpenPaymentMethodsModal(customer: Customer) {
    this.selectedCustomer = customer;
    this.showPaymentMethosdModal = true;
  }

  onClosePaymentMethodsModal() {
    this.showPaymentMethosdModal = false;
    this.selectedCustomer = null;
    this.refreshList();
  }

  // utils
  parseCancelledReasons(reasons) {
    return reasons
      .map(r => `${r.name}${r.value ? `: ${r.value}` : ''}`);
  }

  getNumberOfFreeBoxes(customerId): number {
    const customer = this.customers.find(item => item.id === customerId);
    if (!customer) {
      return 0;
    }

    let freeBoxes = 0;
    let referralsToBeRedeemed = {};
    this.referralPromotions.forEach(promotion => {
      let unredeemedReferrals = customer.referrals.filter(referral => !referral.redeemed);

      unredeemedReferrals = unredeemedReferrals.filter(r => {
        return !referralsToBeRedeemed[r.id];
      });

      if (!promotion.default) {
        unredeemedReferrals = unredeemedReferrals
          .filter(r => r.referralPromotion === promotion._id);
      }

      unredeemedReferrals = unredeemedReferrals
        .filter((r, i) => (i < promotion.requiredReferrals) ? true : false);

      if (unredeemedReferrals.length === promotion.requiredReferrals) {
        freeBoxes = freeBoxes + promotion.freeBoxes;
        referralsToBeRedeemed = {
          ...referralsToBeRedeemed,
          ...unredeemedReferrals.reduce(
            (prev, current) => ({ ...prev, [current.id]: current }),
            {}
          ),
        };
        return;
      }
    });
    return freeBoxes;
  }
}

export interface DashboardListItem {
  customer: Customer;
  payments: Payment[];
  subscriptions: SubscriptionModel[];
  shipments: Shipment[];
  actions: Action[];
  referrals: Referral[];
  freeBoxes: number;
}

export interface LoadingSections {
  skip: number | string[];
  cancel: number | string[];
  void: number | string[];
  reship: number | string[];
}
