var Shipment = /** @class */ (function () {
    function Shipment(data) {
        this.loading = false;
        this.id = data._id;
        this.userId = data.user;
        this.subscriptionId = data.subscription;
        this.receiver = data.receiver;
        this.reship = {
            isReship: data.reship.isReship,
            reshipAt: data.reship.reshipAt ? new Date(data.reship.reshipAt) : undefined,
            reshipOf: data.reship.reshipOf,
        };
        this.address = data.address;
        this.shippingLabel = typeof data.shippingLabel !== 'undefined' ? {
            id: data.shippingLabel._id,
            labelUrl: data.shippingLabel.labelUrl
        } : undefined;
        this.tracker = data.tracker ? {
            trackerId: data.tracker._id,
            shipmentId: data.tracker.shipmentId,
            trackingCode: data.tracker.trackingCode,
            trackingUrl: data.tracker.publicUrl,
            status: data.tracker.status,
            status_code: data.tracker.status_code,
            internalStatus: data.tracker.internalStatus,
            refunded: data.tracker.refunded
        } : undefined;
        this.printed = {
            isPrinted: data.printed.isPrinted,
            printedAt: data.printed.printedAt,
            cantPrintUntil: data.printed.cantPrintUntil,
        };
        this.createdAt = new Date(data.createdAt);
        this.updatedAt = new Date(data.updatedAt);
        this.isGift = data.isGift;
        this.selectedBox = data.selectedBox;
        this.cancelled = data.cancelled;
    }
    return Shipment;
}());
export { Shipment };
var ShipmentStats = /** @class */ (function () {
    function ShipmentStats(data) {
        this.shipments = data.shipments;
        this.unprintedShipments = data.unprintedShipments;
        this.shipmentsWithoutLabels = data.shipmentsWithoutLabels;
        this.monthlyStats = data.monthlyStats;
    }
    return ShipmentStats;
}());
export { ShipmentStats };
var ShipmentRate = /** @class */ (function () {
    function ShipmentRate(data) {
        this.id = data.id;
        this.carrier = data.carrier;
        this.carrierId = data.carrier_account_id;
        this.service = data.service;
        this.rate = data.rate;
        this.currency = data.currency;
        this.estDeliveryDays = data.est_delivery_days;
        this.deliveryDateGuaranteed = data.delivery_date_guaranteed;
    }
    Object.defineProperty(ShipmentRate.prototype, "formatedPrice", {
        get: function () {
            return this.currency + " " + this.rate;
        },
        enumerable: true,
        configurable: true
    });
    return ShipmentRate;
}());
export { ShipmentRate };
