import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
// Models
import { Shipment, ShipmentStats } from '@app/models/shipment.model';
import { Address } from '@app/models/address.model';
import { SocketIoService } from "@app/core/services";
var ShipmentService = /** @class */ (function () {
    function ShipmentService(http, ioService) {
        this.http = http;
        this.ioService = ioService;
        this.apiUrl = environment.apiUrl + '/api';
        this.shipmentsState = [];
        this.shipmentsSubject = new BehaviorSubject([]);
    }
    Object.defineProperty(ShipmentService.prototype, "shipments", {
        get: function () {
            return this.shipmentsState.slice();
        },
        set: function (data) {
            this.shipmentsState = data;
            this.shipmentsSubject.next(data);
        },
        enumerable: true,
        configurable: true
    });
    ShipmentService.prototype.getShipmentsState = function () {
        console.log('ShipmentService::getShipmentsState');
        return this.shipmentsSubject;
    };
    ShipmentService.prototype.updateShipmentsState = function (data) {
        console.log('ShipmentService::updateShipmentsState');
        this.shipments = data;
    };
    ShipmentService.prototype.updateShipmentItem = function (shipment) {
        this.shipments = this.shipments.map(function (s) {
            if (s.id === shipment.id) {
                return shipment;
            }
            return s;
        });
    };
    ShipmentService.prototype.fecthShipmentsFromApi = function (params) {
        var _this = this;
        console.log('ShipmentService::fecthShipmentsFromApi');
        var httpParams = new HttpParams();
        if (typeof params.limit === 'number') {
            httpParams = httpParams.append('limit', params.limit.toString());
        }
        if (typeof params.page === 'number') {
            httpParams = httpParams.append('page', params.page.toString());
        }
        return this.http.get(this.apiUrl + "/admin/shipments/", { params: httpParams })
            .pipe(map(function (response) {
            var newShipments = response.data.map(function (shipment) { return new Shipment(shipment); });
            _this.shipments = _this.mergeShipments(_this.shipments, newShipments);
            return newShipments;
        }));
    };
    ShipmentService.prototype.fetchShipmentRates = function (shipmentId) {
        return this.http.get(this.apiUrl + "/shipments/" + shipmentId + "/rates")
            .pipe(map(function (response) { return response.data; }));
    };
    ShipmentService.prototype.updateShipment = function (shipmentId, newShipment) {
        var _this = this;
        return this.http.put(this.apiUrl + "/admin/shipments/" + shipmentId, newShipment)
            .pipe(map(function (response) {
            var newShipment = new Shipment(response);
            _this.shipments = _this.mergeShipments(_this.shipments, [newShipment]);
            return newShipment;
        }));
    };
    ShipmentService.prototype.setShipmentAsPrinted = function (shipmentId) {
        var _this = this;
        var body = {
            user: 'Admin'
        };
        return this.http.put(this.apiUrl + "/admin/shipments/" + shipmentId + "/print", body)
            .pipe(map(function (response) {
            var newShipment = new Shipment(response.data);
            _this.shipments = _this.mergeShipments(_this.shipments, [newShipment]);
            return newShipment;
        }));
    };
    ShipmentService.prototype.fetchShipmentLabelToPrint = function (shipmentId) {
        console.log('ShipmentService::fetchShipmentLabelToPrint');
        return this.http.get(this.apiUrl + "/admin/shipments/" + shipmentId + "/pdf-to-print")
            .pipe(map(function (response) { return response.data; }));
    };
    ShipmentService.prototype.fetchShipmentLabelPrinted = function (shipmentId) {
        console.log('ShipmentService::fetchShipmentLabelPrinted');
        return this.http.get(this.apiUrl + "/admin/shipment/" + shipmentId + "/generated-label")
            .pipe(map(function (response) { return response.data; }));
    };
    ShipmentService.prototype.fetchUnprintedShipmentsPdf = function (options) {
        console.log('ShipmentService::fetchUnprintedShipmentsPdf');
        var httpParams = new HttpParams();
        httpParams = httpParams.append('option', options.option);
        httpParams = httpParams.append('date', options.date.toString());
        httpParams = httpParams.append('quantity', options.quantity.toString());
        if (options.selectedCarrier && options.selectedService) {
            httpParams = httpParams.append('selectedCarrier', options.selectedCarrier.toString());
            httpParams = httpParams.append('selectedService', options.selectedService.toString());
        }
        httpParams = httpParams.append('socketId', this.ioService.socketId);
        httpParams = httpParams.append('selectedBox', options.selectedBox);
        // if (options.customCarrier) {
        //   httpParams = httpParams.append('selectedCarrier', options.selectedCarrier.toString());
        //   httpParams = httpParams.append('selectedService', options.selectedService.toString());
        // }
        return this.http.get(this.apiUrl + "/admin/shipments/unprinted/pdf-to-print", { params: httpParams })
            .pipe(map(function (response) { return response.data; }));
    };
    ShipmentService.prototype.fetchShipmentLabelByTrackingNumbers = function (trackingCodes) {
        return this.http.post(this.apiUrl + "/admin/shipments/pdf-to-print-by-tracking-codes", { trackingCodes: trackingCodes }).pipe(map(function (response) { return response.data; }));
    };
    ShipmentService.prototype.fetchShipmentsByUsers = function (userIds, page, limit) {
        var _this = this;
        if (userIds === void 0) { userIds = []; }
        if (page === void 0) { page = 0; }
        if (limit === void 0) { limit = 25; }
        console.log('ShipmentService::fetchShipmentsByUsers');
        var httpParams = new HttpParams();
        if (typeof limit === 'number') {
            httpParams = httpParams.append('limit', limit.toString());
        }
        if (typeof page === 'number') {
            httpParams = httpParams.append('page', page.toString());
        }
        return this.http.post(this.apiUrl + "/admin/shipments/by-users", { userIds: userIds }, { params: httpParams })
            .pipe(map(function (response) {
            var newShipments = response.data.map(function (shipment) { return new Shipment(shipment); });
            _this.shipments = _this.mergeShipments(_this.shipments, newShipments);
            return newShipments;
        }));
    };
    ShipmentService.prototype.refundShipmentById = function (shipmentId) {
        var _this = this;
        console.log('ShipmentService::refundShipmentById');
        return this.http.put(this.apiUrl + "/admin/shipments/" + shipmentId + "/refund", {})
            .pipe(map(function (response) {
            var newShipments = new Shipment(response.data);
            _this.shipments = _this.mergeShipments(_this.shipments, [newShipments]);
            return newShipments;
        }));
    };
    ShipmentService.prototype.reshipShipmentById = function (data) {
        var _this = this;
        console.log('ShipmentService::reshipShipmentById');
        return this.http.put(this.apiUrl + "/admin/shipments/" + data.shipmentId + "/reship", {
            reasons: data.reasons,
            refundCurrentLabel: data.refundCurrentLabel
        })
            .pipe(map(function (response) {
            var newShipments = response.data.map(function (shipment) { return new Shipment(shipment); });
            _this.shipments = _this.mergeShipments(_this.shipments, newShipments);
            return newShipments;
        }));
    };
    ShipmentService.prototype.createFreeShipment = function (data) {
        var _this = this;
        console.log('ShipmentService::createFreeShipment');
        return this.http.post(this.apiUrl + "/admin/shipments/", data)
            .pipe(map(function (response) {
            var newShipments = new Shipment(response.data);
            _this.shipments = _this.mergeShipments(_this.shipments, [newShipments]);
            return newShipments;
        }));
    };
    ShipmentService.prototype.fetchCustomShipmentsFromApi = function (page) {
        if (page === void 0) { page = 0; }
        var httpParams = new HttpParams().append('page', page.toString());
        return this.http.get(this.apiUrl + "/admin/custom-shipments/", {
            params: httpParams
        })
            .pipe(map(function (response) { return response.data; }));
    };
    ShipmentService.prototype.createCustomShipment = function (shipmentsData) {
        return this.http.post(this.apiUrl + "/admin/custom-shipments/", shipmentsData)
            .pipe(map(function (response) { return response.data; }));
    };
    ShipmentService.prototype.createShipment = function (shipmentsData) {
        return this.http.post(this.apiUrl + "/shipments/rates/", shipmentsData)
            .pipe(map(function (response) { return response; }));
    };
    ShipmentService.prototype.buyShipment = function (shipmentsData) {
        return this.http.post(this.apiUrl + "/shipments/custom-buy/", shipmentsData)
            .pipe(map(function (response) { return response; }));
    };
    ShipmentService.prototype.duplicateCustomShipment = function (id) {
        return this.http.post(this.apiUrl + "/admin/custom-shipments/" + id + "/duplicate", {})
            .pipe(map(function (response) { return response.data; }));
    };
    ShipmentService.prototype.fetchShipmentsStatsFromApi = function () {
        console.log('ShipmentService::fetchShipmentsStatsFromApi');
        return this.http.get(this.apiUrl + "/admin/shipments/stats")
            .pipe(map(function (response) { return new ShipmentStats(response.data); }));
    };
    ShipmentService.prototype.fetchShipmentsStatsByTypeFromApi = function (selectedDate) {
        console.log('ShipmentService::fetchShipmentsStatsFromApi');
        return this.http.get(this.apiUrl + "/admin/shipments/stats-type", { params: { date: selectedDate } })
            .pipe(map(function (response) { return response.data; }));
    };
    ShipmentService.prototype.buyShipmentLabel = function (subscriptionId, shipmentId, rate) {
        var _this = this;
        if (rate === void 0) { rate = { carrier: 'USPS', service: 'First' }; }
        return this.http.put(this.apiUrl + "/admin/shipments/" + shipmentId + "/buy-label", { subscriptionId: subscriptionId, shipmentId: shipmentId, rate: rate })
            .pipe(map(function (response) {
            var newShipment = new Shipment(response.data);
            _this.shipments = _this.mergeShipments(_this.shipments, [newShipment]);
            return newShipment;
        }));
    };
    ShipmentService.prototype.changeShipmentPrintingDate = function (shipmentId, cantPrintUntil) {
        var _this = this;
        console.log('ShipmentService::changeShipmentPrintingDate');
        return this.http.put(this.apiUrl + "/admin/shipments/" + shipmentId + "/printing-date", {
            cantPrintUntil: cantPrintUntil
        })
            .pipe(map(function (response) {
            var newShipment = new Shipment(response.data);
            _this.shipments = _this.mergeShipments(_this.shipments, [newShipment]);
            return newShipment;
        }));
    };
    ShipmentService.prototype.getTodayShipmentManifest = function () {
        return this.http.get(this.apiUrl + "/admin/shipments/manifest/today")
            .pipe(map(function (response) { return response.data; }));
    };
    // UTILS
    ShipmentService.prototype.mergeShipments = function (oldShipments, newShipments) {
        if (oldShipments.length === 0) {
            return newShipments;
        }
        return newShipments.reduce(function (allShipments, newShipment) {
            var temp = 'length' in allShipments ? allShipments : [];
            var shipmentIndex = temp.findIndex(function (shipment) { return shipment.id === newShipment.id; });
            if (shipmentIndex === -1) {
                return temp.concat([newShipment]);
            }
            else {
                return temp.slice(0, shipmentIndex).concat([
                    newShipment
                ], temp.slice(shipmentIndex + 1));
            }
        }, oldShipments);
    };
    ShipmentService.prototype.updateShipmentAddress = function (shipmentId, address) {
        return this.http
            .put(this.apiUrl + "/admin/shipments/" + shipmentId + "/address", { address: address })
            .pipe(map(function (result) {
            return {
                address: new Address(result.address),
                shipment: new Shipment(result.shipment),
            };
        }));
    };
    return ShipmentService;
}());
export { ShipmentService };
