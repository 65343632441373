import { HttpClient } from '@angular/common/http';
// rxjs
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';
// models
import { Address } from './../models/address.model';
import { map } from 'rxjs/operators';
var AddressService = /** @class */ (function () {
    function AddressService(httpClient) {
        this.httpClient = httpClient;
        this.apiUrl = environment.apiUrl + '/api';
        this.addressState = [];
        this.addressSubject = new BehaviorSubject([]);
    }
    Object.defineProperty(AddressService.prototype, "address", {
        get: function () {
            return this.addressState.slice();
        },
        set: function (data) {
            this.addressState = data;
            this.addressSubject.next(data);
        },
        enumerable: true,
        configurable: true
    });
    AddressService.prototype.getAddressState = function () {
        console.log('AddressService::getAddressState');
        return this.addressSubject;
    };
    AddressService.prototype.updateAddressState = function (data) {
        console.log('AddressService::updateAddressState');
        this.address = data;
    };
    AddressService.prototype.addAddressItem = function (address) {
        this.address = this.address.concat([address]);
    };
    AddressService.prototype.getCustomerAddresses = function (_a) {
        var user = _a.user;
        return this.httpClient
            .get(this.apiUrl + "/users/" + user + "/address")
            .pipe(map(function (result) { return result.addresses.map(function (address) { return new Address(address); }); }));
    };
    AddressService.prototype.getAddressesByUserIds = function (_a) {
        var ids = _a.ids;
        return this.httpClient
            .post(this.apiUrl + "/admin/address/by-customer-ids", { ids: ids })
            .pipe(map(function (result) { return result.addresses.map(function (address) { return new Address(address); }); }));
    };
    AddressService.prototype.updateAddress = function (address) {
        return this.httpClient
            .post(this.apiUrl + "/admin/address/" + address.id, { address: address })
            .pipe(map(function (result) { return new Address(result.address); }));
    };
    return AddressService;
}());
export { AddressService };
