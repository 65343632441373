import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { pluck } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BuildsService = /** @class */ (function () {
    function BuildsService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl + '/api';
    }
    BuildsService.prototype.getTodaysBuilds = function () {
        return this.http.get(this.apiUrl + "/builds/today")
            .pipe(pluck('data'));
    };
    BuildsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BuildsService_Factory() { return new BuildsService(i0.ɵɵinject(i1.HttpClient)); }, token: BuildsService, providedIn: "root" });
    return BuildsService;
}());
export { BuildsService };
