import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
// Models
import { Customer } from '@app/models/customer.model';
var CustomerService = /** @class */ (function () {
    function CustomerService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl + '/api';
        this.customersState = [];
        this.customersState$ = new BehaviorSubject([]);
    }
    CustomerService.prototype.getCustomersState = function () {
        console.log('CustomerService::getCustomersState');
        return this.customersState$;
    };
    CustomerService.prototype.updateCustomersState = function (data) {
        console.log('CustomerService::updateCustomersState');
        this.customers = data;
    };
    Object.defineProperty(CustomerService.prototype, "customers", {
        get: function () {
            return this.customersState.slice();
        },
        set: function (data) {
            this.customersState = data;
            this.customersState$.next(data);
        },
        enumerable: true,
        configurable: true
    });
    CustomerService.prototype.fetchCustomersByIdsFromApi = function (params) {
        var _this = this;
        console.log('CustomerService::fetchCustomersByIdsFromApi');
        var httpParams = new HttpParams();
        if (typeof params.limit === 'number') {
            httpParams = httpParams.append('limit', params.limit.toString());
        }
        if (typeof params.page === 'number') {
            httpParams = httpParams.append('page', params.page.toString());
        }
        return this.http.post(this.apiUrl + "/admin/customers/by-ids", { ids: params.ids }, { params: httpParams })
            .pipe(map(function (response) {
            var newCustomers = response.data.map(function (customer) { return new Customer(customer); });
            _this.customers = _this.mergeCustomers(_this.customers, newCustomers);
            return newCustomers;
        }));
    };
    CustomerService.prototype.fetchCustomerByEmail = function (customerEmail) {
        var _this = this;
        console.log('CustomerService::fetchCustomerByEmail');
        return this.http.post(this.apiUrl + "/admin/customers/by-email", { email: customerEmail })
            .pipe(map(function (response) {
            var newCustomers = response.data.map(function (customer) { return new Customer(customer); });
            _this.customers = _this.mergeCustomers(_this.customers, newCustomers);
            return newCustomers;
        }));
    };
    CustomerService.prototype.fetchCustomersByNameAndEmailFromApi = function (text, page, limit) {
        var _this = this;
        if (page === void 0) { page = 0; }
        if (limit === void 0) { limit = 25; }
        console.log('CustomerService::fetchCustomersBynameAnAddressFromApi');
        var httpParams = new HttpParams();
        if (typeof limit === 'number') {
            httpParams = httpParams.append('limit', limit.toString());
        }
        if (typeof page === 'number') {
            httpParams = httpParams.append('page', page.toString());
        }
        return this.http.post(this.apiUrl + "/admin/customers/by-name-and-email", { text: text }, { params: httpParams })
            .pipe(map(function (response) {
            var newCustomers = response.data.map(function (customer) { return new Customer(customer); });
            _this.customers = _this.mergeCustomers(_this.customers, newCustomers);
            return newCustomers;
        }));
    };
    CustomerService.prototype.editCustomer = function (id, data) {
        var _this = this;
        return this.http.request('put', this.apiUrl + "/admin/customers/" + data.id, {
            body: data
        })
            .pipe(map(function (response) {
            var updatedSubscription = new Customer(response.data);
            _this.customers = _this.mergeCustomers(_this.customers, [updatedSubscription]);
            return updatedSubscription;
        }));
    };
    CustomerService.prototype.resetCustomerPassword = function (id, password) {
        return this.http.put(this.apiUrl + "/admin/customers/" + id + "/reset-password", { password: password })
            .pipe(map(function (response) {
            return response.message;
        }));
    };
    CustomerService.prototype.addPaymentMethod = function (_a) {
        var _this = this;
        var customerId = _a.customerId, nonce = _a.nonce, cardholderName = _a.cardholderName;
        return this.http
            .post(this.apiUrl + "/users/" + customerId + "/add-payment-method", {
            nonce: nonce,
            cardholderName: cardholderName,
        })
            .pipe(map(function (response) {
            var oldCustomer = _this.customers.find(function (customer) { return customer.id === customerId; });
            var newCustomer = new Customer(tslib_1.__assign({}, oldCustomer, { _id: oldCustomer.id, paymentMethods: response.paymentMethods }));
            _this.customers = _this.mergeCustomers(_this.customers, [newCustomer]);
            return newCustomer;
        }));
    };
    CustomerService.prototype.updatePaymentMethod = function (_a) {
        var _this = this;
        var customerId = _a.customerId, paymentMethodId = _a.paymentMethodId, nonce = _a.nonce, cardholderName = _a.cardholderName, _b = _a.paymentProcessor, paymentProcessor = _b === void 0 ? 'braintree' : _b;
        return this.http
            .put(this.apiUrl + "/users/" + customerId + "/update-payment-method", {
            paymentMethodId: paymentMethodId,
            nonce: nonce,
            cardholderName: cardholderName,
            paymentProcessor: paymentProcessor
        })
            .pipe(map(function (response) {
            var oldCustomer = _this.customers.find(function (customer) { return customer.id === customerId; });
            var newCustomer = new Customer(tslib_1.__assign({}, oldCustomer, { _id: oldCustomer.id, paymentMethods: [response.paymentMethod] }));
            _this.customers = _this.mergeCustomers(_this.customers, [newCustomer]);
            return newCustomer;
        }));
    };
    CustomerService.prototype.deletePaymentMethod = function (_a) {
        var _this = this;
        var customerId = _a.customerId, paymentMethodId = _a.paymentMethodId;
        return this.http
            .delete(this.apiUrl + "/users/" + customerId + "/payment-method/" + paymentMethodId)
            .pipe(map(function (response) {
            var oldCustomer = _this.customers.find(function (customer) { return customer.id === customerId; });
            var newCustomer = new Customer(tslib_1.__assign({}, oldCustomer, { _id: oldCustomer.id, paymentMethods: response.paymentMethods }));
            _this.customers = _this.mergeCustomers(_this.customers, [newCustomer]);
            return newCustomer;
        }));
    };
    CustomerService.prototype.defaultPaymentMethod = function (_a) {
        var _this = this;
        var customerId = _a.customerId, paymentMethodId = _a.paymentMethodId;
        return this.http
            .put(this.apiUrl + "/users/" + customerId + "/payment-method/" + paymentMethodId + "/default", {})
            .pipe(map(function (response) {
            var oldCustomer = _this.customers.find(function (customer) { return customer.id === customerId; });
            var newCustomer = new Customer(tslib_1.__assign({}, oldCustomer, { _id: oldCustomer.id, paymentMethods: response.paymentMethods }));
            _this.customers = _this.mergeCustomers(_this.customers, [newCustomer]);
            return newCustomer;
        }));
    };
    // UTILS
    CustomerService.prototype.mergeCustomers = function (oldCustomers, newCustomers) {
        if (oldCustomers.length === 0) {
            return newCustomers;
        }
        return newCustomers.reduce(function (allCustomers, newCustomer) {
            var temp = 'length' in allCustomers ? allCustomers : [];
            var customerIndex = temp.findIndex(function (customer) { return customer.id === newCustomer.id; });
            if (customerIndex === -1) {
                return temp.concat([newCustomer]);
            }
            else {
                return temp.slice(0, customerIndex).concat([
                    newCustomer
                ], temp.slice(customerIndex + 1));
            }
        }, oldCustomers);
    };
    return CustomerService;
}());
export { CustomerService };
