<div class="row">
  <div class="col-sm-2">
    <div class="row">
      <button (click)="buyShipment.next(true)" *ngIf="!shipment?.shippingLabel?.labelUrl" [disabled]="buyingShipment"
              class="full-width dsh-btn dsh-btn--white" type="button">
        Buy label
      </button>
      <button (click)="printLabel.next(true)" *ngIf="shipment?.tracker?.refunded !== true" [disabled]="printingLabel"
              class="full-width dsh-btn dsh-btn--white" type="button">
        Print
      </button>
      <button (click)="voidLabel.next(true)"
              *ngIf="!shipment?.tracker || !shipment?.tracker?.refunded &&
              (!shipment?.tracker?.status_code || ['unknown', 'pre_transit'].includes(shipment?.tracker?.status_code))"
              [disabled]="inVoid"
              class="full-width dsh-btn dsh-btn--white" type="button">
        Void
      </button>
      <button (click)="reshipLabel.next(true)" [disabled]="reshiping" class="full-width dsh-btn dsh-btn--white"
              type="button">
        Reship
      </button>
      <button [disabled]="cancellingLabel || !!shipment.cancelled"
              (click)="cancelLabel()"
               type="button" class="full-width dsh-btn dsh-btn--white">
        <ng-container *ngIf="!cancellingLabel">
          <ng-container *ngIf="!shipment.cancelled">Cancel label</ng-container> <ng-container *ngIf="!!shipment.cancelled">Label cancelled</ng-container>
        </ng-container>
        <ng-container *ngIf="cancellingLabel">
          Loading...
        </ng-container>

      </button>
      <button *ngIf="!shipment?.printed?.isPrinted"
              [disabled]="printingDate"
              (click)="printDate.next(true)" type="button" class="full-width dsh-btn dsh-btn--white">
        Print Date
      </button>
      <button (click)="addressChange.next(true)"
              *ngIf="!shipment?.printed?.isPrinted"
              [disabled]="printingDate" class="full-width dsh-btn dsh-btn--white" type="button">
        Change Address
      </button>
      <button *ngIf="!shipment?.printed?.isPrinted"
              [disabled]="printingDate"
              (click)="receiverChange.next(true)" type="button" class="full-width dsh-btn dsh-btn--white">
        Change Receiver Name
      </button>
    </div>
  </div>
  <div class="col-sm-10">
    <p class="dsh-subs__text">
      Created at: {{ shipment.createdAt | date: 'shortDate' }} {{ shipment.reship.isReship ? '(R)' : '' }}</p>
    <p class="dsh-subs__text"><strong>{{ shipment.selectedBox && shipment.selectedBox.name }}</strong></p>
    <p class="dsh-subs__text">{{ shipment.tracker?.status }}</p>
    <p class="dsh-subs__text">{{ shipment.tracker?.trackingCode }}</p>
    <p class="dsh-subs__text">{{ shipment.tracker?.internalStatus }}</p>

    <a *ngIf="shipment.tracker?.trackingUrl" [href]="shipment.tracker?.trackingUrl" class="dsh-subs__text"
       target="_blank">
      Tracker
    </a>
    -
    <a (click)="getLabel.next(shipment.id)" *ngIf="shipment.tracker?.trackingUrl"
       class="dsh-subs__text pointer pointer--underlined">
      Label
    </a>
    <br/>
    <br/>
    <p class="dsh-subs__text">{{ shipment.receiver }}</p>
    <p *ngIf="giftItMessage !== undefined" class="dsh-subs__text">{{ giftItMessage }}</p>
    <p class="dsh-subs__text">Refunded: {{ shipment.tracker?.refunded || 'false'}}</p>
    <p class="dsh-subs__text">Updated on: {{ shipment.updatedAt | date: 'shortDate'}}</p>
    <p class="dsh-subs__text">Will ship: {{ shipment.printed?.cantPrintUntil | date: 'shortDate'}}</p>
    <p *ngIf="shipment.printed.isPrinted" class="dsh-subs__text">
      Printed on: {{ shipment.printed.printedAt | date: 'shortDate'}}
    </p>
    <!-- <p class="dsh-subs__text">3744 Cedar Ave Long Beach, CA 90807</p> -->
  </div>
</div>
