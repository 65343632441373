import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as ReshipData from '@config/reship.json';

import { environment } from '@env/environment';
// rxjs
import { mergeMap, take, tap } from 'rxjs/operators';
// services
import { AddressService, ShipmentService, SubscriptionService } from '@app/services';
import { AlertService } from '@app/shared/components/alerts/alert.service';
// models
import { Shipment, ShipmentRate } from '@app/models/shipment.model';
import { SubscriptionGiftedShipments } from '@app/models/subscription.model';
import { Address } from '@app/models/address.model';

import * as moment from 'moment';
import * as fromAddressActions from '@app/store/actions/address.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-customer-shipments',
  templateUrl: './customer-shipments.component.html',
  styleUrls: ['./../../dashboard.component.scss', './customer-shipments.component.scss']
})
export class CustomerShipmentsComponent implements OnInit {
  reshipData = (ReshipData as any).default;
  serverUrl: string = environment.apiUrl;

  @Input() subscriptionId: number;
  @Input() subscriptionAddress: Address;
  @Input() customerId: string;
  @Input() shipments: Shipment[];
  @Input() giftedShipments: SubscriptionGiftedShipments[] = [];

  buyShipmentForm: FormGroup;
  showBuyShipmentModal = false;
  shipmentRates: any;
  shipmentRatesRequested = false;

  reshipReasonForm: any;
  showReshipReasonForm = false;

  loadingShipments: string[] = [];

  changePrintingDateForm: FormGroup;
  showChangePrintingDateModal = false;

  shipmentEditAddressForm: FormGroup;
  showChangeShipmentAddress = false;
  activeShipment: Shipment;
  
  shipmentReceiverForm: FormGroup;
  showChangeShipmentReceiver = false;

  constructor(
    private shipmentService: ShipmentService,
    private subscriptionService: SubscriptionService,
    private alertService: AlertService,
    private addressService: AddressService,
    private store: Store<any>,
  ) {
  }

  configureSubscriptionAddressEditForm() {
    this.shipmentEditAddressForm = new FormGroup({
      shippingAddress: new FormControl('', Validators.required),
      shippingAddressExtra: new FormControl(''),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      stateCode: new FormControl('', Validators.required),
      zip: new FormControl('', Validators.required),
      country: new FormControl('United States', Validators.required),
      countryCode: new FormControl('USA', Validators.required),
      line: new FormControl('', Validators.required),
      formattedLine: new FormControl('', Validators.required),
      label: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.configureBuyShipmentForm();
    this.configureReshipForm();
    this.configurePrintingDateForm();
    this.configureSubscriptionAddressEditForm();
    this.configureShipmentReceiverEditForm();
  }

  getGiftItMessage(shipment: Shipment): string | undefined {
    const index = this.giftedShipments.findIndex(gs => gs.shipment === shipment.id);
    if (index !== -1) {
      return this.giftedShipments[index].message;
    } else {
      return undefined;
    }
  }

  configureBuyShipmentForm() {
    this.buyShipmentForm = new FormGroup({
      subscription: new FormControl('', Validators.required),
      shipment: new FormControl('', Validators.required),
      selectedCarrier: new FormControl('', Validators.required),
      selectedService: new FormControl('', Validators.required),
    });
  }

  configureShipmentReceiverEditForm() {
    this.shipmentReceiverForm = new FormGroup({
      name: new FormControl('', Validators.required),
    });
  }

  configurePrintingDateForm() {
    this.changePrintingDateForm = new FormGroup({
      cantPrintUntil: new FormControl('', Validators.required),
      shipmentId: new FormControl('', Validators.required),
    });
  }

  onBuyShipmentAction(shipment: Shipment) {
    this.showBuyShipmentModal = true;
    this.shipmentRatesRequested = true;
    this.buyShipmentForm.reset();
    this.buyShipmentForm.get('subscription').setValue(shipment.subscriptionId);
    this.buyShipmentForm.get('shipment').setValue(shipment.id);
    this.shipmentService.fetchShipmentRates(shipment.id)
      .pipe(tap(results => {
        this.shipmentRatesRequested = false;
      }))
      .subscribe(rates => {
        const normalizedRates = rates.map(rate => new ShipmentRate(rate));
        this.shipmentRates = this.groupRateByCarrierName(normalizedRates);

        if (!this.buyShipmentForm.get('selectedCarrier').value) {
          this.buyShipmentForm.get('selectedCarrier').setValue('USPS');
          this.buyShipmentForm.get('selectedService').setValue('First');
        }

      }, e => {
        this.showBuyShipmentModal = false;
        this.alertService.danger({
          alertsCode: 'dashboard-alerts',
          title: `Can't get the rates`,
          message: e.error.message
        });
      });
  }

  groupRateByCarrierName(rates: ShipmentRate[]) {
    return rates.reduce((prev, current) => {
      const carrierName = current.carrier;
      const carrierClass = current.service;
      const prevClass = prev[carrierName];
      return { ...prev, [carrierName]: { ...prevClass, [carrierClass]: current } };
    }, {});
  }

  onChangeSelectedCarrier(e) {
    const carrier = this.shipmentRates[e.target.value];
    const firstClass = Object.keys(carrier)[0];
    this.buyShipmentForm.get('selectedService').setValue(firstClass);
  }

  buyShipment() {
    const { subscription, shipment, selectedCarrier, selectedService } = this.buyShipmentForm.value;
    this.loadingShipment(shipment, true);
    this.shipmentService.buyShipmentLabel(subscription, shipment, {
      carrier: selectedCarrier,
      service: selectedService,
    })
      .pipe(tap(() => {
        this.showBuyShipmentModal = false;
        this.loadingShipment(shipment, false);
      }))
      .subscribe(updatedShipment => {
        const index = this.shipments.findIndex(s => s.id === updatedShipment.id);
        this.shipments = [
          ...this.shipments.slice(0, index),
          updatedShipment,
          ...this.shipments.slice(index + 1)
        ];
      }, e => {
        this.alertService.danger({
          alertsCode: 'dashboard-alerts',
          title: `Can't Buy the label.`,
          message: e.error.error.message
        });
      });
  }

  onGetLabel(shipmentId: string): void {
    this.loadingShipment(shipmentId, true);
    this.shipmentService.fetchShipmentLabelPrinted(shipmentId)
      .subscribe(pdfPath => {
        this.loadingShipment(shipmentId, false);
        window.open(this.serverUrl + pdfPath);
      }, ({ error }) => {
        this.loadingShipment(shipmentId, false);
        this.alertService.danger({
          alertsCode: 'dashboard-alerts',
          title: `Can't get the label`,
          message: error.message ? error.message : JSON.stringify(error),
          timeout: 10000
        });
      });
  }

  onPrintLabel(shipmentId: string) {
    this.loadingShipment(shipmentId, true);
    this.shipmentService.fetchShipmentLabelToPrint(shipmentId)
      .pipe(
        mergeMap((pdfPath: any) => {
          window.open(this.serverUrl + pdfPath);
          return this.shipmentService.setShipmentAsPrinted(shipmentId);
        }),
        tap(() => this.loadingShipment(shipmentId, false))
      )
      .subscribe((shipment) => {
        const index = this.shipments.findIndex(s => s.id === shipment.id);
        this.shipments = [
          ...this.shipments.slice(0, index),
          shipment,
          ...this.shipments.slice(index + 1)
        ];
      }, ({ error }) => {
        this.alertService.danger({
          alertsCode: 'dashboard-alerts',
          title: `Can't print the label`,
          message: error.message ? error.message : JSON.stringify(error),
          timeout: 10000
        });
      });
  }

  onVoidPressed(shipmentId: string) {
    this.loadingShipment(shipmentId, true);
    this.shipmentService.refundShipmentById(shipmentId)
      .pipe(tap(() => this.loadingShipment(shipmentId, false)))
      .subscribe(shipment => {
        const index = this.shipments.findIndex(s => s.id === shipment.id);
        this.shipments = [
          ...this.shipments.slice(0, index),
          shipment,
          ...this.shipments.slice(index + 1)
        ];
      }, e => alert(JSON.stringify(e.error)));
  }

  configureReshipForm() {
    this.reshipReasonForm = new FormGroup({
      shipmentId: new FormControl(null, Validators.required),
      reasons: new FormArray(
        this.reshipData.reshipReasons && this.reshipData.reshipReasons.length > 0
          ? this.reshipData.reshipReasons.map(reason => new FormGroup({
            value: new FormControl(reason.value),
            enabled: new FormControl(false),
            name: new FormControl(reason.name)
          }))
          : [new FormGroup({
            value: new FormControl(''),
            enabled: new FormControl(false),
            name: new FormControl('Other')
          })],
      )
    });
  }

  onReshipAction(shipmentId: string, refund: boolean) {
    this.reshipReasonForm.get('shipmentId').setValue(shipmentId);
    (<FormArray>this.reshipReasonForm.get('reasons')).controls.forEach((r: FormGroup) => r.get('enabled').setValue(false));
    this.showReshipReasonForm = true;
  }

  onReshipPressed() {
    const { shipmentId, reasons } = this.reshipReasonForm.value;
    this.loadingShipment(shipmentId, true);
    this.shipmentService.reshipShipmentById({ shipmentId, reasons, refundCurrentLabel: false })
      .pipe(tap(() => {
        this.showReshipReasonForm = false;
        this.loadingShipment(shipmentId, false);
      }))
      .subscribe(shipments => {
        shipments.forEach(shipment => {
          const index = this.shipments.findIndex(s => s.id === shipment.id);
          this.shipments = shipment.reship.isReship ? [
            shipment,
            ...this.shipments
          ] : [
            ...this.shipments.slice(0, index),
            shipment,
            ...this.shipments.slice(index + 1)
          ];
        });
      }, e => alert(JSON.stringify(e.error)));
  }

  // utils
  isLoading(shipmentId: string): boolean {
    return this.loadingShipments.findIndex(id => id === shipmentId) !== -1;
  }

  loadingShipment(shipmentId: string, bool: boolean): void {
    const index = this.loadingShipments.findIndex(id => id === shipmentId);
    if (bool && index === -1) {
      this.loadingShipments = [
        shipmentId,
        ...this.loadingShipments
      ];
    } else if (!bool && index !== -1) {
      this.loadingShipments = [
        ...this.loadingShipments.slice(0, index),
        ...this.loadingShipments.slice(index + 1)
      ];
    }
  }

  byKeys(entities): string[] {
    return Object.keys(entities);
  }

  onChangeAddress(shipment: Shipment) {
    this.addressService.getAddressState()
      .pipe(take(1))
      .subscribe((address) => {
        const currentAddress = address.find(a => a.id === shipment.address);
        this.shipmentEditAddressForm.patchValue({
          ...currentAddress
        });
        this.activeShipment = shipment;
        this.showChangeShipmentAddress = true;
      });
  }

  onReceiverChange(shipment: Shipment) {
    this.activeShipment = shipment;
    this.shipmentReceiverForm.get('name').setValue(shipment.receiver);
    this.showChangeShipmentReceiver = true;
  }

  updateShipmentReceiver() {
    const receiver = this.shipmentReceiverForm.get('name').value;
    if(receiver === this.activeShipment.receiver) {
      this.showChangeShipmentReceiver = false;
      return;
    }
    this.shipmentService.updateShipment(this.activeShipment.id, { receiver }).subscribe((data) => {
      this.activeShipment.receiver = data.receiver;
      this.showChangeShipmentReceiver = false;
    })
  }

  updateShipmentAddress() {
    const { shippingAddress, shippingAddressExtra, city, stateCode, zip } = this.shipmentEditAddressForm.value;
    this.shipmentEditAddressForm.get('line').setValue(shippingAddress);
    this.shipmentEditAddressForm.get('formattedLine').setValue(shippingAddress);
    this.shipmentEditAddressForm.get('label').setValue(`${shippingAddress} ${shippingAddressExtra} ${city} ${stateCode} ${zip}`);

    return this.shipmentService.updateShipmentAddress(this.activeShipment.id, this.shipmentEditAddressForm.value)
      .subscribe(data => {
        this.store.dispatch(new fromAddressActions.AddAddressCompleteAction(data.address));
        this.addressService.addAddressItem(data.address);
        this.shipmentService.updateShipmentItem(data.shipment);
        this.showChangeShipmentAddress = false;
        this.activeShipment = undefined;
      });
  }

  onChangePrintDateAction(shipment: Shipment) {
    this.changePrintingDateForm.get('shipmentId').setValue(shipment.id);
    const cantPrintUntilDate = moment(shipment.printed.cantPrintUntil).format('YYYY-MM-DD');
    this.changePrintingDateForm.get('cantPrintUntil')
      .setValue(cantPrintUntilDate);
    this.showChangePrintingDateModal = true;
  }

  changePrintingDate() {
    const { cantPrintUntil, shipmentId } = this.changePrintingDateForm.value;
    this.loadingShipment(shipmentId, true);
    this.shipmentService.changeShipmentPrintingDate(shipmentId, cantPrintUntil)
      .pipe(tap(() => {
        this.showChangePrintingDateModal = false;
        this.loadingShipment(shipmentId, false);
      }))
      .subscribe(updatedShipment => {
        const index = this.shipments.findIndex(s => s.id === updatedShipment.id);
        this.shipments = [
          ...this.shipments.slice(0, index),
          updatedShipment,
          ...this.shipments.slice(index + 1)
        ];
      }, e => {
        this.showChangePrintingDateModal = false;
        this.loadingShipment(shipmentId, false);
        this.alertService.danger({
          alertsCode: 'dashboard-alerts',
          title: `Can't update the printing date.`,
          message: e.error.error.message
        });
      });
  }
}
