import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { AlertModel } from './alert.model';
var AlertService = /** @class */ (function () {
    function AlertService() {
        this.alertsStore = [];
        this.alertsStore$ = new BehaviorSubject([]);
    }
    Object.defineProperty(AlertService.prototype, "alerts", {
        get: function () {
            return this.alertsStore.slice();
        },
        set: function (alerts) {
            this.alertsStore = alerts;
            this.alertsStore$.next(alerts);
        },
        enumerable: true,
        configurable: true
    });
    AlertService.prototype.createNewAlert = function (alert) {
        var _this = this;
        this.alerts = this.alertsStore.concat([alert]);
        if (alert.timeout) {
            setTimeout(function () {
                var index = _this.alertsStore.findIndex(function (a) { return a.id === alert.id; });
                _this.alerts = _this.alertsStore.slice(0, index).concat(_this.alertsStore.slice(index + 1));
            }, alert.timeout);
        }
    };
    AlertService.prototype.removeAlert = function (alertId) {
        this.alerts = this.alerts.filter(function (a) { return a.id !== alertId; });
    };
    AlertService.prototype.success = function (data) {
        this.createNewAlert(new AlertModel(tslib_1.__assign({ type: 'success' }, data)));
    };
    AlertService.prototype.danger = function (data) {
        this.createNewAlert(new AlertModel(tslib_1.__assign({ type: 'danger' }, data)));
    };
    return AlertService;
}());
export { AlertService };
