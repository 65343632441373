// rxjs
import { BehaviorSubject } from 'rxjs';
var PaymentService = /** @class */ (function () {
    function PaymentService() {
        this.paymentsState = [];
        this.paymentsState$ = new BehaviorSubject([]);
    }
    Object.defineProperty(PaymentService.prototype, "payments", {
        get: function () {
            return this.paymentsState.slice();
        },
        set: function (data) {
            this.paymentsState = data;
            this.paymentsState$.next(data);
        },
        enumerable: true,
        configurable: true
    });
    PaymentService.prototype.getPaymentsState = function () {
        console.log('PaymentService::getPaymentsState');
        return this.paymentsState$;
    };
    PaymentService.prototype.updatePaymentsState = function (data) {
        console.log('PaymentService::updatePaymentsState');
        this.payments = data;
    };
    return PaymentService;
}());
export { PaymentService };
