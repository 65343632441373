import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// models
import { Shipment } from '@app/models/shipment.model';
import { Subscription as SubscriptionModel } from '@app/models/subscription.model';
import { ShipmentService } from "@app/services";

@Component({
  selector: 'app-shipment-item',
  templateUrl: './shipment-item.component.html',
  styleUrls: ['./../../dashboard.component.scss', './shipment-item.component.scss']
})
export class ShipmentItemComponent implements OnInit {

  @Output() printDate: EventEmitter<boolean> = new EventEmitter();

  @Input('shipment') shipment: Shipment;
  @Input('subscription') subscription: SubscriptionModel;
  @Input('giftItMessage') giftItMessage: string;


  @Output('buyShipment') buyShipment: EventEmitter<boolean> = new EventEmitter();
  @Input('buyingShipment') buyingShipment = false;

  @Output('printLabel') printLabel: EventEmitter<boolean> = new EventEmitter();
  @Input('printingLabel') printingLabel = false;

  @Output() getLabel: EventEmitter<string> = new EventEmitter();
  @Input() gettingLabel = false;

  @Output('voidLabel') voidLabel: EventEmitter<boolean> = new EventEmitter();
  @Input('inVoid') inVoid = false;

  @Output('reshipLabel') reshipLabel: EventEmitter<boolean> = new EventEmitter();
  @Input('reshiping') reshiping = false;
  @Input() printingDate = false;


  @Output() addressChange: EventEmitter<boolean> = new EventEmitter();
  @Output() receiverChange: EventEmitter<boolean> = new EventEmitter();

  cancellingLabel = false;

  constructor(
    private shipmentService: ShipmentService,
  ) {}

  ngOnInit() {
  }


  cancelLabel() {
    this.cancellingLabel = true;
    this.shipmentService.updateShipment(this.shipment.id, { cancelled: true })
      .subscribe(shipment => {
        this.cancellingLabel = false;
        this.shipment.cancelled = true;
      }, error => {
        alert(`We were not able to cancel the shipment`);
        this.cancellingLabel = false;
    })
  }
}
