<app-bar-alert alerts-code="sl-alerts"></app-bar-alert>

<div class="row">
  <section class="container">
    <div class="row">
      <div class="col-sm-10">
        <p>
          Total shipments:
          {{ loadingShipmentsStats ? "Fetching..." : shipmentsStats.shipments }}
        </p>
      </div>

      <div class="col-sm-2">
        <button
          *ngIf="labelPrinterData?.allowManifest"
          class="btn"
          (click)="openManifestConfirmationModal()"
        >
          {{ loadingManifests ? "Loading" : "Create" }} manifest
        </button>
        <div class="col-sm-auto">
          <app-manifests-list></app-manifests-list>
        </div>
      </div>

      <div class="col-sm-12">
        <p>
          Total unprinted labels:
          {{
          loadingShipmentsStats
            ? "Fetching..."
            : shipmentsStats.unprintedShipments
          }}
        </p>
      </div>
    </div>
    <div class="row">
      <ul class="col-sm-12 list-container">
        <li class="row list-item">
          <div class="col-sm-3">User's name</div>
          <div class="col-sm-2">User's address</div>

          <div class="col-sm-auto ml-auto">
            <button
              class="labelSearcherTrigger button-animation pointer"
              type="button"
              [ngClass]="{
                'button-animation--active': downloadingPdfByTrackingCodes
              }"
              (click)="printByTrackingNumbers = true"
            >
              Print by tracking numbers
            </button>
          </div>
          <div class="col-sm-auto ml-auto">
            <button
              class="labelSearcherTrigger button-animation pointer"
              type="button"
              [ngClass]="{ 'button-animation--active': downloadingPdf }"
              (click)="showBuildsOptions = true"
            >
              Print build
            </button>
          </div>

          <div class="col-sm-auto">
            <app-builds-list></app-builds-list>
          </div>

          <div class="col-sm-auto ml-auto">
            <button class="labelSearcherTrigger button-animation pointer" type="button"
                    (click)="processSubscriptionsComponent.active = true">
              Process subs
            </button>
            <app-process-subscriptions #processSubscriptionsComponent
                                       (success)="updateShipmentsStats(); loadShipments()"></app-process-subscriptions>
          </div>

          <div class="col-sm-auto">
            <button class="labelSearcherTrigger button-animation" type="button"
                    (click)="showSearchLabelsField = true; labelSearcher.focus()">
              Search
            </button>
            <input #labelSearcher class="labelSearcher" type="text" placeholder="Search a label by name or address"
                   (keyup)="onSearchLabel$.next($event.target)"
                   (blur)="showSearchLabelsField = false"
                   [ngClass]="{ 'labelSearcher--active': showSearchLabelsField }"/>
          </div>
        </li>

        <li
          class="row list-item"
          *ngFor="let shipment of filteredLabels(shipmentsLabelsRows)"
          [ngClass]="{ 'list-item--printed': shipment.printed }"
        >
          <div class="col-sm-3">{{ shipment.userName }}</div>
          <div class="col-sm-6">
            {{ (getShipmentAddress(shipment) | async)?.label }}
          </div>
          <div class="col-sm-auto ml-sm-auto">
            <button
              *ngIf="!shipment.refunded"
              class="list-item__button"
              (click)="onPrintLabel(shipment.shipmentId)"
            >
              Print label
            </button>
            <button
              *ngIf="shipment.refunded"
              style="cursor: not-allowed"
              class="list-item__button"
            >
              Label refunded
            </button>
          </div>
        </li>
      </ul>

      <div class="col-sm-12">
        <div class="loadMoreWrapper">
          <button
            class="loadMoreButton button-animation"
            [disabled]="loadingShipment"
            (click)="onLoadMore(page + 1)"
            [ngClass]="{ 'button-animation--active': loadingShipment }"
          >
            {{
            loadingShipment
              ? "Fetching more shipments"
              : "Load More shipments"
            }}
          </button>
        </div>
      </div>
    </div>
  </section>
</div>

<app-modal
  title="Printing options"
  [show]="showBuildsOptions"
  (atCloseBtn)="showBuildsOptions = false"
  secondaryBtn="Cancel"
  (atSecondaryBtn)="showBuildsOptions = false"
  primaryBtn="Print"
  [disablePrimaryBtn]="buildOptionsForm.invalid"
  (atPrimaryBtn)="onPrintBuild(buildOptionsForm.value)"
>
  <form
    [formGroup]="buildOptionsForm"
    novalidate
    (ngSubmit)="onPrintBuild($event.value)"
    class="row"
  >
    <div class="form-group col-sm-12">
      <label for="buildOptionsForm__date">1. Select Month:</label>
      <select
        formControlName="date"
        id="buildOptionsForm__date"
        class="form-control"
        *ngIf="shipmentsStats && shipmentsStats.monthlyStats"
      >
        <option value="ALL">All</option>
        <option *ngFor="let month of monthlyStatsList" [value]="month">
          {{ month }} (Available:{{ shipmentsStats.monthlyStats[month].total }},
          On Hold: {{ shipmentsStats.monthlyStats[month]?.onHold }})
        </option>
      </select>
      <small class="form-text text-muted"
      >Month when labels where created.</small
      >
    </div>

    <div class="form-group col-sm-12">
      <label>2. Select type:</label>

      <select name="option" id="" class="form-control" formControlName="option">
        <option *ngIf="showAll || optionStats.succulents?.available" value="succulents">Offer: Succulents
          (Available: {{optionStats.succulents?.available}}, On Hold: {{optionStats.succulents?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.silver_dollar?.available" value="silver_dollar">Offer: Silver dollar
          (Available: {{optionStats.silver_dollar?.available}}, On Hold: {{optionStats.silver_dollar?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.house_plant?.available" value="house_plant">Offer: House plant
          (Available: {{optionStats.house_plant?.available}}, On Hold: {{optionStats.house_plant?.onHold}})
        </option>

        <option value="ALL">ALL (Available: {{optionStats.ALL?.available}},
          On Hold: {{optionStats.ALL?.onHold}})
        </option>
        <option value="NEW">NEW (Available: {{optionStats.NEW?.available}},
          On Hold: {{optionStats.NEW?.onHold}})
        </option>
        <option value="NEW_GIFT_GIFTIT">NEW GIFT / GIFTIT
          (Available: {{optionStats.NEW_GIFT_GIFTIT?.available}}, On Hold: {{optionStats.NEW_GIFT_GIFTIT?.onHold}})
        </option>
        <option value="FUTURE_GIFT">FUTURE GIFT / GIFTIT
          (Available: {{optionStats.FUTURE_GIFT?.available}}, On Hold: {{optionStats.FUTURE_GIFT?.onHold}})
        </option>
        <option value="GIFT_COMPLETE">GIFT COMPLETE
          (Available: {{optionStats.GIFT_COMPLETE?.available}}, On Hold: {{optionStats.GIFT_COMPLETE?.onHold}})
        </option>
        <option value="SCHED">SCHED
          (Available: {{optionStats.SCHED?.available}}, On Hold: {{optionStats.SCHED?.onHold}})
        </option>
        <option value="RE-SHIP">RE-SHIP
          (Available: {{optionStats.RE_SHIP?.available}}, On Hold: {{optionStats.RE_SHIP?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.BUNDLE_FIRST_WEEK?.available" value="BUNDLE_FIRST_WEEK">2X BUNDLE FIRST
          WEEK (Available: {{optionStats.BUNDLE_FIRST_WEEK?.available}}, On
          Hold: {{optionStats.BUNDLE_FIRST_WEEK?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.BUNDLE_SECOND_WEEK?.available" value="BUNDLE_SECOND_WEEK">2X BUNDLE SECOND
          WEEK (Available: {{optionStats.BUNDLE_SECOND_WEEK?.available}}, On
          Hold: {{optionStats.BUNDLE_SECOND_WEEK?.onHold}})
        </option>

        <option *ngIf="showAll || optionStats.OTB_fruit_blossom?.available" value="OTB_fruit_blossom">OTB Fruit Blossom
          (Available: {{optionStats.OTB_fruit_blossom?.available}}, On Hold: {{optionStats.OTB_fruit_blossom?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_curly_willow_bouquet?.available" value="OTB_curly_willow_bouquet">OTB
          Curly Willow (Available: {{optionStats.OTB_curly_willow_bouquet?.available}}, On
          Hold: {{optionStats.OTB_curly_willow_bouquet?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_pussy_willow_bouquet?.available" value="OTB_pussy_willow_bouquet">OTB
          Pussy Willow (Available: {{optionStats.OTB_pussy_willow_bouquet?.available}}, On
          Hold: {{optionStats.OTB_pussy_willow_bouquet?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_lavender_bundle?.available" value="OTB_lavender_bundle">OTB Lavender
          Bundle (Available: {{optionStats.OTB_lavender_bundle?.available}}, On
          Hold: {{optionStats.OTB_lavender_bundle?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_PRESERVED?.available" value="OTB_PRESERVED">OTB Preserved Eucalyptus
          (Available: {{optionStats.OTB_PRESERVED?.available}}, On Hold: {{optionStats.OTB_PRESERVED?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_SAFARI?.available" value="OTB_SAFARI">OTB Safari Sunset
          (Available: {{optionStats.OTB_SAFARI?.available}}, On Hold: {{optionStats.OTB_SAFARI?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_SILVER_PUSSY?.available" value="OTB_SILVER_PUSSY">OTB Silver Dollar +
          Pussy Willow Bouquet (Available: {{optionStats.OTB_SILVER_PUSSY?.available}}, On
          Hold: {{optionStats.OTB_SILVER_PUSSY?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_SEEDED?.available" value="OTB_SEEDED">OTB Seeded Eucalyptus
          (Available: {{optionStats.OTB_SEEDED?.available}}, On Hold: {{optionStats.OTB_SEEDED?.onHold}})
        </option>

        <option value="OTB_CANDY_PLANT" *ngIf="showAll || optionStats.OTB_CANDY_PLANT?.available">
          OTB Cotton candy: {{optionStats.OTB_CANDY_PLANT?.available}}, On
          Hold: {{optionStats.OTB_CANDY_PLANT?.onHold}})
        </option>

        <option *ngIf="showAll || optionStats.WILD_PUSSY_WILLOW?.available" value="WILD_PUSSY_WILLOW">OTB Wild Pussy
          Willow Bundle Box (Available: {{optionStats.WILD_PUSSY_WILLOW?.available}}, On
          Hold: {{optionStats.WILD_PUSSY_WILLOW?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.FOUR_PACK?.available" value="FOUR_PACK">OTB 4 Pack Succulents
          (Available: {{optionStats.FOUR_PACK?.available}}, On Hold: {{optionStats.FOUR_PACK?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.PADDLE_BOX?.available" value="PADDLE_BOX">OTB Paddle Plant Duo Box
          (Available: {{optionStats.PADDLE_BOX?.available}}, On Hold: {{optionStats.PADDLE_BOX?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_PICKLE_PLANT?.available" value="OTB_PICKLE_PLANT">OTB Pickle Plant Box
          (Available: {{optionStats.OTB_PICKLE_PLANT?.available}}, On Hold: {{optionStats.OTB_PICKLE_PLANT?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_LAVENDER_WHEAT?.available" value="OTB_LAVENDER_WHEAT">OTB Lavender +
          Wheat Bundle Box (Available: {{optionStats.OTB_LAVENDER_WHEAT?.available}}, On
          Hold: {{optionStats.OTB_LAVENDER_WHEAT?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_GOLDEN_EUONYMUS_BUNDLE?.available" value="OTB_GOLDEN_EUONYMUS_BUNDLE">
          OTB Golden Euonymus Bundle (Available: {{optionStats.OTB_GOLDEN_EUONYMUS_BUNDLE?.available}}, On
          Hold: {{optionStats.OTB_GOLDEN_EUONYMUS_BUNDLE?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_TRICOLOR_JEWEL?.available" value="OTB_TRICOLOR_JEWEL">OTB Rhoeo
          Tricolor + Little Jewel Box (Available: {{optionStats.OTB_TRICOLOR_JEWEL?.available}}, On
          Hold: {{optionStats.OTB_TRICOLOR_JEWEL?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_ROSEMARY_BUNDLE_BOX?.available" value="OTB_ROSEMARY_BUNDLE_BOX">OTB
          Rosemary Bundle Box (Available: {{optionStats.OTB_ROSEMARY_BUNDLE_BOX?.available}}, On Hold:
          {{optionStats.OTB_ROSEMARY_BUNDLE_BOX?.onHold)
        </option>
        <option *ngIf="showAll || optionStats.OTB_BERZELIA?.available" value="OTB_BERZELIA">OTB BERZELIA Bundle Box
          (Available: {{optionStats.OTB_BERZELIA?.available}}, On Hold: {{optionStats.OTB_BERZELIA?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_4_PACK_SUCCULENTS?.available" value="OTB_4_PACK_SUCCULENTS">OTB 4 PACK
          of SUCCULENTS (Available: {{optionStats.OTB_4_PACK_SUCCULENTS?.available}}, On
          Hold: {{optionStats.OTB_4_PACK_SUCCULENTS?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_PEACOCK_PLANT?.available" value="OTB_PEACOCK_PLANT">
          Peacock Plant (Available: {{optionStats.OTB_PEACOCK_PLANT?.available}}, On
          Hold: {{optionStats.OTB_PEACOCK_PLANT?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.SUN_SPARKLER?.available" value="SUN_SPARKLER">
          SunSparkler Box (Available: {{optionStats.SUN_SPARKLER?.available}}, On
          Hold: {{optionStats.SUN_SPARKLER?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.DEFAULT_BOGO_OTB?.available" value="DEFAULT_BOGO_OTB">Bogo default box
          (Available: {{optionStats.DEFAULT_BOGO_OTB?.available}}, On Hold: {{optionStats.DEFAULT_BOGO_OTB?.onHold}})
        </option>

        // DEC OTB 2021
        <option *ngIf="showAll || optionStats.OTB_AUSSIE_PINE?.available" value="OTB_AUSSIE_PINE">OTB Aussie Pine Bundle
          Box (Available: {{optionStats.OTB_AUSSIE_PINE?.available}}, On Hold: {{optionStats.OTB_AUSSIE_PINE?.onHold}})
        </option>

<!--        Sep OTB 2022-->
        <option value="OTB_BELLE_DONKEY" *ngIf="showAll || optionStats.OTB_BELLE_DONKEY?.available">
          OTB Pachyveria 'Belle Blue' + Sedum 'Donkey's Tail': {{optionStats.OTB_BELLE_DONKEY?.available}}, On
          Hold: {{optionStats.OTB_BELLE_DONKEY?.onHold}})
        </option>
        <option value="OTB_PADDLE_DEDOS" *ngIf="showAll || optionStats.OTB_PADDLE_DEDOS?.available">
          OTB Kalanchoe 'Paddle Plant' + Sedum 'Dedos': {{optionStats.OTB_PADDLE_DEDOS?.available}}, On
          Hold: {{optionStats.OTB_PADDLE_DEDOS?.onHold}})
        </option>
        <option value="OTB_TACO_CARPET" *ngIf="showAll || optionStats.OTB_TACO_CARPET?.available">
          OTB Peperomia 'Taco Plant' + Crassula 'Small Red Carpet': {{optionStats.OTB_TACO_CARPET?.available}}, On
          Hold: {{optionStats.OTB_TACO_CARPET?.onHold}})
        </option>
        <option value="OTB_FANG_KIWI" *ngIf="showAll || optionStats.OTB_FANG_KIWI?.available">
          OTB Kalanchoe 'Fang' + Aeonium 'Kiwi': {{optionStats.OTB_FANG_KIWI?.available}}, On
          Hold: {{optionStats.OTB_FANG_KIWI?.onHold}})
        </option>
        <option value="OTB_PROLIFICA_JEWEL" *ngIf="showAll || optionStats.OTB_PROLIFICA_JEWEL?.available">
          OTB Echeveria 'Prolifica' + Pachyphytum 'Little Jewel': {{optionStats.OTB_PROLIFICA_JEWEL?.available}}, On
          Hold: {{optionStats.OTB_PROLIFICA_JEWEL?.onHold}})
        </option>
        <option value="OTB_SPOONS_DARLEY" *ngIf="showAll || optionStats.OTB_SPOONS_DARLEY?.available">
          OTB Kalanchoe 'Copper Spoons' + Graptosedum 'Darley Sunshine': {{optionStats.OTB_SPOONS_DARLEY?.available}}, On
          Hold: {{optionStats.OTB_SPOONS_DARLEY?.onHold}})
        </option>

        <option value="OTB_MIXED_BUNDLE" *ngIf="showAll || optionStats.OTB_MIXED_BUNDLE?.available">
          OTB Mixed Bouquet Bundle: {{optionStats.OTB_MIXED_BUNDLE?.available}}, On
          Hold: {{optionStats.OTB_MIXED_BUNDLE?.onHold}})
        </option>


        <option value="OTB_WINTER1" *ngIf="showAll || optionStats.OTB_WINTER1?.available">
          OTB Echeveria Imbricata 'Blue Rose' + Aeomium 'Lily Pad': {{optionStats.OTB_WINTER1?.available}}, On
          Hold: {{optionStats.OTB_WINTER1?.onHold}})
        </option>
        <option value="OTB_WINTER2" *ngIf="showAll || optionStats.OTB_WINTER2?.available">
          OTB Sedum 'Donkey's Tail' + Echeveria 'Chroma': {{optionStats.OTB_WINTER2?.available}}, On
          Hold: {{optionStats.OTB_WINTER2?.onHold}})
        </option>
        <option value="OTB_WINTER3" *ngIf="showAll || optionStats.OTB_WINTER3?.available">
          OTB Gasteria Biocolor 'Lawyer's Tongue' + Sempervivum 'Cobweb Houseleek': {{optionStats.OTB_WINTER3?.available}}, On
          Hold: {{optionStats.OTB_WINTER3?.onHold}})
        </option>
        <option value="OTB_WINTER4" *ngIf="showAll || optionStats.OTB_WINTER4?.available">
          OTB Adromischus Cristatus 'Crinkle Leaf' + Anacampseros Rufescens 'Love Plant': {{optionStats.OTB_WINTER4?.available}}, On
          Hold: {{optionStats.OTB_WINTER4?.onHold}})
        </option>
        <option value="OTB_WINTER5" *ngIf="showAll || optionStats.OTB_WINTER5?.available">
          OTB Senico 'Mount Everest' + Crassula Mesembryanthemoides 'Fuzzy Crassula': {{optionStats.OTB_WINTER5?.available}}, On
          Hold: {{optionStats.OTB_WINTER5?.onHold}})
        </option>
        <option value="OTB_WINTER6" *ngIf="showAll || optionStats.OTB_WINTER6?.available">
          OTB Titanopsis Calcarea ‘Concrete-Leaf’ + Delosperma echinatum ‘Pickle Plant’: {{optionStats.OTB_WINTER6?.available}}, On
          Hold: {{optionStats.OTB_WINTER6?.onHold}})
        </option>

<!--        April 2023 -->
        <option value="OTB_RICFLWR" *ngIf="showAll || optionStats.OTB_RICFLWR?.available">
          OTB Rice Flower Bundle Box: {{optionStats.OTB_RICFLWR?.available}}, On
          Hold: {{optionStats.OTB_RICFLWR?.onHold}})
        </option>
        <option value="OTB_PINKWAX" *ngIf="showAll || optionStats.OTB_PINKWAX?.available">
          OTB Pink Wax Flowers bundle: {{optionStats.OTB_PINKWAX?.available}}, On
          Hold: {{optionStats.OTB_PINKWAX?.onHold}})
        </option>

        <!--        July 2023-->
        <option value="OTB_MINIMA_DRAGON" *ngIf="showAll || optionStats.OTB_MINIMA_DRAGON?.available">
          The Minima + Dragon Box: {{optionStats.OTB_MINIMA_DRAGON?.available}}, On
          Hold: {{optionStats.OTB_MINIMA_DRAGON?.onHold}})
        </option>

        <!--        July 2023-->
        <option value="OTB_BZ_TREE" *ngIf="showAll || optionStats.OTB_BZ_TREE?.available">
          OTB Brazilian Tree Fern Box: {{optionStats.OTB_BZ_TREE?.available}}, On
          Hold: {{optionStats.OTB_BZ_TREE?.onHold}})
        </option>

        <!--        October 2023-->
        <option value="OTB_2XSTRG" *ngIf="showAll || optionStats.OTB_2XSTRG?.available">
          OTB 2X String of Pearls Box: {{optionStats.OTB_2XSTRG?.available}}, On
          Hold: {{optionStats.OTB_2XSTRG?.onHold}})
        </option>

        <option *ngIf="showAll || optionStats.GWP?.available" value="GWP">GWP (Available: {{optionStats.GWP?.available}}
          ,
          On Hold: {{optionStats.GWP?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_SILVER_DOLLAR?.available" value="OTB_SILVER_DOLLAR">OTB Silver dollar
          (Available: {{optionStats.OTB_SILVER_DOLLAR?.available}}, On Hold: {{optionStats.OTB_SILVER_DOLLAR?.onHold}})
        </option>
        <option *ngIf="showAll || optionStats.OTB_SUCSTU_BUNDLE_BOX?.available" value="OTB_SUCSTU_BUNDLE_BOX">OTB Succulent Bundle Box
          (Available: {{optionStats.OTB_SUCSTU_BUNDLE_BOX?.available}}, On Hold: {{optionStats.OTB_SUCSTU_BUNDLE_BOX?.onHold}})
        </option>
      </select>
      <div class="form-group form-check text-right">
        <input type="checkbox" class="form-check-input" id="showAllOptions" [(ngModel)]="showAll"
               [ngModelOptions]="{standalone: true}">
        <label class="form-check-label" for="showAllOptions">Show All Options.</label>
      </div>
    </div>

    <!--    <div class="form-group col-sm-12">
          <div><label>3. Select Carrier:</label></div>
          <div class="form-check form-check-inline">
            &lt;!&ndash; <input class="form-check-input" type="radio" id="carrierOption1" formControlName="selectedCarrier"
                   value="UPSMailInnovations"> &ndash;&gt;
            &lt;!&ndash; <label class="form-check-label" for="carrierOption2">UPS</label> &ndash;&gt;
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" formControlName="selectedCarrier" id="carrierOption1" type="radio"
                   value="DHLeCommerce">
            <label class="form-check-label" for="carrierOption1">DHL</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" id="carrierOption2" formControlName="selectedCarrier" value="USPS">
            <label class="form-check-label" for="carrierOption2">USPS</label>
          </div>
        </div>-->

    <div class="form-group col-sm-12">
      <div><label>3. Select box:</label></div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" formControlName="selectedBox" type="radio" value="all">
        <label class="form-check-label">All</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" formControlName="selectedBox" type="radio" value="5e8ce5e11f3aaf2542e41ff0">
        <label class="form-check-label">Baby Blue</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" formControlName="selectedBox" type="radio" value="5e8ce5e11f3aaf2542e41ff1">
        <label class="form-check-label">Silver Dollar</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" formControlName="selectedBox" type="radio" value="5e8ce5e11f3aaf2542e41ff2">
        <label class="form-check-label">Willow</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" formControlName="selectedBox" type="radio" value="5e8ce5e11f3aaf2542e41ff3">
        <label class="form-check-label">Surprise</label>
      </div>
    </div>

    <div class="form-group col-sm-12">
      <label for="buildOptionsForm">Quantity of labels:</label>
      <input
        type="number"
        class="form-control"
        id="buildOptionsForm"
        formControlName="quantity"
        min="0"
        max="100"
        value="100"
        [ngClass]="{ 'is-invalid': !buildOptionsForm.get('quantity').valid }"
      />
      <small class="form-text text-muted">
        {{
        buildOptionsForm.get("quantity").valid
          ? "Total labels to print. Write a number between 0 and 100"
          : "Please write a number between 0 and 100"
        }}
      </small>
    </div>

    <!-- <div class="form-group col-sm-12">
      <label for="buildOptionsForm__date">Select custom Carrier:</label>
      <input type="checkbox" formControlName="customCarrier" #customCarrier>
    </div>

    <div class="form-group col-sm-12" *ngIf="customCarrier.checked">
      <label>Available Carriers</label>
      <div class="form-group">
        <label for="buildOptionsForm__date">Select Carrier:</label>
        <select
          formControlName="selectedCarrier" class="form-control">
          <option
            *ngFor="let carrier of carrierList"
            [value]="carrier.carrierName"
            [selected]="buildOptionsForm.get('selectedCarrier').value === carrier.carrierName">
            {{ carrier.carrierName }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="buildOptionsForm__date">Select Service:</label>
        <select formControlName="selectedService" class="form-control">
          <option
            *ngFor="let service of carrierSelectedServices"
            [value]="service"
            [selected]="buildOptionsForm.get('selectedService').value === service">
            {{ service }}
          </option>
        </select>
      </div>
    </div> -->
  </form>
</app-modal>

<app-modal
  title="Print By Tracking Numbers"
  secondaryBtn="Cancel"
  primaryBtn="Submit & Print"
  (atCloseBtn)="printByTrackingNumbers = false"
  (atSecondaryBtn)="printByTrackingNumbers = false"
  [show]="printByTrackingNumbers"
  (atPrimaryBtn)="submitTrackingNumbers()"
>
  <form novalidate class="row">
    <div class="form-group col-sm-6">
      <label>List of tracking Numbers</label>
      <input
        #csvInputFile
        name="csvInputFile"
        required
        type="file"
        class="form-control-file"
        accept="text/csv"
      />
    </div>
  </form>
</app-modal>


<ng-container *ngIf="isManifestConfirmationModalActive">
  <div
    class="modal"
    [ngStyle]="{
      'display': 'block',
      'backgroundColor': 'rgba(0,0,0,.8)'
    }"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmation</h5>
          <button (click)="closeManifestConfirmationModal()" aria-label="Close" class="close" data-dismiss="modal"
                  type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to create a manifest?</p>
        </div>
        <div class="modal-footer">
          <button (click)="getTodayShipmentsManifest()" [disabled]="loadingManifests" class="btn btn-primary"
                  type="button">
            {{ loadingManifests ? 'Loading...' : 'Yes'}}
          </button>
          <button (click)="closeManifestConfirmationModal()" [disabled]="loadingManifests" class="btn btn-secondary"
                  type="button">Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
