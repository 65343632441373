import * as tslib_1 from "tslib";
import * as moment from 'moment';
var BaseCoupon = /** @class */ (function () {
    function BaseCoupon(data) {
        if (data) {
            this.id = data._id;
            this.code = data.code;
            this.bannerMessage = data.bannerMessage;
            this.amount = data.amount;
            this.status = data.status;
            this.createdAt = data.createdAt;
            this.updatedAt = data.updatedAt;
        }
    }
    return BaseCoupon;
}());
var Coupon = /** @class */ (function (_super) {
    tslib_1.__extends(Coupon, _super);
    function Coupon(data) {
        var _this = _super.call(this, data) || this;
        if (data) {
            _this.name = data.name;
            _this.description = data.description;
            _this.validUntil = data.validUntil && moment(data.validUntil).format('YYYY-MM-DD');
            _this.amount = data.amount;
            _this.canBeUsedByClient = data.canBeUsedByClient;
            _this.boxSku = data.boxSku;
            _this.totalRedeemed = data.totalRedeemed;
        }
        return _this;
    }
    return Coupon;
}(BaseCoupon));
export { Coupon };
var GGCoupon = /** @class */ (function (_super) {
    tslib_1.__extends(GGCoupon, _super);
    function GGCoupon(data) {
        var _this = _super.call(this, data) || this;
        if (data) {
            _this.shippingCostsDiscount = _this.shippingCostsDiscount;
            _this.currentUse = data.currentUse;
            _this.freeMonths = data.freeMonths;
            _this.maxUse = data.maxUse;
            _this.usedBySubscriptions = data.usedBySubscriptions;
            _this.allowedPlans = data.allowedPlans;
        }
        return _this;
    }
    GGCoupon.prototype.isAvailable = function () {
        return this.currentUse !== this.maxUse;
    };
    GGCoupon.prototype.planNames = function () {
        return this.allowedPlans.map(function (p) { return p.name; }).join('\n');
    };
    return GGCoupon;
}(BaseCoupon));
export { GGCoupon };
