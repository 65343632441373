import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { pluck } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ManifestsService = /** @class */ (function () {
    function ManifestsService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl + '/api';
    }
    ManifestsService.prototype.getTodaysManifests = function () {
        return this.http.get(this.apiUrl + "/manifests/today")
            .pipe(pluck('data'));
    };
    ManifestsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManifestsService_Factory() { return new ManifestsService(i0.ɵɵinject(i1.HttpClient)); }, token: ManifestsService, providedIn: "root" });
    return ManifestsService;
}());
export { ManifestsService };
